import React, { useState } from "react";
import Header from "../Components/Layout/Header";
import Footer from "../Components/Layout/Footer";
import TodayDealzCard from "../Components/ProductCard1";
import product1 from "../Assets/Images/Joystick.png";
import product2 from "../Assets/Images/mobile.png";
import product3 from "../Assets/Images/airbots.png";
import product4 from "../Assets/Images/headphone.png";
import ratings from "../Assets/Images/ratings.svg";
import joystick from "../Assets/Images/Joystick.png";
import hometheatre from "../Assets/Images/home_theatre.png";
import headphone from "../Assets/Images/headphone.png";
import tv from "../Assets/Images/tv.png";
import watch from "../Assets/Images/watch.png";

import Dropdown from "react-bootstrap/Dropdown";
import FeatureProductCard from "../Components/FeatureProductCard";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import SearchFilter from "../Components/SearchFilter";
import Offcanvas from "react-bootstrap/Offcanvas";
import { BsSliders } from "react-icons/bs";
function SearchProducts() {
  const [deliverOption, setDeliveryOption] = useState(true);
  const [category, setCategory] = useState(true);
  const [price, setPrice] = useState(true);
  const [brand, setBrand] = useState(true);
  const [show, setShow] = useState(false);

  const handleCloseOffcanvas = () => {
    setShow(false);
  };
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const TodayDealz = [
    {
      id: 1,
      prodImg: product1,
      ProdName: "Lorem ipsum dolor sit amet adi pscing elitr",
      ratings: ratings,
      ProdStatus: "new",
      ProdStatusVal: "Free Shipping",
    },
    {
      id: 2,
      prodImg: product2,
      ProdName: "Lorem ipsum dolor sit amet adi pscing elitr",
      ratings: ratings,
      ProdStatus: "new",
      ProdStatusVal: "Free Shipping",
    },
    {
      id: 3,
      prodImg: product3,
      ProdName: "Lorem ipsum dolor sit amet adi pscing elitr",
      ratings: ratings,
      ProdStatus: "new",
      ProdStatusVal: "Free Shipping",
    },
    {
      id: 4,
      prodImg: product4,
      ProdName: "Lorem ipsum dolor sit amet adi pscing elitr",
      ratings: ratings,
      ProdStatus: "new",
      ProdStatusVal: "Free Shipping",
    },
    {
      id: 5,
      prodImg: product1,
      ProdName: "Lorem ipsum dolor sit amet adi pscing elitr",
      ratings: ratings,
      ProdStatus: "new",
      ProdStatusVal: "Free Shipping",
    },
  ];
  const FeaturedProduct = [
    {
      id: 1,
      Img: joystick,
      Name: "Smart Watch for Android",
    },
    {
      id: 2,
      Img: hometheatre,
      Name: "Smart Watch for Android",
    },
    {
      id: 3,
      Img: headphone,
      Name: "Smart Watch for Android",
    },
    {
      id: 4,
      Img: tv,
      Name: "Smart Watch for Android",
    },
    {
      id: 5,
      Img: watch,
      Name: "Smart Watch for Android",
    },

    {
      id: 6,
      Img: joystick,
      Name: "Smart Watch for Android",
    },
    {
      id: 7,
      Img: hometheatre,
      Name: "Smart Watch for Android",
    },
    {
      id: 8,
      Img: headphone,
      Name: "Smart Watch for Android",
    },
    {
      id: 9,
      Img: tv,
      Name: "Smart Watch for Android",
    },
    {
      id: 10,
      Img: watch,
      Name: "Smart Watch for Android",
    },

    {
      id: 11,
      Img: joystick,
      Name: "Smart Watch for Android",
    },
    {
      id: 12,
      Img: hometheatre,
      Name: "Smart Watch for Android",
    },
    {
      id: 13,
      Img: headphone,
      Name: "Smart Watch for Android",
    },
    {
      id: 14,
      Img: tv,
      Name: "Smart Watch for Android",
    },
    {
      id: 15,
      Img: watch,
      Name: "Smart Watch for Android",
    },

    {
      id: 16,
      Img: joystick,
      Name: "Smart Watch for Android",
    },
  ];

  return (
    <div className="">
      <Header />
      <div className="search_products">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 col-xxl-2 mb-3">
              <div className="select-filter-sec-web">
                <SearchFilter handleCloseOffcanvas={handleCloseOffcanvas} />
              </div>
              <div className="select-filter-sec-mbl">
                <div className="filter-slider" onClick={handleShow}>
                  <BsSliders />
                </div>
                <Offcanvas
                  show={show}
                  onHide={handleClose}
                  className="selectFilter-offcanvas"
                >
                  {/* <Offcanvas.Header closeButton></Offcanvas.Header> */}
                  <Offcanvas.Body className="filter-rooms">
                    <SearchFilter handleCloseOffcanvas={handleCloseOffcanvas} />
                  </Offcanvas.Body>
                </Offcanvas>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9 col-xxl-10 product_list">
              <div className="row">
                {TodayDealz.map((item, index) => {
                  return (
                    <div className="col-12 col-sm-6 col-md-4 col-xl-4 col-xxl-3 col">
                      <TodayDealzCard items={item} />
                    </div>
                  );
                })}
              </div>
              <div className="similar_products mt-4">
                <div className="cmnHeading_sec">
                  <p className="mb-0 heading_txt">Similar products</p>
                  <Link to="/" className="text-decoration-none">
                    <p className="mb-0 see-all">See all</p>
                  </Link>
                </div>

                <div className="card_sec mt-4">
                  <div className="row">
                    {FeaturedProduct.map((item, index) => {
                      return (
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4 col-xxl-4 col">
                          <Link to="/" className="text-decoration-none">
                            <FeatureProductCard items={item} />
                          </Link>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default SearchProducts;
