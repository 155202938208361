import React, { useState } from "react";
import { IoIosArrowForward } from "react-icons/io";
import { PiShareFatFill } from "react-icons/pi";
import { Button } from "react-bootstrap";
import ShareProductModal from "./Modals/ShareProductsModal";
function CartHeader() {
  const [share, setShare] = useState(false);
  const handleShareShow = () => {
    setShare(true);
  };
  const handleShareclose = () => {
    setShare(false);
  };
  return (
    <>
      <div className="cart_header">
        <div className="container">
          <div className="d-flex align-items-center justify-content-between">
            <div className="breadcrump_list">
              <span>Home</span>
              <IoIosArrowForward />
              <span>Computers</span>
              <IoIosArrowForward />
              <span>Laptops</span>
              <IoIosArrowForward />
              <span>Lenovo laptops</span>
              <IoIosArrowForward className="current-txt" />
              <span className="current-txt">Shopping cart</span>
            </div>
            <Button className="share_btn" onClick={handleShareShow}>
              <PiShareFatFill size={16} />
              <span>Share</span>
            </Button>
          </div>
        </div>
      </div>
      {share && (
        <ShareProductModal show={share} handleClose={handleShareclose} />
      )}
    </>
  );
}

export default CartHeader;
