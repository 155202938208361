import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Image } from "../data/Images";

const Sample = () => {
  const [networks, setNetworks] = useState([
    {
      img: Image.bsc,
      name: "BSC",
    },
    {
      img: Image.bsc,
      name: "BSC",
    },
    {
      img: Image.bsc,
      name: "BSC",
    },
    {
      img: Image.bsc,
      name: "BSC",
    },
    {
      img: Image.bsc,
      name: "BSC",
    },
    {
      img: Image.bsc,
      name: "BSC",
    },
  ]);
  return (
    <>
      <Container fluid>
        <Row>
          <Col lg={3}>
            <div className="dash__topCard rounded-4 overflow-hidden">
              <div className="d-flex justify-content-start align-items-center gap-4">
                <img
                  src={Image.chipOne}
                  className="img-fluid dashCard__chipImg"
                  alt=""
                />
                <div>
                  <p className="m-0 dashCard__label">Wallet Balance:</p>
                  <div className="d-flex justify-content-start align-items-start gap-2 mt-1">
                    <p className="m-0 dashCard__value">25.08</p>
                    <img
                      src={Image.bsc}
                      className="img-fluid dashCard__coinImg rounded-5"
                      alt=""
                    />
                    <p className="m-0 dashCard__value">BNB</p>
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-start align-items-start gap-4 mt-4">
                <img
                  src={Image.chipOne}
                  className="img-fluid dashCard__chipImg"
                  alt=""
                />
                <div>
                  <p className="m-0 dashCard__label">Account :</p>
                  <p className="m-0 dashCard__value">0xcvg12dbh4df464adc</p>

                  <button className="dashCard__whiteBtn rounded-5 mt-4">
                    Manage Account
                  </button>
                </div>
              </div>
            </div>
          </Col>

          <Col lg={3}>
            <div className="dash__topNetworkCard rounded-4 overflow-hidden">
              <p className="m-0 dashCard__value">Blockchain Networks</p>

              <div className="dashCard__networksHolder d-flex justify-content-start align-items-center gap-2 flex-wrap mt-4">
                {networks.map((item, index) => (
                  <button className="dashCard__singleNetwork rounded-5 d-flex justify-content-start align-items-center gap-2">
                    <img
                      src={item.img}
                      className="img-fluid dashCard__coinImg rounded-5"
                      alt=""
                    />
                    <p className="m-0 dashCard__networkName">
                      {item.name}{" "}
                      <span className="ms-1">
                        <i class="fa-solid fa-circle-check" />
                      </span>
                    </p>
                  </button>
                ))}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Sample;
