// import package

import React, { useRef, useState } from "react";
import { Button, Col, Dropdown, Row } from "react-bootstrap";
import Select from "react-select";
import Form from "react-bootstrap/Form";
// import local
import { Image } from "../data/Images";
import DashLayout from "../Components/DashboardLayout/DashLayout";
import { Link } from "react-router-dom";
import ShippingByCountry from "../Components/ShippingByCountry";
import DeliveryMan from "../Components/DeliveryMan";
import { FaTrash } from "react-icons/fa";
const ShippingManagement = () => {
  const [shippingType, setShippingType] = useState("Manual Shipping");

  const CountryOptions = [
    { value: "in", label: "India" },
    { value: "usa", label: "Usa" },
  ];

  return (
    <DashLayout>
      <div className="shipping-management">
        <h5 className="overview__title">Shipping Management</h5>
        <div className="row mt-3 shipping_managementNew">
          {/* <div className="order-2 order-lg-1 col-lg-9 col-xl-10 mb-4">
            <div className="ShippingByCat mt-3">
              <p className="mb-2 f-15">Shipping by categories</p>
              <div className="cmn_shipList">
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4 mb-2">
                    <Form.Group
                      className=""
                      controlId="exampleForm.ControlInput21"
                    >
                      <Form.Label>Choosed Country</Form.Label>
                      <Select
                        classNamePrefix="stateCountry_select"
                        options={CountryOptions}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-12 col-sm-12 col-md-8 col-lg-8">
                    <div className="row">
                      <div className="col-6 col-sm-3 col-lg-3 mb-2">
                        <Form.Group
                          className=""
                          controlId="exampleForm.ControlInput21"
                        >
                          <Form.Label>BNB</Form.Label>
                          <Form.Control type="text" placeholder="BNB" />
                        </Form.Group>
                      </div>
                      <div className="col-6 col-sm-3 col-lg-3 mb-2">
                        <Form.Group
                          className=""
                          controlId="exampleForm.ControlInput21"
                        >
                          <Form.Label>ETH</Form.Label>
                          <Form.Control type="text" placeholder="ETH" />
                        </Form.Group>
                      </div>
                      <div className="col-6 col-sm-3 col-lg-3">
                        <Form.Group
                          className=""
                          controlId="exampleForm.ControlInput21"
                        >
                          <Form.Label>MAT</Form.Label>
                          <Form.Control type="text" placeholder="MAT" />
                        </Form.Group>
                      </div>
                      <div className="col-6 col-sm-3 col-lg-3">
                        <div className="add_deleteCat">
                          <Button className="addBtn">Add</Button>
                          <Button className="deleteBtn">
                            <FaTrash className="trashIcon" />
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div className="order-1 order-lg-2 mb-3 mb-lg-0 mt-0 mt-lg-4 col-lg-3 col-xl-2">
            <div className="product_ryt_tab mt-lg-2">
              {/* <div
                className={
                  selectTab == "productinfo"
                    ? "heading_txt active mb-2"
                    : "heading_txt mb-2"
                }
                onClick={() => handleSelectTab("productinfo")}
              >
                <span className="activeLine"></span>
                Product information
              </div>
              <div
                className={
                  selectTab == "description"
                    ? "heading_txt active mb-2"
                    : "heading_txt mb-2"
                }
                onClick={() => handleSelectTab("description")}
              >
                <span className="activeLine"></span>
                Description
              </div>
              <div
                className={
                  selectTab == "shippingType"
                    ? "heading_txt active mb-2"
                    : "heading_txt mb-2"
                }
                onClick={() => handleSelectTab("shippingType")}
              >
                <span className="activeLine"></span>
                Shipping Type
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </DashLayout>
  );
};

export default ShippingManagement;
