import React, {
  createContext,
  useContext,
  useState,
  useRef,
  useEffect,
} from "react";

// import package
import { Link, NavLink, useLocation } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";
import { IoMdLogOut } from "react-icons/io";

// import local
import { Image } from "../../data/Images";
import { GlobalContext } from "../../context/Context";
import useWindowResize from "../hook/useWindowResize";

const navigationLinks = [
  {
    id: Math.random().toString(16).slice(2, 7),
    img: Image.overview,
    name: "Overview",
    linkTo: "/overview",
  },
  {
    id: Math.random().toString(16).slice(2, 7),
    img: Image.orders,
    name: "Orders",
    linkTo: "/orders",
  },
  {
    id: Math.random().toString(16).slice(2, 7),
    img: Image.escrow,
    name: "Escrow",
    linkTo: "/escrow",
  },
  {
    id: Math.random().toString(16).slice(2, 7),
    img: Image.deliveries,
    name: "Deliveries",
    linkTo: "/deliveries",
  },
  {
    id: Math.random().toString(16).slice(2, 7),
    img: Image.chat,
    name: "Chat",
    linkTo: "/chat",
  },
  {
    id: Math.random().toString(16).slice(2, 7),
    img: Image.coupons,
    name: "Coupons",
    linkTo: "/coupons",
    subLink: "/add-coupon",
  },
  {
    id: Math.random().toString(16).slice(2, 7),
    img: Image.wishlists,
    name: "Wishlists",
    linkTo: "/wishlists",
  },
  {
    id: Math.random().toString(16).slice(2, 7),
    img: Image.product,
    name: "Products",
    linkTo: "/products",
    subLink: "/add-product",
  },
  {
    id: Math.random().toString(16).slice(2, 7),
    img: Image.store,
    name: "Stores",
    linkTo: "/stores",
    subLink: "/add-store",
  },
  {
    id: Math.random().toString(16).slice(2, 7),
    img: Image.qaSeller,
    name: "QA Seller",
    linkTo: "/qa-seller",
  },
  // {
  //   id: Math.random().toString(16).slice(2, 7),
  //   img: Image.address,
  //   name: "Address",
  //   linkTo: "/address",
  // },
  {
    id: Math.random().toString(16).slice(2, 7),
    img: Image.notification,
    name: "Notifications",
    linkTo: "/notifications",
  },
  {
    id: Math.random().toString(16).slice(2, 7),
    img: Image.email,
    name: "Email",
    linkTo: "/email",
  },
  {
    id: Math.random().toString(16).slice(2, 7),
    img: Image.email,
    name: "Shipping Management",
    linkTo: "/shipping-management",
  },
  {
    id: Math.random().toString(16).slice(2, 7),
    img: Image.account,
    name: "Accounts",
    linkTo: "/account",
  },
];

const DashSidebar = () => {
  const { checkContext, handleSidebar, isSidebarOpen, setIsSidebarOpen } =
    useContext(GlobalContext);

  const windowSize = useWindowResize();
  const { width, height } = windowSize;
  const location = useLocation();

  const [activeIndex, setActiveIndex] = useState(null);
  const listRefs = useRef([]);

  const handleClick = (index) => {
    localStorage.setItem("sidebarIndex", index);
  };

  useEffect(() => {
    if (location.pathname == "/overview") {
      listRefs?.current[0]?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    } else {
      const sidebarIndex = localStorage.getItem("sidebarIndex");
      listRefs?.current[sidebarIndex]?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  }, []);

  return (
    <div className="dash_sidebar">
      <div
        className={`dash_sidebar_content h-100 ${
          isSidebarOpen ? "active" : ""
        }`}
      >
        <div
          className={`sidebar_header d-flex align-items-center  ${
            isSidebarOpen
              ? "justify-content-between p-3 "
              : "justify-content-center p-2 "
          }`}
        >
          {isSidebarOpen && (
            <div>
              <img
                src={Image.logo}
                alt="logo"
                className="img-fluid sidebar_logo"
              />
            </div>
          )}

          <button
            className="sidebar_controller_button d-flex align-items-center justify-content-center"
            onClick={handleSidebar}
          >
            <FaArrowRight fill="#fff" />
          </button>
        </div>
        <div className="sidebar_body d-flex flex-column justify-content-between pt-1">
          <ul className="px-0 sidebar_ul">
            {navigationLinks.map((item, index) => (
              <li
                className="mt-3"
                key={index}
                ref={(el) => (listRefs.current[index] = el)}
                onClick={() => handleClick(index)}
              >
                <NavLink
                  to={item.linkTo}
                  className={
                    location.pathname == item?.subLink
                      ? "active sidebar_navlink text-decoration-none position-relative gap-3"
                      : "sidebar_navlink text-decoration-none position-relative gap-3"
                  }
                >
                  {isSidebarOpen && <div className="sidebar__greenLine"></div>}

                  <div
                    className={`d-flex align-items-center  sidebar__linkOnly gap-3  ${
                      isSidebarOpen
                        ? "ms-4 me-3 px-3 justify-content-start"
                        : "mx-2 justify-content-center"
                    }`}
                  >
                    <img src={item.img} alt={item.name} className="img-fluid" />
                    {isSidebarOpen && (
                      <p className="mb-0 sidebar_name">{item.name}</p>
                    )}
                  </div>
                </NavLink>
              </li>
            ))}
          </ul>
          <div className="mb-5 ">
            <button
              className={`red_border_less_btn d-flex align-items-center gap-2  ${
                isSidebarOpen
                  ? "justify-content-start ms-4"
                  : "justify-content-center ms-3"
              }`}
            >
              <IoMdLogOut fontSize={20} fill="#dd3d4c" />

              {isSidebarOpen && "Logout"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashSidebar;
