import React, { useContext, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Image } from "../data/Images";
import Select from "react-select";

import PingBg from "../Assets/Images/pinkBg.png";
import SkyBg from "../Assets/Images/skyBg.png";
import VioletBg from "../Assets/Images/vioCard.png";
import OrangeingBg from "../Assets/Images/orangeBg.png";

const EscrowLock = () => {
  const [selected, setSelected] = useState({
    value: "Select Coin",
    label: "Select Coin",
    img: Image.bsc,
  });
  const coinRef = useRef();
  const [selectedNetwork, setSelectedNetwork] = useState();
  const [closer, setCloser] = useState();
  const [selectedOption, setSelectedOption] = useState(null);

  const customOptionRenderer = ({ label, data, value }) => (
    <div
      className="market_select_component d-flex justify-content-start align-items-center ps-2 mb-1 pointer"
      onClick={() => {
        setSelected({ label, value, img: data.img });
        coinRef.current.blur();
        setCloser(!closer);
      }}
    >
      {console.log("sdhguisd", label, data, value)}
      <img src={data.img} style={{ width: "15px", marginRight: "7px" }} />
      <p className="m-0 overview__selectCompoValue">{label}</p>
    </div>
  );

  const options = [
    { value: "bnb", label: "BNB", img: Image.bsc },
    { value: "tron", label: "TRON", img: Image.bsc },
    { value: "btc", label: "BTC", img: Image.bsc },
  ];

  const escrow = [
    {
      id: 1,
      bgImg: PingBg,
    },
    {
      id: 2,
      bgImg: SkyBg,
    },
    {
      id: 3,
      bgImg: VioletBg,
    },
    {
      id: 4,
      bgImg: OrangeingBg,
    },
  ];

  return (
    <Row className="mt-5 dashboard__escrowCardHolder">
      {escrow.map((item, index) => {
        return (
          <Col xxl={3} lg={4} sm={6} className="mb-4">
            <div
              className="dashboard__escrowCard dashboard__pinkBg p-2"
              style={{ background: `url(${item.bgImg})` }}
            >
              <div className="dashbaord__escrowCardTop d-flex justify-content-between align-items-center ">
                <div className="d-flex justify-content-start align-items-center gap-2">
                  <img
                    src={Image.goldlock}
                    className="img-fluid overview__goldLockImg"
                    alt=""
                  />
                  <p className="m-0 escrowCard__label">Escrow</p>
                </div>

                <div className="d-flex justify-content-end align-items-center gap-2">
                  <p className="m-0 escrowCard__small">Network</p>
                  <div className="market_coinname_select position-relative">
                    <img
                      src={Image.bsc}
                      className="img-fluid escrowCard__selectImg"
                    />
                    <Select
                    //   menuIsOpen={true}
                      classNamePrefix="escrowCard__select"
                      defaultValue={selectedOption}
                      onChange={setSelected}
                      options={options}
                      value={selected}
                      components={{ Option: customOptionRenderer }}
                      ref={coinRef}
                      placeholder=""
                    />
                  </div>
                </div>
              </div>
              <img
                src={Image.grapher}
                className="img-fluid escrowCard__graphImg mt-2"
                alt=""
              />
              <div className="d-flex justify-content-center ">
                <div className="dashboard__escrowCardPair d-flex justify-content-center align-items-center gap-1 px-2 py-1 rounded-5">
                  <p className="m-0 escrowCardPair__value">0.15</p>
                  <img
                    src={Image.bsc}
                    className="img-fluid escrowCardPairImg"
                    alt=""
                  />
                  <p className="m-0 escrowCardPair__value">BNB</p>
                  <p className="m-0 escrowCardPair__value">~</p>
                  <p className="m-0 escrowCardPair__value">$108.42</p>
                </div>
              </div>
            </div>
          </Col>
        );
      })}
    </Row>
  );
};

export default EscrowLock;
