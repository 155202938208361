import React from "react";
import tv from "../Assets/Images/tv.png";
import check from "../Assets/Images/green-check.svg";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
function PopularSalesCard({ items }) {
  return (
    <Link to="" className="text-decoration-none">
      <div className="popularSales_card">
        <div className="sale_percentage">
          <p className="txt mb-0">Sale</p>
          <p className="value mb-0">35%</p>
        </div>
        <div className="img_sec">
          <img src={items.Img} alt="product" className="img-fluid" />
        </div>
        <div className="prod_details">
          <p className="type mb-0">{items.type}</p>
          <p className="prod-modal mb-0">Power Banks 1130 Mah</p>
          <div className="features">
            <p className="mb-0 list">
              <img src={check} className="img-fluid checkImg" />
              <span className="feature_txt">5.1 Home theater</span>
            </p>
            <p className="mb-0 list">
              <img src={check} className="img-fluid checkImg" />
              <span className="feature_txt">Bluetooth Specification</span>
            </p>
            <p className="mb-0 list">
              <img src={check} className="img-fluid checkImg" />
              <span className="feature_txt">Wi-Fi Support</span>
            </p>
          </div>
        </div>
        <Button className="AddToCart cmn-linearBtn">Add to cart</Button>
      </div>
    </Link>
  );
}

export default PopularSalesCard;
