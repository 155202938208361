import React, { useContext, useRef, useState } from "react";
import DashSidebar from "./DashSidebar";
import DashHeader from "./DashHeader";
import { GlobalContext } from "../../context/Context";
const DashLayout = ({ children }) => {
  const { isSidebarOpen } = useContext(GlobalContext);

  console.log('isSidebarOpen',isSidebarOpen)
  return (
    <div className="cmn_WholeDash">
      <DashSidebar />
      <DashHeader />
      <div className={` ${isSidebarOpen ? "width_less" : "width_full"}`}>
        <div className="dash_mainbar">
          <div className="container container_95">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default DashLayout;
