import React from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import closeBtn from "../../Assets/Images/modal_close.svg";

import logo from "../../Assets/Images/smlLogo.svg";
import seller from "../../Assets/Images/seller.svg";
import customer from "../../Assets/Images/customer.svg";
import bsc from "../../Assets/Images/bsc.png";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
function ChooseNetwork({ show, handleClose }) {
  return (
    <div>
      <Modal
        show={show}
        // onHide={handleClose}
        centered
        size="md"
        className="common_Modal_Popup choose_network_modal"
      >
        <Modal.Body>
          <Button className="close_btn" onClick={handleClose}>
            <img src={closeBtn} className="img-fluid" />
          </Button>

          <div className="content">
            <div className="head_sec text-center">
              <img src={logo} className="logo img-fluid" />
              <p className="name mb-0 mt-2">Choose Network</p>
            </div>
            <div className="type">
              <Button className="network_btn">
                <span className="network_img">
                  <img src={bsc} className="img-fluid" />
                </span>
                <span className="txt">BSC</span>
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ChooseNetwork;
