import React from "react";
import banner1 from "../Assets/Images/Best_Dealz/banner1.png";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
function BestdealzCard({ items }) {
  return (
    <Link to="" className="text-decoration-none">
      <div
        className="bestdealz_card"
        style={{ backgroundImage: `url(${items?.BgImg})` }}
      >
        <img src={items?.BgImg} className="img-fluid" />
        <div className="description">
          <p className="name_txt mb-0">{items?.Name}</p>
          <p className="name_txt mb-0">{items?.Type}</p>
          <Button className="showNow_btn shopBtn">
            <span>Shop now</span>
          </Button>
        </div>
      </div>
    </Link>
  );
}

export default BestdealzCard;
