import React, { useState } from "react";
import checked from "../Assets/Images/checked.png";
import { AiOutlinePlus } from "react-icons/ai";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Accordion from "react-bootstrap/Accordion";
import { BiSolidRightArrow } from "react-icons/bi";
import { BiSolidDownArrow } from "react-icons/bi";
function FrequentlyAskedQus() {
  const [activeKey, setActiveKey] = useState(null);
  const quesAns = [
    {
      id: 0,
      qus: "Lorem ipsum lorem 1?",
      ans: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata.",
    },
    {
      id: 1,
      qus: "Lorem ipsum lorem 2 ?",
      ans: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata.",
    },
    {
      id: 2,
      qus: "Lorem ipsum lorem 3 ?",
      ans: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata.",
    },
    {
      id: 4,
      qus: "Lorem ipsum lorem 4 ?",
      ans: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata.",
    },
    {
      id: 5,
      qus: "Lorem ipsum lorem 5 ?",
      ans: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata.",
    },
  ];

  const handleQusAns = (id) => {
    setActiveKey(activeKey === id ? null : id);
  };

  console.log("activeKey", activeKey);
  return (
    <div className="frequentlyAskQus">
      <div className="shipHeading">
        <span>Frequently Asked Questions</span>
        <img src={checked} className="img-fluid checkImg" />
      </div>
      <div className="allQusAns">
        <Accordion
          // activeKey={activeKey}
          className="qusAns_accordion"
        >
          {quesAns.map((item) => (
            <Accordion.Item eventKey={item.id.toString()} key={item.id}>
              <Accordion.Header onClick={() => handleQusAns(item.id)}>
                {item.id === activeKey ? (
                  <BiSolidDownArrow className="me-2" size={12} />
                ) : (
                  <BiSolidRightArrow className="me-2" size={12} />
                )}
                {item.qus}
              </Accordion.Header>
              <Accordion.Body>{item.ans}</Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </div>
    </div>
  );
}

export default FrequentlyAskedQus;
