export const getTime = ()=> {
    let hrs = new Date().getHours();
    let des = ""
    if(hrs >= 12){
        des = "P.M"
    }
    else{
        des = "A.M"
    }
    if(hrs > 12){
        hrs = hrs-12
    }

    return hrs?.toString()?.padStart(2,"0")+ ":" +  new Date().getMinutes()?.toString()?.padStart(2,"0") + " " + des
 }