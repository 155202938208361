import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link, useLocation, NavLink as NavLinks } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import Lottie from "lottie-react";
import { FaChevronDown, FaChevronUp, FaLeaf } from "react-icons/fa";
import { IoIosArrowForward } from "react-icons/io";
// import shopLottie from "../../Assets/Json/shop.json";
import shopLottie from "../../Assets/Json/shop1.json";
import NavHide from "../../Assets/Json/navHide.json";
import { IoClose } from "react-icons/io5";
import { PiLineVertical } from "react-icons/pi";
import { CiSearch } from "react-icons/ci";
import { IoIosArrowDown } from "react-icons/io";
import logo from "../../Assets/Images/logo.png";
import downArrow from "../../Assets/Images/down_arrow.png";
import verticalBar from "../../Assets/Images/vertical_line.png";
import searchIcon from "../../Assets/Images/search_icon.png";
import walletIcon from "../../Assets/Images/wallet_icon.png";
import icon1 from "../../Assets/Images/coin1.png";
import coin1 from "../../Assets/Images/coin1.png";
import coin2 from "../../Assets/Images/coin2.png";
import watch from "../../Assets/Images/watch.png";

import myorder from "../../Assets/Images/my-order.svg";
import message from "../../Assets/Images/message.svg";
import escrow from "../../Assets/Images/escrow.svg";
import wishlist from "../../Assets/Images/wishlist.svg";
import mycoupons from "../../Assets/Images/My-Coupons.svg";
import sign1 from "../../Assets/Images/sign1.svg";
import categoryFilter from "../../Assets/Images/category_Filter.png";
import hotDeals from "../../Assets/Images/hot_deals.png";

import country1 from "../../Assets/Images/india.svg";
import country2 from "../../Assets/Images/US.svg";
import searchChecked from "../../Assets/Images/checked.png";
import verticalLine2 from "../../Assets/Images/vertical_line2.png";

import menFashion from "../../Assets/Images/men.png";
import womenFashion from "../../Assets/Images/womensFashion.png";
import homeKitchen from "../../Assets/Images/homeKitchen.png";
import toyFashions from "../../Assets/Images/toy.png";
import electronics from "../../Assets/Images/electronics.png";
import cosmetics from "../../Assets/Images/cosmetics.png";
import trendingSearch from "../../Assets/Images/trending_Search.png";
import uparrow from "../../Assets/Images/uparrow.png";
import ChooseNetwork from "../Modals/ChooseNetwork";
import ShiplocationModal from "../Modals/ShiplocationModal";
import BitdealzMarketPlaceModal from "../Modals/BitdealzMarketPlaceModal";
import SignUpModal from "../Modals/SignUpModal";
import SignUpPasskeyModal from "../Modals/SignUpPasskeyModal";
import SignUpPasskeyCnfmModal from "../Modals/SignUpPasskeyCnfmModal";
import SignupSuccessModal from "../Modals/SignupSuccessModal";
import LoginPasskeyModal from "../Modals/LoginPasskeyModal";

import ExistingPasskeyModal from "../Modals/ExistingPasskeyModal";
import CreateNewPasskeyModal from "../Modals/CreateNewPasskeyModal";
import CnfmNewPassKeyModal from "../Modals/CnfmNewPassKeyModal";
import PasscodeCreateSuccessModal from "../Modals/PasscodeCreateSuccessModal";
import ResetPasscodeModal from "../Modals/ResetPasscodeModal";
import AccountSigninModal from "../Modals/AccountSignIn";
import PasscodeLoginModal from "../Modals/PasscodeLoginModal";
import ExistingPasscodeModal from "../Modals/ExistingPasscodeModal";
import CreateNewPasscodeModal from "../Modals/CreateNewPasscodeModal";
import ConfirmCreatePasscodeModal from "../Modals/ConfirmCreatePasscodeModal";
import PasskeyCreateSuccessModal from "../Modals/PasskeyCreateSuccessModal";
import PasskeyLoginModal from "../Modals/PasskeyLoginModal";
import ScanQrModal from "../Modals/ScanQrModal";
import SecurityLoginModal from "../Modals/SecurityLoginModal";
import PasskeySecurityLoginModal from "../Modals/PasskeySecurityLoginModal";
import AllowSiteModal from "../Modals/AllowSiteModal";
import PasskeyCreatedModal from "../Modals/PasskeyCreatedModal";
import ResetPasskeyModal from "../Modals/ResetPasskeyModal";

function Header({ handleFocus }) {
  const [navOpen, setNavOpen] = useState(false);
  const [networkShow, SetNetwotkShow] = useState(false);
  const [shipLocationShow, SetShipLocationShow] = useState(false);
  const [signUpShow, SetSignUpShow] = useState(false);
  const [accountSignUpShow, SetAccountSignUpShow] = useState(false);

  const [signUpPasskeyShow, SetSignUpPasskeyShow] = useState(false);
  const [CnfmSignUpPasskeyShow, SetCnfmSignUpPasskeyShow] = useState(false);
  const [SignUpSucShow, SetSignUpSuccessShow] = useState(false);

  const [signinPasskeyShow, SetSigninPasskeyShow] = useState(false);




  const [existingNewPasskeyShow, SetExistingPasskeyShow] = useState(false);
  const [createNewpasskeyShow, SetCreateNewpasskeyShow] = useState(false);
  const [cnfmNewpasskeyShow, SetCnfmNewpasskeyShow] = useState(false);
  const [successfullypasskeyShow, SetSuccessfullypasskeyShow] = useState(false);

  const [resetPasscodeShow, SetResetPasscodeShow] = useState(false);
  // const [accSigninShow, SetAccSigninShow] = useState(false);
  const [passcodeLoginShow, SetPasscodeLoginShow] = useState(false);

  const [signinShow, SetSigninShow] = useState(false);

  const [existingPasscode, SetExistingPasscode] = useState(false);
  const [createPasscode, SetCreatePasscode] = useState(false);
  const [cnfmPasscode, SetCnfmPasscode] = useState(false);
  const [PasscodeCreateSuccess, SetPasscodeCreateSuccess] = useState(false);
  const [resetPasskey, setResetPasskey] = useState(false);
  const [PasskeyLogin, SetPasskeyLogin] = useState(false);
  const [PasskeyDeviceLogin, SetPasskeyDeviceLogin] = useState(false);
  const [PasskeySecurityLogin, SetPasskeySecurityLogin] = useState(false);
  const [PasskeySecurityPinLogin, SetPasskeySecurityPinLogin] = useState(false);
  const [PasskeySecurityAllowLogin, SetPasskeySecurityAllowLogin] =
    useState(false);
  const [PasskeySecurityLoginSuccess, SetPasskeySecurityLoginSuccess] =
    useState(false);

  const [languageTab, setLanguageTab] = useState(false);
  const [networkTab, setNetworkTab] = useState(false);
  const [currencyTab, setCurrencyTab] = useState(false);

  const handleNetworkShow = () => {
    SetNetwotkShow(true);
  };

  const handleNetworkClose = () => {
    SetNetwotkShow(false);
  };

  const handleShipLocationShow = () => {
    SetShipLocationShow(true);
  };

  const handleShipLocationClose = () => {
    SetShipLocationShow(false);
  };

  const handleSignUpShow = () => {
    SetSignUpShow(true);
  };

  const handleSignUpClose = () => {
    SetSignUpShow(false);
  };

  const handleAccountSignupShow = () => {
    SetSignUpShow(false);
    SetAccountSignUpShow(true);
  };

  const handleAccountSignupClose = () => {
    SetAccountSignUpShow(false);
  };

  const handleSignupPasskeyShow = () => {
    SetAccountSignUpShow(false);
    SetSignUpPasskeyShow(true);
  };

  const handlePasskeySignInShow = () => {
    SetAccountSignUpShow(false);
    SetSigninPasskeyShow(true);
  };

  const handleSignupPasskeyClose = () => {
    SetSignUpPasskeyShow(false);
  };

  const CnfmSignupPasskeyShow = () => {
    SetSignUpPasskeyShow(false);
    SetCnfmSignUpPasskeyShow(true);
  };

  const CnfmSignupPasskeyClose = () => {
    SetCnfmSignUpPasskeyShow(false);
  };

  const SignupSuccessShow = () => {
    SetCnfmSignUpPasskeyShow(false);
    SetSignUpSuccessShow(true);
  };

  const SignupSuccessClose = () => {
    SetSignUpSuccessShow(false);
  };

  const SigninPasskeyClose = () => {
    SetSigninPasskeyShow(false);
  };

  const ExistingPasskeyShow = () => {
    SetSigninPasskeyShow(false);
    SetExistingPasskeyShow(true);
  };

  const ExistingPasskeyClose = () => {
    SetExistingPasskeyShow(false);
  };

  const ExistingResetPasskeyShow = () => {
    SetExistingPasskeyShow(false);
    setResetPasskey(true);
  };

  const handleResetPasskeyShow = () => {
    SetSigninPasskeyShow(false);
    setResetPasskey(true);
  };

  const handleResetPasskeyClose = () => {
    setResetPasskey(false);
  };

  const CreatePasskeyShow = () => {
    SetExistingPasskeyShow(false);
    SetCreateNewpasskeyShow(true);
  };

  const CreatePasskeyClose = () => {
    SetCreateNewpasskeyShow(false);
  };

  const CreateResetPasskeyShow = () => {
    SetCreateNewpasskeyShow(false);
    setResetPasskey(true);
  };

  const CnfmNewPasskeyShow = () => {
    SetCreateNewpasskeyShow(false);
    SetCnfmNewpasskeyShow(true);
  };

  const CnfmNewPasskeyClose = () => {
    SetCnfmNewpasskeyShow(false);
  };

  const ConfirmResetPasskeyShow = () => {
    SetCnfmNewpasskeyShow(false);
    setResetPasskey(true);
  };

  const SuccessfullyCreatePasskeyShow = () => {
    SetCnfmNewpasskeyShow(false);
    SetSuccessfullypasskeyShow(true);
  };

  const SuccessfullyCreatePasskeyClose = () => {
    SetSuccessfullypasskeyShow(false);
  };
  const LoginSuccessShow = () => {
    SetSuccessfullypasskeyShow(false);
    SetSigninPasskeyShow(true);
  };

  const shopProduct = [
    {
      id: 1,
    },
    {
      id: 2,
    },
    {
      id: 3,
    },
    {
      id: 4,
    },
  ];

  const countrySearch = [
    {
      icon: country1,
      name: "USD - US Doller",
      active: true,
    },
    {
      icon: country2,
      name: "INR - Indian Rupee",
      active: false,
    },
    {
      icon: country1,
      name: "USD - US Doller",
      active: false,
    },
  ];
  const currencySearch = [
    {
      icon: coin1,
      name: "Binance coin - BNB",
      active: false,
    },
    {
      icon: coin1,
      name: "Binance coin - BNB",
      active: true,
    },
    {
      icon: coin1,
      name: "Binance coin - BNBr",
      active: false,
    },
  ];

  const TopCategories = [
    {
      img: menFashion,
      name: "Men's & Fashions",
    },
    {
      img: womenFashion,
      name: "Women's Fashions",
    },
    {
      img: homeKitchen,
      name: "Home & Kitchens",
    },
    {
      img: toyFashions,
      name: "Toys, Kids Fashions",
    },
    {
      img: electronics,
      name: "Electronics & Appliance",
    },
    {
      img: cosmetics,
      name: "Cosmetics & Things",
    },
  ];

  const MoreCategories = [
    {
      name: "Mobile Phone & Gatgets",
    },
    {
      name: "Women's Fashions",
    },
    {
      name: "Home & Kitchens",
    },
    {
      name: "Toys, Kids Fashions",
    },
    {
      name: "Electronics & Appliance",
    },
    {
      name: "Cosmetics & Things",
    },
  ];
  const TrendingSearch = [
    {
      name: "Mobile Phone & Gatgets",
    },
    {
      name: "Mobile Phone & Gatgets",
    },
    {
      name: "Mobile Phone & Gatgets",
    },
    {
      name: "Mobile Phone & Gatgets",
    },
    {
      name: "Mobile Phone & Gatgets",
    },
    {
      name: "Mobile Phone & Gatgets",
    },
  ];

  const subCategory = [
    {
      id: 1,
    },
    {
      id: 2,
    },
    {
      id: 3,
    },
    {
      id: 4,
    },
    {
      id: 5,
    },
    {
      id: 6,
    },
    {
      id: 7,
    },
    {
      id: 8,
    },
  ];

  const handleNavClose = () => {
    setNavOpen(!navOpen);
  };

  const handleResetPasscodeShow = () => {
    SetPasscodeLoginShow(false);
    SetResetPasscodeShow(true);
  };

  const ResetPasscodeClose = () => {
    SetResetPasscodeShow(false);
  };

  const handleSignInShow = () => {
    SetSigninShow(true);
  };

  const handleSignInClose = () => {
    SetSigninShow(false);
  };

  const handlePasscodeLoginShow = () => {
    SetSigninShow(false);
    SetPasscodeLoginShow(true);
  };

  const PasscodeLoginClose = () => {
    SetPasscodeLoginShow(false);
  };

  const ExistingPasscodeShow = () => {
    SetPasscodeLoginShow(false);
    SetExistingPasscode(true);
  };
  const ExistingPasscodeClose = () => {
    SetExistingPasscode(false);
  };

  const handleResetPasscodeShow1 = () => {
    SetExistingPasscode(false);
    SetResetPasscodeShow(true);
  };

  const handleCreatePasscodeShow = () => {
    SetExistingPasscode(false);
    SetCreatePasscode(true);
  };
  const handleCreatePasscodeClose = () => {
    SetCreatePasscode(false);
  };

  const handleResetPasscodeShow2 = () => {
    SetCreatePasscode(false);
    SetResetPasscodeShow(true);
  };

  const handleCnfmPasscodeShow = () => {
    SetCreatePasscode(false);
    SetCnfmPasscode(true);
  };
  const handleCnfmPasscodeClose = () => {
    SetCnfmPasscode(false);
  };
  const handleResetPasscodeShow3 = () => {
    SetCnfmPasscode(false);
    SetResetPasscodeShow(true);
  };

  const handlePasscodeCreateSuccessShow = () => {
    SetCnfmPasscode(false);
    SetPasscodeCreateSuccess(true);
  };

  const handlePasscodeCreateSuccessClose = () => {
    SetPasscodeCreateSuccess(false);
  };

  const handlePasscodeLoginSuccessShow = () => {
    SetPasscodeCreateSuccess(false);
    SetPasscodeLoginShow(true);
  };

  const handleSigninPasskeyShow = () => {
    SetSigninShow(false);
    SetPasskeyLogin(true);
  };

  const handleSigninPasskeyClose = () => {
    SetPasskeyLogin(false);
  };

  const handleDeviceLoginShow = () => {
    SetPasskeyLogin(false);
    SetPasskeyDeviceLogin(true);
  };
  const handleDeviceLoginClose = () => {
    SetPasskeyDeviceLogin(false);
  };

  const handleSecurityPKLoginShow = () => {
    SetPasskeyLogin(false);
    SetPasskeySecurityLogin(true);
  };

  const handleSecurityPKLoginClose = () => {
    SetPasskeySecurityLogin(false);
  };

  const handleSecurityPinPkLoginShow = () => {
    SetPasskeySecurityLogin(false);
    SetPasskeySecurityPinLogin(true);
  };
  const handleSecurityPinPkLoginClose = () => {
    SetPasskeySecurityPinLogin(false);
  };

  const handlePkAllowSecurityShow = () => {
    SetPasskeySecurityPinLogin(false);
    SetPasskeySecurityAllowLogin(true);
  };

  const handlePkAllowSecurityClose = () => {
    SetPasskeySecurityAllowLogin(false);
  };

  const handleSecurityLoginSuccessShow = () => {
    SetPasskeySecurityAllowLogin(false);
    SetPasskeySecurityLoginSuccess(true);
  };
  const handleSecurityLoginSuccessClose = () => {
    SetPasskeySecurityLoginSuccess(false);
  };

  const handleSecurityUseLoginClose = () => {
    SetPasskeySecurityLogin(false);
    SetPasskeyLogin(true);
  };

  const handleSecurityPinLoginClose = () => {
    SetPasskeySecurityPinLogin(false);
    SetPasskeyLogin(true);
  };

  const handleSecurityAllowLoginClose = () => {
    SetPasskeySecurityAllowLogin(false);
    SetPasskeyLogin(true);
  };

  const handleLanguageCurrency = () => {
    setLanguageTab(false);
    setNetworkTab(false);
    setCurrencyTab(false);
  };

  return (
    <>
      <div className={!navOpen ? "header" : "header"}>
        <Navbar expand="lg" className="custom-nav" fixed="top">
          <Container
            className={
              !navOpen
                ? "nav_customContainer ani-reverse"
                : "nav_customContainer ani"
            }
          >
            <div className={navOpen ? "fst_nav" : "fst_nav radius"}>
              <Navbar.Brand href="#" className="me-0">
                <Link to="/">
                  <img src={logo} className="img-fluid logo" />
                </Link>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="navbarScroll" />
              <Navbar.Collapse id="navbarScroll">
                <div className="header_content">
                  <div className="search_sec">
                    <Dropdown className="allProduct_listsDrop">
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        <img src={downArrow} className="img-fluid downarrow" />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href="">All Categories</Dropdown.Item>
                        <Dropdown.Item href="">Appliances</Dropdown.Item>
                        <Dropdown.Item href="">Apps & Games</Dropdown.Item>
                        <Dropdown.Item href="">Baby Products</Dropdown.Item>
                        <Dropdown.Item href="">Books</Dropdown.Item>
                        <Dropdown.Item href="">Car & Motorbikes</Dropdown.Item>
                        <Dropdown.Item href="">
                          Clothing's & Collectibles
                        </Dropdown.Item>
                        <Dropdown.Item href="">Dresses</Dropdown.Item>
                        <Dropdown.Item href="">Deals</Dropdown.Item>
                        <Dropdown.Item href="">Electronics</Dropdown.Item>
                        <Dropdown.Item href="">Furniture</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <span className="all-txt">ALL</span>

                    <img src={verticalBar} className="img-fluid vertical_bar" />
                    <Form.Control
                      type="email"
                      placeholder="Search products...."
                      className="search_product"
                    />
                    <Link
                      to="/search-products"
                      className="text-decoration-none"
                    >
                      <Button className="searchBtn">
                        <img
                          src={searchIcon}
                          className="img-fluid vertical_bar"
                        />
                      </Button>
                    </Link>
                  </div>
                  <div className="connectType_sec">
                    <Button
                      className="network_btn cmn-linearBtn"
                      onClick={handleNetworkShow}
                    >
                      <img src={icon1} className="img-fluid icon" />
                      <span className="name">BSC</span>
                      <IoIosArrowDown />
                    </Button>
                    <div className="wallet_icon_hvr">
                      <img src={walletIcon} className="img-fluid walletIcon" />
                      <div className="wallet_dropContent cmn_hvr_Arrow">
                        <Button
                          className="sign-InUp-Btn mb-2 active"
                          onClick={handleSignInShow}
                        >
                          Sign In
                        </Button>
                        <Button
                          className="sign-InUp-Btn"
                          onClick={handleSignUpShow}
                        >
                          {/* Sign Up */}
                          Account
                        </Button>
                        <div className="mt-2 my-info">
                          <p className="mb-2 txt">
                            <img src={myorder} className="img-fluid icons" />
                            My Order
                          </p>
                          <p className="mb-2 txt">
                            <img src={message} className="img-fluid icons" />
                            Message Centre
                          </p>
                          <p className="mb-2 txt">
                            <img src={escrow} className="img-fluid icons" />
                            Escrow
                          </p>
                          <p className="mb-2 txt">
                            <img src={wishlist} className="img-fluid icons" />
                            Wishlist
                          </p>
                          <p className="mb-2 txt">
                            <img src={mycoupons} className="img-fluid icons" />
                            My Coupons
                          </p>
                        </div>
                        <Link to="/overview">
                          <Button className="mt-2 dashboard_btn cmn-linearBtn">
                            Dashboard
                          </Button>
                        </Link>
                        <div className="uparrow">
                          <img src={uparrow} alt="" className="img-fluid" />
                        </div>
                      </div>
                    </div>
                    <div className="shop_icon_hvr navShop_details">
                      <Lottie
                        animationData={shopLottie}
                        className="shop_lottie"
                      />
                      {/* <img src={walletIcon} className="img-fluid shop_icon" /> */}

                      <div className="dropContent cmn_hvr_Arrow">
                        <div className="allProd">
                          {shopProduct.map((item, index) => {
                            return (
                              <div className="products_list">
                                <img
                                  src={watch}
                                  className="img-fluid watch_img"
                                />
                                <div>
                                  <p className="prod_name mb-0">
                                    Smart Watch for Android
                                  </p>
                                  <p className="prod_price mb-0">
                                    0.15
                                    <img
                                      src={coin2}
                                      className="img-fluid prod_img"
                                    />
                                    BNB
                                    <img
                                      src={sign1}
                                      className="img-fluid sign_img"
                                    />
                                    $108.42
                                  </p>
                                </div>
                                <IoClose className="close_icon" />
                              </div>
                            );
                          })}
                        </div>
                        <hr className="cus_hr" />
                        <div className="total">
                          <span className="txt">Subtotal</span>
                          <span className="val">$800.00</span>
                        </div>
                        <div className="btns mt-3">
                          <Button className="viewcart_btn">View Cart</Button>
                          <Button className="checkout_btn mt-2">
                            Checkout Now
                          </Button>
                        </div>
                        <div className="uparrow">
                          <img src={uparrow} alt="" className="img-fluid" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Navbar.Collapse>
            </div>
            {/* <hr className={navOpen ? "nav_hr" : "d-none"} /> */}
            <div className={navOpen ? "snd_nav show" : "snd_nav hide"}>
              <div className="fst_sec">
                <div className="categories_hvr_drop">
                  <Button className="categoryBtn">
                    <img
                      src={categoryFilter}
                      className="img-fluid categoryFilter_img"
                    />
                    Categories
                  </Button>
                  <div className="categories_drop_content">
                    <div className="top_categories">
                      <p className="mb-0 title_txt">TOP CATEGORIES</p>
                      <div className="categories_list">
                        {TopCategories.map((item, index) => {
                          return (
                            <div className="cat_list_items">
                              <div className="items_List">
                                <div className="item">
                                  <img src={item.img} className="cat_img" />
                                  <span className="cat_name">{item.name}</span>
                                  {/* <img src={menFashion} className="cat_img" />
                                <span className="cat_name">Men's</span> */}
                                </div>
                              </div>
                              <div className="categories_subContent_hvr">
                                <div className="details">
                                  {subCategory.map((item, index) => {
                                    return (
                                      <div>
                                        <p className="mb-0 title">
                                          MEN'S SHIRTS
                                        </p>
                                        <div className="mt-2">
                                          <p className="sub_prod mb-2">
                                            Lorem Ipsum dolor
                                          </p>
                                          <p className="sub_prod mb-2">
                                            Lorem Ipsum dolor
                                          </p>
                                          <p className="sub_prod mb-2">
                                            Lorem Ipsum dolor
                                          </p>
                                          <p className="sub_prod mb-2">
                                            Lorem Ipsum dolor
                                          </p>
                                          <p className="sub_prod mb-2">
                                            Lorem Ipsum dolor
                                          </p>
                                          <p className="sub_prod mb-2">
                                            Lorem Ipsum dolor
                                          </p>
                                        </div>
                                        <Link to="/" className="see-all">
                                          SEE ALL{" "}
                                          <IoIosArrowForward size={15} />
                                        </Link>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div className="more_categories">
                      <p className="mb-0 title_txt">MORE CATEGORIES</p>
                      <div className="categories_list">
                        {MoreCategories.map((item, index) => {
                          return (
                            <div className="item">
                              <span className="cat_name">{item.name}</span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div className="more_categories">
                      <p className="mb-0 title_txt">TRENDING SEARCHES</p>
                      <div className="categories_list">
                        {TrendingSearch.map((item, index) => {
                          return (
                            <div className="item">
                              <img
                                src={trendingSearch}
                                className="trending_search"
                              />
                              <span className="cat_name">{item.name}</span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div className="uparrow">
                      <img src={uparrow} alt="" className="img-fluid" />
                    </div>
                  </div>
                </div>
                <Button className="hotDealzBtn">
                  <img src={hotDeals} className="img-fluid hotDealz_img" />
                  Hot Deals !
                </Button>
              </div>
              <div className="snd_sec">
                <Link to="/" className="customNavlink">
                  Home
                </Link>
                <Link to="/todays-deal" className="customNavlink">
                  Today's Deal
                </Link>
                <Dropdown className="sell_crypto_drop cmnAll_dropdown">
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    Sell for crypto <FaChevronDown />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="">All</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

                <Dropdown className="services_drop cmnAll_dropdown">
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    Services <FaChevronDown />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="">All</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="trd_sec">
                <div className="langInr_drop_hvr">
                  <span className="heading_txt">ENG - INR</span>
                  <div
                    className="langInr_drop_content"
                    onMouseLeave={handleLanguageCurrency}
                  >
                    <div className="sec">
                      <p
                        className="mb-0 title_txt d-flex align-items-center justify-content-between"
                        onClick={() => setLanguageTab(!languageTab)}
                      >
                        Language
                        {languageTab ? <FaChevronUp /> : <FaChevronDown />}
                      </p>
                      <span></span>

                      {languageTab && (
                        <Dropdown className="mt-2 language_drop cmnAll_dropdown">
                          <Dropdown.Toggle
                            variant="success"
                            id="dropdown-basic"
                          >
                            English <FaChevronDown />
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item href="">Tamil</Dropdown.Item>
                            <Dropdown.Item href="">English</Dropdown.Item>
                            <Dropdown.Item href="">Spanish</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      )}
                    </div>
                    <hr className="lang_dropHr" />

                    <div className="sec">
                      <p
                        className="mb-0 title_txt d-flex align-items-center justify-content-between"
                        onClick={() => setNetworkTab(!networkTab)}
                      >
                        Block chain Networks
                        {networkTab ? <FaChevronUp /> : <FaChevronDown />}
                      </p>
                      {networkTab && (
                        <Button
                          className="mt-2 network_btn cmn-linearBtn"
                          onClick={handleNetworkShow}
                        >
                          <img src={icon1} className="img-fluid icon" />
                          <span className="name">BSC</span>
                          <IoIosArrowDown className="down_arrow" />
                        </Button>
                      )}
                    </div>
                    <hr className="lang_dropHr" />
                    <div className="pb-3">
                      <div className="sec">
                        <p
                          className="mb-0 title_txt d-flex align-items-center justify-content-between"
                          onClick={() => setCurrencyTab(!currencyTab)}
                        >
                          Currency Management
                          {currencyTab ? <FaChevronUp /> : <FaChevronDown />}
                        </p>
                      </div>
                      {currencyTab && (
                        <div className="choose_allCurrency sec">
                          <div className="mt-2 chooseCurrency_Filter">
                            <p className="title_txt mb-0">
                              Choose fiat currency
                            </p>
                            <div className="content">
                              <div className="custom_search">
                                <input
                                  type="text"
                                  className=""
                                  placeholder="Type a currency name"
                                />
                                <CiSearch className="search_iocn" />
                              </div>
                              <div className="search_items">
                                {countrySearch.map((item, index) => {
                                  return (
                                    <div className="search_items_list">
                                      <div className="logoName">
                                        <img
                                          src={item.icon}
                                          className="search_logos"
                                        />
                                        <span className="search_name">
                                          {item.name}
                                        </span>
                                      </div>
                                      {item.active ? (
                                        <img
                                          src={searchChecked}
                                          className="search_checked"
                                        />
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                          <div className="mt-3 chooseCurrency_Filter">
                            <p className="title_txt mb-0">
                              Choose Crypto currency
                            </p>
                            <div className="content">
                              <div className="custom_search">
                                <input
                                  type="text"
                                  className=""
                                  placeholder="Type a crypto currency name"
                                />
                                <CiSearch className="search_iocn" />
                              </div>
                              <div className="search_items">
                                {currencySearch.map((item, index) => {
                                  return (
                                    <div className="search_items_list">
                                      <div className="logoName">
                                        <img
                                          src={item.icon}
                                          className="search_logos"
                                        />
                                        <span className="search_name">
                                          {item.name}
                                        </span>
                                      </div>
                                      {item.active ? (
                                        <img
                                          src={searchChecked}
                                          className="search_checked"
                                        />
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="uparrow">
                        <img src={uparrow} alt="" className="img-fluid" />
                      </div>
                    </div>
                    <div className="uparrow">
                      <img src={uparrow} alt="" className="img-fluid" />
                    </div>
                  </div>
                </div>
                <img src={verticalLine2} className="img-fluid vertical_bar2" />
                <Button className="shipTo_Btn" onClick={handleShipLocationShow}>
                  <span className="name">Ship to :</span>
                  <img src={country1} className="img-fluid countryLogo" />
                  <IoIosArrowDown className="downArrow" />
                </Button>
                <div className="headerNavHide_lottie" onClick={handleNavClose}>
                  <Lottie animationData={NavHide} className="navHide_lottie" />
                </div>
              </div>
            </div>
          </Container>
          {/* {!navOpen ? (
            <div className="navShowHide_loader" onClick={handleNavClose}>
              <Lottie animationData={NavHide} className="navHide_lottie" />
            </div>
          ) : (
            ""
          )} */}
        </Navbar>
        {!navOpen ? (
          <div className="navShowHide_loader" onClick={handleNavClose}>
            <Lottie animationData={NavHide} className="navHide_lottie" />
          </div>
        ) : (
          ""
        )}
      </div>
      {/* Choose Network Modal */}
      {networkShow && (
        <ChooseNetwork show={networkShow} handleClose={handleNetworkClose} />
      )}
      {/* Ship Location Modal */}
      {shipLocationShow && (
        <ShiplocationModal
          show={shipLocationShow}
          handleClose={handleShipLocationClose}
        />
      )}
      {/* Sign In Up Modal */}
      {signUpShow && (
        <BitdealzMarketPlaceModal
          show={signUpShow}
          handleClose={handleSignUpClose}
          handleAccountSignupShow={handleAccountSignupShow}
        />
      )}
      {/* Account Signup Modal */}
      {accountSignUpShow && (
        <SignUpModal
          show={accountSignUpShow}
          handleClose={handleAccountSignupClose}
          handleSignupPasskeyShow={handleSignupPasskeyShow}
          handlePasskeySignInShow={handlePasskeySignInShow}
        />
      )}
      {/* Signup With Passkey Modal */}
      {signUpPasskeyShow && (
        <SignUpPasskeyModal
          show={signUpPasskeyShow}
          handleClose={handleSignupPasskeyClose}
          CnfmSignupPasskeyShow={CnfmSignupPasskeyShow}
        />
      )}
      {/* Confirm Signup With Passkey Modal */}
      {CnfmSignUpPasskeyShow && (
        <SignUpPasskeyCnfmModal
          show={CnfmSignUpPasskeyShow}
          handleClose={CnfmSignupPasskeyClose}
          SignupSuccessShow={SignupSuccessShow}
        />
      )}
      {/* Signup Success Modal */}
      {SignUpSucShow && (
        <SignupSuccessModal
          show={SignUpSucShow}
          handleClose={SignupSuccessClose}
          // CnfmSignupPasskeyShow={CnfmSignupPasskeyShow}
        />
      )}
      {/* Signin Passkey Modal */}
      {signinPasskeyShow && (
        <LoginPasskeyModal
          show={signinPasskeyShow}
          handleClose={SigninPasskeyClose}
          ExistingPasskeyShow={ExistingPasskeyShow}
          handleResetPasskeyShow={handleResetPasskeyShow}
        />
      )}

      {resetPasskey && (
        <ResetPasskeyModal
          show={resetPasskey}
          handleClose={handleResetPasskeyClose}
        />
      )}
      {/* Existing Passkey Modal */}
      {existingNewPasskeyShow && (
        <ExistingPasskeyModal
          show={existingNewPasskeyShow}
          handleClose={ExistingPasskeyClose}
          CreatePasskeyShow={CreatePasskeyShow}
          handleReset={ExistingResetPasskeyShow}
        />
      )}
      {/* Create Passkey Modal */}
      {createNewpasskeyShow && (
        <CreateNewPasskeyModal
          show={createNewpasskeyShow}
          handleClose={CreatePasskeyClose}
          CnfmNewPasskeyShow={CnfmNewPasskeyShow}
          handleReset={CreateResetPasskeyShow}
        />
      )}
      {/* Confirm New Passkey Modal */}
      {cnfmNewpasskeyShow && (
        <CnfmNewPassKeyModal
          show={cnfmNewpasskeyShow}
          handleClose={CnfmNewPasskeyClose}
          SuccessfullyCreatePasskeyShow={SuccessfullyCreatePasskeyShow}
          handleReset={ConfirmResetPasskeyShow}
        />
      )}

      {/* Successfully Create New Passkey Modal */}
      {successfullypasskeyShow && (
        <PasskeyCreateSuccessModal
          show={successfullypasskeyShow}
          handleClose={SuccessfullyCreatePasskeyClose}
          LoginSuccessShow={LoginSuccessShow}
        />
      )}

      {/* Forgot Password Modal */}
      {resetPasscodeShow && (
        <ResetPasscodeModal
          show={resetPasscodeShow}
          handleClose={ResetPasscodeClose}
        />
      )}

      {/* Account Signin Modal */}
      {signinShow && (
        <AccountSigninModal
          show={signinShow}
          handleClose={handleSignInClose}
          handlePasscodeShow={handlePasscodeLoginShow}
          handleSigninPasskeyShow={handleSigninPasskeyShow}
        />
      )}

      {/* Passcode Login Modal */}
      {passcodeLoginShow && (
        <PasscodeLoginModal
          show={passcodeLoginShow}
          handleClose={PasscodeLoginClose}
          handleResetPasscodeShow={handleResetPasscodeShow}
          ExistingPasscodeShow={ExistingPasscodeShow}
        />
      )}
      {/* Existing Passcode Modal */}
      {existingPasscode && (
        <ExistingPasscodeModal
          show={existingPasscode}
          handleClose={ExistingPasscodeClose}
          handleResetPasscodeShow={handleResetPasscodeShow1}
          handleCreatePasscodeShow={handleCreatePasscodeShow}
        />
      )}
      {/* Create Passcode Modal */}
      {createPasscode && (
        <CreateNewPasscodeModal
          show={createPasscode}
          handleClose={handleCreatePasscodeClose}
          handleResetPasscodeShow={handleResetPasscodeShow2}
          handleCnfmPasscodeShow={handleCnfmPasscodeShow}
        />
      )}
      {/* Confirm Passcode Modal */}
      {cnfmPasscode && (
        <ConfirmCreatePasscodeModal
          show={cnfmPasscode}
          handleResetPasscodeShow={handleResetPasscodeShow3}
          handleClose={handleCnfmPasscodeClose}
          handlePasscodeCreateSuccess={handlePasscodeCreateSuccessShow}
        />
      )}
      {/* Passcode Create Successfully Modal */}
      {PasscodeCreateSuccess && (
        <PasscodeCreateSuccessModal
          show={PasscodeCreateSuccess}
          handleClose={handlePasscodeCreateSuccessClose}
          handlePasscodeLoginSuccessShow={handlePasscodeLoginSuccessShow}
        />
      )}
      {/* Passkey Login way Modal */}
      {PasskeyLogin && (
        <PasskeyLoginModal
          show={PasskeyLogin}
          handleClose={handleSigninPasskeyClose}
          handleDeviceLoginShow={handleDeviceLoginShow}
          handleSecurityPKLoginShow={handleSecurityPKLoginShow}
        />
      )}

      {/* Scan Qr passkey login */}
      {PasskeyDeviceLogin && (
        <ScanQrModal
          show={PasskeyDeviceLogin}
          handleClose={handleDeviceLoginClose}
        />
      )}

      {/* Passkey security login */}
      {PasskeySecurityLogin && (
        <SecurityLoginModal
          show={PasskeySecurityLogin}
          handleClose={handleSecurityPKLoginClose}
          handleSecurityPinPkLoginShow={handleSecurityPinPkLoginShow}
          handleSecurityUseLoginClose={handleSecurityUseLoginClose}
        />
      )}
      {/* Passkey security pin login */}
      {PasskeySecurityPinLogin && (
        <PasskeySecurityLoginModal
          show={PasskeySecurityPinLogin}
          handleClose={handleSecurityPinPkLoginClose}
          handlePkAllowSecurityShow={handlePkAllowSecurityShow}
          handleSecurityPinLoginClose={handleSecurityPinLoginClose}
        />
      )}
      {/* Passkey security allow login */}
      {PasskeySecurityAllowLogin && (
        <AllowSiteModal
          show={PasskeySecurityAllowLogin}
          handleClose={handlePkAllowSecurityClose}
          handleSecurityLoginSuccessShow={handleSecurityLoginSuccessShow}
          handleSecurityAllowLoginClose={handleSecurityAllowLoginClose}
        />
      )}
      {/* Passkey security login success */}
      {PasskeySecurityLoginSuccess && (
        <PasskeyCreatedModal
          show={PasskeySecurityLoginSuccess}
          handleClose={handleSecurityLoginSuccessClose}
        />
      )}
    </>
  );
}

export default Header;
