import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Banner1 from "../Assets/Images/Banner/Banner1.png";
import Banner2 from "../Assets/Images/Banner/Banner2.png";
import Banner3 from "../Assets/Images/Banner/Banner3.png";
import Banner4 from "../Assets/Images/Banner/Banner4.png";
import { Link } from "react-router-dom";

function Banner() {
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const Banner = [
    {
      id: 1,
      banner: Banner1,
    },
    {
      id: 2,
      banner: Banner2,
    },
    {
      id: 3,
      banner: Banner3,
    },
    {
      id: 4,
      banner: Banner4,
    },
  ];
  return (
    <div className="home_banner">
      <div className="container">
        <Slider {...settings} className="banner_slide">
          {Banner.map((item, index) => {
            return (
              <Link to="/">
                <img
                  src={item.banner}
                  alt="banner"
                  className="banner_img img-fluid"
                />
              </Link>
            );
          })}
        </Slider>
        {/* <p>adasdas</p> */}
      </div>
    </div>
  );
}

export default Banner;
