import React from "react";
import Header from "../Components/Layout/Header";
import HomePage from "../Components/HomePage";
import Footer from "../Components/Layout/Footer";

function Landing() {
  return (
    <div className="landing">
      <Header />
      <HomePage />
      <Footer />
    </div>
  );
}

export default Landing;
