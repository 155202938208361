import React, { useState } from "react";
import checked from "../Assets/Images/checked.png";
import { AiOutlinePlus } from "react-icons/ai";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import AddAddressModal from "./Modals/AddAddressModal";

function ShippingAddressList() {
  const [primaryAddress, setPrimaryAddress] = useState(1);
  const [addAddress, setAddAddress] = useState(false);

  const address = [
    {
      id: 1,
    },
    {
      id: 2,
    },
  ];

  const handleAddress = (item) => {
    setPrimaryAddress(item.id);
  };

  const handleAddressShow = () => {
    setAddAddress(true);
  };

  const handleAddressClose = () => {
    setAddAddress(false);
  };

  return (
    <>
      {addAddress && (
        <AddAddressModal show={addAddress} handleClose={handleAddressClose} />
      )}

      <div className="shippingAddress_list">
        <div className="shipHeading">
          <span>Shipping Address</span>
          <img src={checked} className="img-fluid checkImg" />
        </div>
        <div className="address_details">
          <Button
            className="btn-link addAddress_Btn"
            onClick={handleAddressShow}
          >
            + Add new address
          </Button>

          <div className="address_lists mt-3">
            {address.map((item, index) => {
              return (
                <div className="item commonRadio_btn">
                  <Form.Check
                    type="radio"
                    onChange={() => handleAddress(item)}
                    checked={item.id == primaryAddress ? true : false}
                  />
                  <div>
                    <p className="add-headtxt mb-0">Demo Account</p>
                    <div className="addDetails">
                      <p className="mb-0">
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                        sed diam nonumy eirmod tempor invidunt ut labore et
                        dolore magna aliquyam erat, sed diam voluptua. At vero
                        eos et accusam et justo duo dolores et ea rebum.
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export default ShippingAddressList;
