import React from "react";
import { Link } from "react-router-dom";

import oneplus from "../Assets/Images/shop_categories/oneplus.png";
import samsung from "../Assets/Images/shop_categories/samsung.png";
import nokia from "../Assets/Images/shop_categories/nokia.png";
import mi from "../Assets/Images/shop_categories/mi.png";
import moto from "../Assets/Images/shop_categories/moto.png";

function ShopByCategories() {
  const Brands = [
    {
      id: 1,
      brandLogo: oneplus,
    },
    {
      id: 2,
      brandLogo: samsung,
    },
    {
      id: 3,
      brandLogo: nokia,
    },
    {
      id: 4,
      brandLogo: mi,
    },
    {
      id: 5,
      brandLogo: oneplus,
    },
    {
      id: 6,
      brandLogo: samsung,
    },
    {
      id: 7,
      brandLogo: nokia,
    },
    {
      id: 8,
      brandLogo: mi,
    },
    {
      id: 9,
      brandLogo: oneplus,
    },
    {
      id: 10,
      brandLogo: samsung,
    },
    {
      id: 11,
      brandLogo: nokia,
    },
    {
      id: 12,
      brandLogo: mi,
    },
  ];

  return (
    <div className="shop_categories">
      <div className="shopCategories_container container">
        <div className="cmnHeading_sec">
          <p className="mb-0 heading_txt">Shop by category</p>
          <Link to="/" className="text-decoration-none">
            <p className="mb-0 see-all">See all</p>
          </Link>
        </div>
        <div className="brand_details">
          <div className="row">
            {Brands.map((item, index) => {
              return (
                <div className="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-2 col">
                  <Link to="/" className="text-decotaion-none">
                    <div className="brand_logo">
                      <img src={item.brandLogo} className="img-fluid" />
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShopByCategories;
