import React from "react";
import product1 from "../Assets/Images/Joystick.png";
import product2 from "../Assets/Images/mobile.png";
import product3 from "../Assets/Images/airbots.png";
import product4 from "../Assets/Images/headphone.png";
import ratings from "../Assets/Images/ratings.svg";
import coin2 from "../Assets/Images/coin2.png";
import sign1 from "../Assets/Images/sign1.svg";
import shop1 from "../Assets/Images/shop1.png";
import eye1 from "../Assets/Images/eye1.png";
import wishlist1 from "../Assets/Images/wishlist1.png";
import { Button, Image } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Link } from "react-router-dom";

function TodayDealzCard({ items }) {
  const renderTooltipShop = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Add to cart
    </Tooltip>
  );
  const renderTooltipEye = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Quick view
    </Tooltip>
  );
  const renderTooltipWishlist = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Add to wishlist
    </Tooltip>
  );

  return (
    <Link to="/product-details" className="text-decoration-none">
      <div className="product_card1">
        <div>
          <div className="img_sec">
            <div
              className={
                items?.ProdStatus == "minus"
                  ? "prod_status minus"
                  : items?.ProdStatus == "new"
                  ? "prod_status new"
                  : "prod_status trending"
              }
            >
              {items?.ProdStatusVal}
            </div>
            <div className="productImg">
              <img src={items?.prodImg} alt="product" className="img-fluid" />
            </div>
          </div>
          <div className="product_details">
            <p className="sml_title mb-0">Gaming Appliance</p>
            <p className="mb-0 prod_name mt-1">{items?.ProdName}</p>
            <div className="prod_ratings mt-1">
              <img
                src={items?.ratings}
                alt="product"
                className="img-fluid rating_img"
              />
              <img
                src={items?.ratings}
                alt="product"
                className="img-fluid rating_img"
              />
              <img
                src={items?.ratings}
                alt="product"
                className="img-fluid rating_img"
              />
              <img
                src={items?.ratings}
                alt="product"
                className="img-fluid rating_img"
              />
              <img
                src={items?.ratings}
                alt="product"
                className="img-fluid rating_img"
              />
            </div>
            <p className="prod_price mb-0 mt-2">
              0.15
              <img src={coin2} className="img-fluid prod_img" />
              BNB
              <img src={sign1} className="img-fluid sign_img" />
              $108.42
            </p>
          </div>
        </div>
        <div className="hidden-details">
          <OverlayTrigger
            placement="left"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltipShop}
          >
            <div className="item">
              <Image src={shop1} className="img-fluid icon" />
            </div>
          </OverlayTrigger>

          <OverlayTrigger
            placement="left"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltipEye}
          >
            <div className="item">
              <Image src={eye1} className="img-fluid icon" />
            </div>
          </OverlayTrigger>
          <OverlayTrigger
            placement="left"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltipWishlist}
          >
            <div className="item">
              <Image src={wishlist1} className="img-fluid icon" />
            </div>
          </OverlayTrigger>
        </div>
      </div>
    </Link>
  );
}

export default TodayDealzCard;
