import React from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import closeBtn from "../../Assets/Images/modal_close.svg";

import signUpWallet from "../../Assets/Images/passkey.png";

import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import PinInput from "react-pin-input";
function CreateNewPasskeyModal({
  show,
  handleClose,
  CnfmNewPasskeyShow,
  handleReset,
}) {
  const [pin, setPin] = useState("");

  const handleComplete = (value) => {
    setPin(value);
  };
  return (
    <div>
      <Modal
        show={show}
        // onHide={handleClose}
        centered
        size="md"
        className="common_Modal_Popup signUp_modal"
      >
        <Modal.Body>
          <Button className="close_btn" onClick={handleClose}>
            <img src={closeBtn} className="img-fluid" />
          </Button>

          <div className="content">
            <div className="head_sec text-center">
              <p className="name mb-0">Create a new Passkey</p>
              <img src={signUpWallet} className="signUpWallet img-fluid my-3" />
              <p className="passkey_protect mb-0">
                Create a new passcode for your account
              </p>
              <p className="create-passkey mb-0 mt-1">
                Enter new 6 Digit Passkey code
              </p>
            </div>
            <div className="mt-4 passkey_inp">
              <PinInput
                length={6}
                initialValue=""
                secret
                secretDelay={100}
                onChange={(value, index) => {}}
                type="numeric"
                inputMode="number"
                // style={{ padding: "10px" }}
                // inputStyle={{ borderColor: "red" }}
                // inputFocusStyle={{ borderColor: "blue" }}
                onComplete={handleComplete}
                // autoSelect={true}
                // regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
              />
            </div>

            <div className="mt-4 mt-md-5">
              <p className="mb-0 reset_link text-center">
                <Button className="btn btn-link" onClick={handleReset}>
                  Reset
                </Button>
              </p>
              <p className="my-3 text-center">
                <Button
                  className="confirmBtn cmn_linearBtn"
                  onClick={CnfmNewPasskeyShow}
                >
                  Confirm
                </Button>
              </p>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default CreateNewPasskeyModal;
