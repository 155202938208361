import React from "react";
import discover1 from "../Assets/Images/discover1.png";
import discover2 from "../Assets/Images/discover2.png";
import { Link } from "react-router-dom";
function DiscoverNow() {
  return (
    <div className="discover_now">
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 fst-col">
            <Link to="" className="text-decoration-none">
              <div className="img-sec">
                <img src={discover1} alt="discover" className="img-fluid" />
              </div>
            </Link>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 snd-col">
            <Link to="" className="text-decoration-none">
              <div className="img-sec">
                <img src={discover2} alt="discover" className="img-fluid" />
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DiscoverNow;
