import React from "react";
import product1 from "../Assets/Images/Joystick.png";
import product2 from "../Assets/Images/mobile.png";
import product3 from "../Assets/Images/airbots.png";
import product4 from "../Assets/Images/headphone.png";
import ratings from "../Assets/Images/ratings.svg";
import coin2 from "../Assets/Images/coin2.png";
import sign1 from "../Assets/Images/sign1.svg";
import shop1 from "../Assets/Images/shop1.png";
import eye1 from "../Assets/Images/eye1.png";
import wishlist1 from "../Assets/Images/wishlist1.png";
import { Button, Image } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Link } from "react-router-dom";
import arbitrum from "../Assets/Images/arbitrum.png";
import polygon from "../Assets/Images/polygon.png";
import bnb from "../Assets/Images/bnb.png";
import shop from "../Assets/Images/shop2.png";
import ticket from "../Assets/Images/ticket.png";

function RecentlyViewedProduct({ items }) {
  return (
    <Link to="/product-details" className="text-decoration-none">
      <div className="recentViewed_productCard">
        <div>
          <div className="img_sec">
            <div className="prod_status minus">
              <img
                src={ticket}
                alt="ticket"
                className="img-fluid ticketIcon me-1"
              />
              Ship to india
            </div>
            <div className="productImg">
              <img src={items?.prodImg} alt="product" className="img-fluid" />
            </div>
          </div>
          <div className="product_details">
            <p className="mb-0 prod_name mt-1">{items?.ProdName}</p>
            <div className="prod_ratings mt-1">
              <img
                src={items?.ratings}
                alt="product"
                className="img-fluid rating_img"
              />
              <img
                src={items?.ratings}
                alt="product"
                className="img-fluid rating_img"
              />
              <img
                src={items?.ratings}
                alt="product"
                className="img-fluid rating_img"
              />
              <img
                src={items?.ratings}
                alt="product"
                className="img-fluid rating_img"
              />
              <img
                src={items?.ratings}
                alt="product"
                className="img-fluid rating_img"
              />
            </div>
            <p className="soldBy mb-0 mt-2">
              Sold by : <Button className="btn-link">Oppo store</Button>
            </p>
            <p className="prod_price mb-0 mt-1">
              0.15
              <img src={coin2} className="img-fluid prod_img" />
              BNB
              <img src={sign1} className="img-fluid sign_img" />
              $108.42
            </p>
            <p className="delivered mb-0 mt-1">
              <img src={shop} className="img-fluid shopImg me-1" />
              Delivered by Friday , 1 May
            </p>
            <div className="block-chains mt-2">
              <p className="mb-1">Block chains:</p>
              <div className="d-flex align-items-center gap-1">
                <img src={bnb} alt="product" className="img-fluid" />
                <img src={arbitrum} alt="product" className="img-fluid" />
                <img src={polygon} alt="product" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
        <div className="hvr-details">
          <div className="hide_btns">
            <Button className="">Buy now</Button>
            <Button className="">Add to cart</Button>
            <Button className="">Select Option</Button>
          </div>
        </div>
      </div>
    </Link>
  );
}

export default RecentlyViewedProduct;
