// import package

import React, { useRef, useState } from "react";
import { Button, Col, Dropdown, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
// import local
import { Image } from "../data/Images";
import DashLayout from "../Components/DashboardLayout/DashLayout";
import { Link } from "react-router-dom";
import Select from "react-select";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import Accordion from "react-bootstrap/Accordion";

const AddProduct = () => {
  const [logo, setLogo] = useState("");
  const [banner, setBanner] = useState("");
  const [selectTab, setSelectTab] = useState("productinfo");
  const [description1, setDescription1] = React.useState({
    bigImg: "",
    smallImg1: "",
    smallImg2: "",
  });
  const [description2, setDescription2] = React.useState({
    smallImg11: "",
    smallImg12: "",
    smallImg13: "",
    Desc1: "",
    Desc2: "",
    Desc3: "",
  });
  const [prodDes1, setProdDes1] = useState(false);
  const [prodDes2, setProdDes2] = useState(false);
  const [address, setAddress] = useState([
    {
      id: 1,
      addr: "  No 18, North Street, Thirunagar, Madurai, Tamilnadu.",
    },
    {
      id: 2,
      addr: "  No 18, North Street, Thirunagar, Madurai, Tamilnadu.",
    },
    {
      id: 3,
      addr: "  No 18, North Street, Thirunagar, Madurai, Tamilnadu.",
    },
  ]);
  const [additionlInfo, setAdditionlInfo] = useState([
    {
      id: 1,
      type: "Colour",
    },
    {
      id: 2,
      type: "Ram",
    },
    {
      id: 3,
      type: "Plug Type",
    },
    {
      id: 4,
      type: "Hard drive",
    },
    {
      id: 5,
      type: "Capacity",
    },
    {
      id: 6,
      type: "Size",
    },
  ]);

  const [additionlInfoSub, setIdditionlInfoSub] = useState([
    {
      id: 1,
      type: "Red",
    },
    {
      id: 2,
      type: "Blur",
    },
    {
      id: 3,
      type: "Green",
    },
  ]);

  const handleDesc1 = (e) => {
    const value = e.target.files[0];
    setDescription1({
      ...description1,
      [e.target.name]: value,
    });
  };

  const handleDesc2 = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      setDescription2({
        ...description2,
        [name]: e.target.files[0],
      });
    } else {
      setDescription2({
        ...description2,
        [name]: value,
      });
    }
  };

  const handleSelectTab = (val) => {
    setSelectTab(val);
  };

  return (
    <DashLayout>
      <div className="my-4 addProductPage">
        <div className="row mt-3">
          <div className="order-2 order-lg-1 col-lg-9 col-xl-10 mb-4">
            {selectTab == "productinfo" ? (
              <div className="stores_details">
                <h5 className="overview__title">Add Product</h5>
                <div className="addProduct_inp mt-3">
                  <div className="header_sec">
                    <img
                      src={Image.userBlackLogo}
                      className="img-fluid userLogo"
                    />
                    <p className="title_txt mb-0">Profile Information</p>
                  </div>
                  <div className="inpContent">
                    <div className="row">
                      <div className="col-12 col-md-6 col-lg-6 mb-3">
                        <div className="row">
                          <div className="col-lg-12 mb-3">
                            <Form.Group
                              className=""
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Product Name</Form.Label>
                              <Form.Control type="text" placeholder="" />
                            </Form.Group>
                          </div>
                          <div className="col-lg-12">
                            <p className="mb-0 titleLabel mb-1">Category</p>
                            <div className="category_inpGrp">
                              <Form.Group
                                className="mb-2"
                                controlId="exampleForm.ControlInput2"
                              >
                                <Form.Label className="label_sm">
                                  Product Category
                                </Form.Label>

                                <Form.Control type="text" placeholder="" />
                              </Form.Group>
                              <Form.Group
                                className="mb-2"
                                controlId="exampleForm.ControlInput3"
                              >
                                <Form.Label className="label_sm">
                                  Product Sub category
                                </Form.Label>

                                <Form.Control type="text" placeholder="" />
                              </Form.Group>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-lg-6 mb-3">
                        <div className="row">
                          <div className="col-lg-12">
                            <p className="mb-0 titleLabel mb-1">Pricing</p>
                            <div className="pricing_inpGrp">
                              <Form.Group
                                className="mb-2"
                                controlId="exampleForm.ControlInput5"
                              >
                                <Form.Label className="label_sm">
                                  Base Price
                                </Form.Label>

                                <Form.Control type="text" placeholder="" />
                              </Form.Group>
                              <Form.Group
                                className=""
                                controlId="exampleForm.ControlInput6"
                              >
                                <Form.Label className="label_sm">
                                  Tax Type
                                </Form.Label>

                                <Form.Control type="text" placeholder="" />
                              </Form.Group>
                            </div>
                          </div>
                          <div className="col-lg-12 mt-3">
                            <Form.Group
                              className=""
                              controlId="exampleForm.ControlInput22"
                            >
                              <Form.Label className="label_sm">
                                Product Weight
                              </Form.Label>

                              <Form.Control type="text" placeholder="" />
                            </Form.Group>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-lg-6">
                        <div className="row">
                          <div className="col-lg-12 mb-3">
                            <Form.Group
                              className=""
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Stock (Qty)</Form.Label>
                              <Form.Control type="text" placeholder="" />
                            </Form.Group>
                          </div>
                          <div className="col-lg-12 mb-3">
                            <Form.Group
                              className=""
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Choose Networks</Form.Label>
                              <Form.Control type="text" placeholder="" />
                            </Form.Group>
                          </div>
                          <div className="col-lg-12 mb-3">
                            <Form.Group
                              className=""
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Description</Form.Label>
                              <Form.Control
                                as="textarea"
                                rows={4}
                                placeholder=""
                              />
                            </Form.Group>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-lg-6">
                        <p className="mb-0 titleLabel mb-1">Choose Address</p>
                        <div className="choose_address">
                          {address.map((item, index) => {
                            return (
                              <>
                                <div className="address_list">
                                  <div className="d-flex align-items-start gap-3">
                                    <Form.Check // prettier-ignore
                                      type="checkbox"
                                      id="checkbox"
                                      className="cmn_checkBox"
                                    />
                                    <div className="addr">{item.addr}</div>
                                  </div>
                                  <div className="addressView">
                                    <Button className="cmn_liteBlueBtn">
                                      Edit
                                    </Button>
                                    <Button className="cmn_Green_btn">
                                      View
                                    </Button>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-lg-6 mb-3">
                        <Accordion
                          // defaultActiveKey="0"
                          className="cmn_accordion"
                        >
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>
                              Additional Information
                            </Accordion.Header>
                            <Accordion.Body>
                              <div className="check_list">
                                {additionlInfo.map((item, index) => {
                                  return (
                                    <Form.Check
                                      type="checkbox"
                                      id={item.id}
                                      className="cmn_checkBox"
                                      label={item.type}
                                    />
                                  );
                                })}
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                      <div className="col-lg-6 mb-3">
                        <Accordion
                          // defaultActiveKey="0"
                          className="cmn_accordion"
                        >
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>Colour</Accordion.Header>
                            <Accordion.Body>
                              <div className="sub_types">
                                <div className="check_list">
                                  {additionlInfoSub.map((item, index) => {
                                    return (
                                      <Form.Check
                                        type="checkbox"
                                        id={item.id}
                                        className="cmn_checkBox"
                                        label={item.type}
                                      />
                                    );
                                  })}
                                </div>

                                <Button className="add_moreBtn mt-3">
                                  Add more
                                </Button>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <Accordion
                          // defaultActiveKey="0"
                          className="cmn_accordion"
                        >
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>
                              Add More Information
                            </Accordion.Header>
                            <Accordion.Body>
                              <Form.Group
                                className=""
                                controlId="exampleForm.ControlInput1"
                              >
                                <Form.Label>Enter Name</Form.Label>
                                <Form.Control type="text" placeholder="" />
                              </Form.Group>
                              <div className="select_types mt-3">
                                <Form.Label>Select Type</Form.Label>
                                <div className="check_list">
                                  <Form.Check
                                    type="checkbox"
                                    id="active"
                                    className="cmn_checkBox"
                                    label="Active"
                                  />
                                  <Form.Check
                                    type="checkbox"
                                    id="visible"
                                    className="cmn_checkBox"
                                    label="Visible On Product Page"
                                  />
                                  <Form.Check
                                    type="checkbox"
                                    id="others"
                                    className="cmn_checkBox"
                                    label="Others"
                                  />
                                </div>
                                <Button className="mt-3 saveBtn cmn-linearBtn">
                                  Save
                                </Button>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-4">
                  <div className="d-flex align-items-center gap-3 cancel_nxt_Btn">
                    <Button className="btn cmn_grayBtn">Cancel</Button>
                    <Button
                      className="btn cmn-linearBtn"
                      onClick={() => handleSelectTab("description")}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              </div>
            ) : selectTab == "description" ? (
              <div>
                <h5 className="overview__title">Description</h5>
                <div className="description_details mt-3">
                  <div
                    className={
                      !prodDes1
                        ? "header_sec bdr_none cursor-pointer"
                        : "header_sec cursor-pointer"
                    }
                    onClick={() => setProdDes1(!prodDes1)}
                  >
                    <div className="head">
                      <img
                        src={Image.userBlackLogo}
                        className="img-fluid userLogo"
                      />
                      <p className="title_txt mb-0">Product Description - 1</p>
                    </div>
                    {!prodDes1 ? (
                      <IoIosArrowDown className="arrow" />
                    ) : (
                      <IoIosArrowUp className="arrow" />
                    )}
                  </div>
                  {prodDes1 && (
                    <div className="content_sec">
                      <div className="row">
                        <div className="col-12 col-lg-12">
                          <div className="desc_uploadBigImg">
                            <button class="btn">
                              {!description1?.bigImg ? (
                                <div className="">
                                  <img
                                    src={Image.emptyImg1}
                                    className="emptyImg img-fluid"
                                  />
                                  <p className="mt-2 pt-1 Imgupload_txt mb-0">
                                    Upload Size - 1354 * 413
                                  </p>
                                </div>
                              ) : (
                                <img
                                  src={URL.createObjectURL(
                                    description1?.bigImg
                                  )}
                                  className="pickedImg img-fluid"
                                />
                              )}
                            </button>
                            <input
                              type="file"
                              name="bigImg"
                              onChange={handleDesc1}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-6 mt-3">
                          <div className="desc_uploadBigImg">
                            <button class="btn">
                              {!description1?.smallImg1 ? (
                                <div className="">
                                  <img
                                    src={Image.emptyImg1}
                                    className="emptyImg img-fluid"
                                  />
                                  <p className="mt-2 pt-1 Imgupload_txt mb-0">
                                    Upload Size - 650 * 450
                                  </p>
                                </div>
                              ) : (
                                <img
                                  src={URL.createObjectURL(
                                    description1?.smallImg1
                                  )}
                                  className="pickedImg img-fluid"
                                />
                              )}
                            </button>
                            <input
                              type="file"
                              name="smallImg1"
                              onChange={handleDesc1}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-6 mt-3">
                          <div className="desc_uploadBigImg">
                            <button class="btn">
                              {!description1?.smallImg2 ? (
                                <div className="">
                                  <img
                                    src={Image.emptyImg1}
                                    className="emptyImg img-fluid"
                                  />
                                  <p className="mt-2 pt-1 Imgupload_txt mb-0">
                                    Upload Size - 650 * 450
                                  </p>
                                </div>
                              ) : (
                                <img
                                  src={URL.createObjectURL(
                                    description1?.smallImg2
                                  )}
                                  className="pickedImg img-fluid"
                                />
                              )}
                            </button>
                            <input
                              type="file"
                              name="smallImg2"
                              onChange={handleDesc1}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="description_details mt-3">
                  <div
                    className={
                      !prodDes2
                        ? "header_sec bdr_none cursor-pointer"
                        : "header_sec cursor-pointer"
                    }
                    onClick={() => setProdDes2(!prodDes2)}
                  >
                    <div className="head">
                      <img
                        src={Image.userBlackLogo}
                        className="img-fluid userLogo"
                      />
                      <p className="title_txt mb-0">Product Description - 2</p>
                    </div>
                    {!prodDes2 ? (
                      <IoIosArrowDown className="arrow" />
                    ) : (
                      <IoIosArrowUp className="arrow" />
                    )}
                  </div>
                  {prodDes2 && (
                    <div className="content_sec">
                      <div className="row">
                        <div className="col-12 col-sm-6 col-lg-6 mb-3">
                          <div className="desc_uploadBigImg">
                            <button class="btn">
                              {!description2?.smallImg11 ? (
                                <div className="">
                                  <img
                                    src={Image.emptyImg1}
                                    className="emptyImg img-fluid"
                                  />
                                  <p className="mt-2 pt-1 Imgupload_txt mb-0">
                                    Upload Size - 650 * 450
                                  </p>
                                </div>
                              ) : (
                                <img
                                  src={URL.createObjectURL(
                                    description2?.smallImg11
                                  )}
                                  className="pickedImg img-fluid"
                                />
                              )}
                            </button>
                            <input
                              type="file"
                              name="smallImg11"
                              onChange={handleDesc2}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-6 mb-3">
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                          >
                            <Form.Control
                              as="textarea"
                              rows={7}
                              onChange={handleDesc2}
                              value={description2?.Desc1}
                              className="cmn_desc_height"
                              name="Desc1"
                              placeholder="Description"
                            />
                          </Form.Group>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-6 mb-3">
                          <div className="desc_uploadBigImg">
                            <button class="btn">
                              {!description2?.smallImg12 ? (
                                <div className="">
                                  <img
                                    src={Image.emptyImg1}
                                    className="emptyImg img-fluid"
                                  />
                                  <p className="mt-2 pt-1 Imgupload_txt mb-0">
                                    Upload Size - 650 * 450
                                  </p>
                                </div>
                              ) : (
                                <img
                                  src={URL.createObjectURL(
                                    description2?.smallImg12
                                  )}
                                  className="pickedImg img-fluid"
                                />
                              )}
                            </button>
                            <input
                              type="file"
                              name="smallImg12"
                              onChange={handleDesc2}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-6 mb-3">
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea2"
                          >
                            <Form.Control
                              as="textarea"
                              rows={3}
                              onChange={handleDesc2}
                              name="Desc2"
                              className="cmn_desc_height"
                              placeholder="Description"
                            />
                          </Form.Group>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-6">
                          <div className="desc_uploadBigImg">
                            <button class="btn">
                              {!description2?.smallImg13 ? (
                                <div className="">
                                  <img
                                    src={Image.emptyImg1}
                                    className="emptyImg img-fluid"
                                  />
                                  <p className="mt-2 pt-1 Imgupload_txt mb-0">
                                    Upload Size - 650 * 450
                                  </p>
                                </div>
                              ) : (
                                <img
                                  src={URL.createObjectURL(
                                    description2?.smallImg13
                                  )}
                                  className="pickedImg img-fluid"
                                />
                              )}
                            </button>
                            <input
                              type="file"
                              name="smallImg13"
                              onChange={handleDesc2}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-6">
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea3"
                          >
                            <Form.Control
                              as="textarea"
                              rows={3}
                              onChange={handleDesc2}
                              name="Desc3"
                              className="cmn_desc_height"
                              placeholder="Description"
                            />
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="mt-4">
                  <div className="d-flex align-items-center gap-3 cancel_nxt_Btn">
                    <Button className="btn cmn_grayBtn">Cancel</Button>
                    <Button className="btn cmn-linearBtn">Apply</Button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="">
                <h5 className="overview__title">Shipping Type</h5>
                <div className="prod_shippingType mt-4">
                  <div className="check_list mb-3 mb-lg-4">
                    <Form.Check
                      type="checkbox"
                      id="category"
                      className="cmn_checkBox"
                      label="Shipping by category"
                      checked
                    />
                    <Form.Check
                      type="checkbox"
                      id="distance"
                      className="cmn_checkBox"
                      label="Shipping by distance"
                      checked
                    />
                    <Form.Check
                      type="checkbox"
                      id="deliveryman"
                      className="cmn_checkBox"
                      label="Delivery man"
                    />
                  </div>
                  <div className="ShippingByCat mt-3">
                    <p className="mb-2 f-15">Shipping by categories</p>
                    <div className="cmn_shipList">
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-4 col-lg-4 mb-2">
                          <Form.Group
                            className=""
                            controlId="exampleForm.ControlInput21"
                          >
                            <Form.Label>Choosed Country</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Country"
                              readOnly
                            />
                          </Form.Group>
                        </div>
                        <div className="col-12 col-sm-12 col-md-8 col-lg-8">
                          <div className="row">
                            <div className="col-6 col-sm-4 col-lg-4 mb-2">
                              <Form.Group
                                className=""
                                controlId="exampleForm.ControlInput21"
                              >
                                <Form.Label>BNB</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="BNB"
                                  readOnly
                                />
                              </Form.Group>
                            </div>
                            <div className="col-6 col-sm-4 col-lg-4 mb-2">
                              <Form.Group
                                className=""
                                controlId="exampleForm.ControlInput21"
                              >
                                <Form.Label>ETH</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="ETH"
                                  readOnly
                                />
                              </Form.Group>
                            </div>
                            <div className="col-6 col-sm-4 col-lg-4">
                              <Form.Group
                                className=""
                                controlId="exampleForm.ControlInput21"
                              >
                                <Form.Label>MAT</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="MAT"
                                  readOnly
                                />
                              </Form.Group>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ShippingByCat mt-3">
                    <p className="mb-2 f-15">Shipping by distance</p>
                    <div className="cmn_shipList">
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-4 col-lg-4 mb-2">
                          <div className="row">
                            <div className="col-6 col-lg-6 mb-2">
                              <Form.Group
                                className=""
                                controlId="exampleForm.ControlInput21"
                              >
                                <Form.Label>From</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Km"
                                  readOnly
                                />
                              </Form.Group>
                            </div>
                            <div className="col-6 col-lg-6">
                              <Form.Group
                                className=""
                                controlId="exampleForm.ControlInput21"
                              >
                                <Form.Label>To</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="km"
                                  readOnly
                                />
                              </Form.Group>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-8 col-lg-8">
                          <div className="row">
                            <div className="col-6 col-sm-4 col-lg-4 mb-2">
                              <Form.Group
                                className=""
                                controlId="exampleForm.ControlInput21"
                              >
                                <Form.Label>BNB</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="BNB"
                                  readOnly
                                />
                              </Form.Group>
                            </div>
                            <div className="col-6 col-sm-4 col-lg-4 mb-2">
                              <Form.Group
                                className=""
                                controlId="exampleForm.ControlInput21"
                              >
                                <Form.Label>ETH</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="ETH"
                                  readOnly
                                />
                              </Form.Group>
                            </div>
                            <div className="col-6 col-sm-4 col-lg-4">
                              <Form.Group
                                className=""
                                controlId="exampleForm.ControlInput21"
                              >
                                <Form.Label>MAT</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="MAT"
                                  readOnly
                                />
                              </Form.Group>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="order-1 order-lg-2 mb-3 mb-lg-0 mt-0 mt-lg-4 col-lg-3 col-xl-2">
            <div className="product_ryt_tab mt-lg-2">
              <div
                className={
                  selectTab == "productinfo"
                    ? "heading_txt active mb-2"
                    : "heading_txt mb-2"
                }
                onClick={() => handleSelectTab("productinfo")}
              >
                <span className="activeLine"></span>
                Product information
              </div>
              <div
                className={
                  selectTab == "description"
                    ? "heading_txt active mb-2"
                    : "heading_txt mb-2"
                }
                onClick={() => handleSelectTab("description")}
              >
                <span className="activeLine"></span>
                Description
              </div>
              <div
                className={
                  selectTab == "shippingType"
                    ? "heading_txt active mb-2"
                    : "heading_txt mb-2"
                }
                onClick={() => handleSelectTab("shippingType")}
              >
                <span className="activeLine"></span>
                Shipping Type
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashLayout>
  );
};

export default AddProduct;
