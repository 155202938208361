import React, { useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import closeIcon from "../Assets/Images/modal_close.svg";
function SearchFilter({ handleCloseOffcanvas }) {
  const [deliverOption, setDeliveryOption] = useState(true);
  const [category, setCategory] = useState(true);
  const [price, setPrice] = useState(true);
  const [brand, setBrand] = useState(true);
  return (
    <div>
      <div className="searchProduct_filter">
        <div className="heading d-flex align-items-center justify-content-between">
          Filters
          {/* offcanvas button */}
          <Button className="close_offCanvas" onClick={handleCloseOffcanvas}>
            <img src={closeIcon} className="img-fluid" />
          </Button>
        </div>
        <div className="filter_list">
          <div className="sec">
            <p
              className="drop-heading mb-0"
              onClick={() => setDeliveryOption(!deliverOption)}
            >
              Delivery options & Services
              {deliverOption ? (
                <IoIosArrowDown size={17} />
              ) : (
                <IoIosArrowUp size={17} />
              )}
            </p>
            {deliverOption && (
              <div className="mt-2 pt-1">
                <div className="drop_items commonRadio_btn">
                  <Form.Check // prettier-ignore
                    type="radio"
                    id="Free shipping"
                    label="Free shipping"
                  />
                </div>
              </div>
            )}
          </div>

          <div className="sec">
            <p
              className="drop-heading mb-0"
              onClick={() => setCategory(!category)}
            >
              Categories
              {category ? (
                <IoIosArrowDown size={17} />
              ) : (
                <IoIosArrowUp size={17} />
              )}
            </p>
            {category && (
              <div className="mt-2 pt-1">
                {" "}
                <div className="drop_items commonRadio_btn">
                  <Form.Check type="radio" id="All" label="All" />
                </div>
                <div className="drop_items commonRadio_btn">
                  <Form.Check type="radio" id="Cellphones" label="Cellphones" />
                </div>
                <div className="drop_items commonRadio_btn">
                  <Form.Check
                    type="radio"
                    id="Mobile Phone Cables"
                    label="Mobile Phone Cables"
                  />
                </div>
                <div className="drop_items commonRadio_btn">
                  <Form.Check
                    type="radio"
                    id="Mobile Phone Chargers"
                    label="Mobile Phone Chargers"
                  />
                </div>
                <div className="drop_items commonRadio_btn">
                  <Form.Check
                    type="radio"
                    id="Microscopes"
                    label="Microscopes"
                  />
                </div>
                <div className="drop_items commonRadio_btn">
                  <Form.Check
                    type="radio"
                    id="Baby Rattles & Mobiles"
                    label="Baby Rattles & Mobiles"
                  />
                </div>
              </div>
            )}
          </div>

          <div className="sec">
            <p className="drop-heading mb-0" onClick={() => setPrice(!price)}>
              Price
              {price ? (
                <IoIosArrowDown size={17} />
              ) : (
                <IoIosArrowUp size={17} />
              )}
            </p>
            {price && (
              <div className="mt-2 pt-1">
                {" "}
                <div className="price-range">
                  <div className="d-flex gap-4">
                    <input
                      type="number"
                      className="valInp"
                      placeholder="₹ Min"
                    />
                    <input
                      type="number"
                      className="valInp"
                      placeholder="₹ Max"
                    />
                  </div>
                  <Button className="goBtn">Go</Button>
                </div>
                <div className="drop_items commonRadio_btn">
                  <Form.Check
                    type="radio"
                    id="Under - 1837.40"
                    label="Under - 1837.40"
                  />
                </div>
                <div className="drop_items commonRadio_btn">
                  <Form.Check
                    type="radio"
                    id="Under - 1837.40 - 7683.97"
                    label="Under - 1837.40 - 7683.97"
                  />
                </div>
                <div className="drop_items commonRadio_btn">
                  <Form.Check
                    type="radio"
                    id="7683.97 - 17149.59"
                    label="7683.97 - 17149.59"
                  />
                </div>
                <div className="drop_items commonRadio_btn">
                  <Form.Check
                    type="radio"
                    id="17149.59 - 58948.41"
                    label="17149.59 - 58948.41"
                  />
                </div>
                <div className="drop_items commonRadio_btn">
                  <Form.Check
                    type="radio"
                    id="58948.41 - Over"
                    label="58948.41 - Over"
                  />
                </div>
              </div>
            )}
          </div>

          <div className="sec brand">
            <p className="drop-heading mb-0" onClick={() => setBrand(!brand)}>
              Brand
              {brand ? (
                <IoIosArrowDown size={17} />
              ) : (
                <IoIosArrowUp size={17} />
              )}
            </p>
            {brand && (
              <div className="mt-2 pt-1">
                {" "}
                <div className="drop_items commonRadio_btn">
                  <Form.Check type="radio" id="HP" label="HP" />
                </div>
                <div className="drop_items commonRadio_btn">
                  <Form.Check type="radio" id="DELL" label="DELL" />
                </div>
                <div className="drop_items commonRadio_btn">
                  <Form.Check type="radio" id="Lenovo" label="Lenovo" />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SearchFilter;
