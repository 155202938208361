// import package

import React, { useContext, useState } from "react";
import { RxHamburgerMenu } from "react-icons/rx";
import { IoClose } from "react-icons/io5";
import Offcanvas from "react-bootstrap/Offcanvas";

// import local

import { GlobalContext } from "../../context/Context";
import { Image } from "../../data/Images";

const DashHeader = () => {
  const { isSidebarOpen } = useContext(GlobalContext);

  const [offCanvaOpen, setOffCanvaOpen] = useState(false);

  const handleOffCanvaOpen = () => {
    setOffCanvaOpen(true);
  };
  const handleOffCanvaClose = () => {
    setOffCanvaOpen(false);
  };
  return (
    <>
      <div
        className={`${isSidebarOpen ? "width_less" : "width_full"} dash_header`}
      >
        <div className="container container_95 h-100">
          <div className="d-flex align-items-center justify-content-between h-100">
            <div className="d-flex flex-column flex-sm-row align-items-center gap-2">
              <h6 className="mb-0  dashHeader__desc">Welcome</h6>
              {/* <h6 className="mb-0 dashHeader__desc">Hariharan</h6> */}
            </div>
            <div className="d-flex align-items-center gap-3 ">
              <div className="dashHeader__searchHolder d-none d-lg-flex justify-content-between align-items-center gap-2  rounded-4 px-3 py-1">
                <input
                  type="text"
                  placeholder="Search"
                  className="p-1 flex-grow-1"
                />
                <img
                  src={Image.searchGlass}
                  className="img-fluid dashHeader__btnImages"
                />
              </div>
              <button className="gray_circle_btn d-none d-lg-flex">
                <img src={Image.notify} className="img-fluid " />
              </button>
              <button className="gray_circle_btn d-none d-lg-flex">
                <img src={Image.mail} className="img-fluid " />
              </button>
              <button className="gray_circle_btn ">
                <img src={Image.wallet} className="img-fluid " />
              </button>
              <button
                className="gray_circle_btn d-lg-none"
                onClick={handleOffCanvaOpen}
              >
                <RxHamburgerMenu color="#68647c" fontSize={20} />
              </button>
              <button className="gray_circle_btn d-none d-lg-flex">
                <img src={Image.settings} className="img-fluid" />
              </button>
            </div>
          </div>
        </div>
      </div>

      <Offcanvas
        show={offCanvaOpen}
        onHide={handleOffCanvaClose}
        backdrop="static"
        placement="top"
        className="custom_canva "
      >
        <Offcanvas.Header closeButton>
          <div className="d-flex align-items-center justify-content-between w-100">
            <img
              src={Image.logo}
              alt="logo"
              className="img-fluid sidebar_logo"
            />
            <button
              className="border-0 outline-0 bg-transparent"
              onClick={handleOffCanvaClose}
            >
              <IoClose color="#fff" fontSize={25} />
            </button>
          </div>{" "}
        </Offcanvas.Header>
        <Offcanvas.Body className="d-flex align-items-center">
          <div className="d-flex flex-wrap align-items-center gap-3">
            {" "}
            <div className="dashHeader__searchHolder d-flex justify-content-between align-items-center gap-2  rounded-4 px-3 py-1">
              <div className="row mx-auto">
                <div className="col-10 px-0">
                  <input
                    type="text"
                    placeholder="Search"
                    className=" flex-grow-1 "
                  />
                </div>
                <div className="col-2 pe-0 d-flex justify-content-end align-items-center">
                  <img
                    src={Image.searchGlass}
                    className="img-fluid dashHeader__btnImages"
                  />
                </div>
              </div>
            </div>
            <button className="gray_circle_btn ">
              <img src={Image.notify} className="img-fluid " />
            </button>
            <button className="gray_circle_btn ">
              <img src={Image.mail} className="img-fluid " />
            </button>
            <button className="gray_circle_btn">
              <img src={Image.settings} className="img-fluid" />
            </button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default DashHeader;
