// import package

import React, { useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Select from "react-select";
import ReactDatatable from "@ashvin27/react-datatable";
import { Link } from "react-router-dom";

// import local
import { Image } from "../data/Images";
import DashLayout from "../Components/DashboardLayout/DashLayout";
import OrderStatus from "../table/OrderStatus";
import FeatureProductCard from "../Components/FeatureProductCard";
import BottomTopCards from "../Components/BottomTopCards";
import BottomTopCard from "../Components/BottomTopCards";
import EthTransferModal from "../Components/Modals/EthTransferModal";
import WalletAndNetwork from "../Components/WalletAndNetwork";
import EscrowLock from "../Components/EscrowLock";

const Escrow = () => {
  const [buySellRecords, setBuySellRecords] = useState([
    {
      id: 1,
      img: Image.btc,
      wallet: "ETH",
      walletBalance: "0.02154 ETH",
      spotBalance: "0.021 ETH",
      derivativeBalance: "0.021 ETH",
      unrealized: "0.021 ETH",
    },
    {
      id: 2,
      img: Image.tron,
      wallet: "BNB",
      walletBalance: "0.02154 ETH",
      spotBalance: "0.021 ETH",
      derivativeBalance: "0.021 ETH",
      unrealized: "0.021 ETH",
    },
    {
      id: 3,
      img: Image.eth,
      wallet: "TRON",
      walletBalance: "0.02154 ETH",
      spotBalance: "0.021 ETH",
      derivativeBalance: "0.021 ETH",
      unrealized: "0.021 ETH",
    },
    {
      id: 4,
      img: Image.coin1,
      wallet: "BTC",
      walletBalance: "0.02154 ETH",
      spotBalance: "0.021 ETH",
      derivativeBalance: "0.021 ETH",
      unrealized: "0.021 ETH",
    },
    {
      id: 5,
      img: Image.btc,
      wallet: "ETH",
      walletBalance: "0.02154 ETH",
      spotBalance: "0.021 ETH",
      derivativeBalance: "0.021 ETH",
      unrealized: "0.021 ETH",
    },
    {
      id: 6,
      img: Image.tron,
      wallet: "TRON",
      walletBalance: "0.02154 ETH",
      spotBalance: "0.021 ETH",
      derivativeBalance: "0.021 ETH",
      unrealized: "0.021 ETH",
    },
  ]);
  const [depositHistory, setDepositHistory] = useState([
    {
      id: 1,
      contract: "BTCUSDT",
      qty: "20.0000",
      filledRemaining: "20.0000/0.000",
      execPrice: "168.20",
      triggerPrice: "168.20",
      orderPrice: "3364.000",
      orderValue: "0.65400",
      tradingFees: "0.65400",
      type: "Buy",
      status: "Completed",
      order: "64dgdg",
    },
    {
      id: 2,
      contract: "BTCUSDT",
      qty: "20.0000",
      filledRemaining: "20.0000/0.000",
      execPrice: "168.20",
      triggerPrice: "168.20",
      orderPrice: "3364.000",
      orderValue: "0.65400",
      tradingFees: "0.65400",
      type: "Buy",
      status: "Completed",
      order: "64dgdg",
    },
    {
      id: 3,
      contract: "BTCUSDT",
      qty: "20.0000",
      filledRemaining: "20.0000/0.000",
      execPrice: "168.20",
      triggerPrice: "168.20",
      orderPrice: "3364.000",
      orderValue: "0.65400",
      tradingFees: "0.65400",
      type: "Buy",
      status: "Completed",
      order: "64dgdg",
    },
    {
      id: 4,
      contract: "BTCUSDT",
      qty: "20.0000",
      filledRemaining: "20.0000/0.000",
      execPrice: "168.20",
      triggerPrice: "168.20",
      orderPrice: "3364.000",
      orderValue: "0.65400",
      tradingFees: "0.65400",
      type: "Buy",
      status: "Completed",
      order: "64dgdg",
    },
    {
      id: 5,
      contract: "BTCUSDT",
      qty: "20.0000",
      filledRemaining: "20.0000/0.000",
      execPrice: "168.20",
      triggerPrice: "168.20",
      orderPrice: "3364.000",
      orderValue: "0.65400",
      tradingFees: "0.65400",
      type: "Buy",
      status: "Completed",
      order: "64dgdg",
    },
    {
      id: 6,
      contract: "BTCUSDT",
      qty: "20.0000",
      filledRemaining: "20.0000/0.000",
      execPrice: "168.20",
      triggerPrice: "168.20",
      orderPrice: "3364.000",
      orderValue: "0.65400",
      tradingFees: "0.65400",
      type: "Buy",
      status: "Completed",
      order: "64dgdg",
    },
    {
      id: 7,
      contract: "BTCUSDT",
      qty: "20.0000",
      filledRemaining: "20.0000/0.000",
      execPrice: "168.20",
      triggerPrice: "168.20",
      orderPrice: "3364.000",
      orderValue: "0.65400",
      tradingFees: "0.65400",
      type: "Buy",
      status: "Completed",
      order: "64dgdg",
    },
  ]);
  const [withdrawHistory, setWithdrawHistory] = useState([
    {
      id: 1,
      coin: "ETH",
      amount: "12.00100",
      transactionFees: "0.0011",
      walletAddress: "0x9566b3io767gj34",
      status: "Completed",
      time: "06:03:24-19:43",
      withdrawRemark: "Lorem ipsum",
    },
    {
      id: 2,
      coin: "ETH",
      amount: "12.00100",
      transactionFees: "0.0011",
      walletAddress: "0x9566b3io767gj34",
      status: "Completed",
      time: "06:03:24-19:43",
      withdrawRemark: "Lorem ipsum",
    },
    {
      id: 3,
      coin: "ETH",
      amount: "12.00100",
      transactionFees: "0.0011",
      walletAddress: "0x9566b3io767gj34",
      status: "Completed",
      time: "06:03:24-19:43",
      withdrawRemark: "Lorem ipsum",
    },
    {
      id: 4,
      coin: "ETH",
      amount: "12.00100",
      transactionFees: "0.0011",
      walletAddress: "0x9566b3io767gj34",
      status: "Completed",
      time: "06:03:24-19:43",
      withdrawRemark: "Lorem ipsum",
    },
    {
      id: 5,
      coin: "ETH",
      amount: "12.00100",
      transactionFees: "0.0011",
      walletAddress: "0x9566b3io767gj34",
      status: "Completed",
      time: "06:03:24-19:43",
      withdrawRemark: "Lorem ipsum",
    },
    {
      id: 6,
      coin: "ETH",
      amount: "12.00100",
      transactionFees: "0.0011",
      walletAddress: "0x9566b3io767gj34",
      status: "Completed",
      time: "06:03:24-19:43",
      withdrawRemark: "Lorem ipsum",
    },
    {
      id: 7,
      coin: "ETH",
      amount: "12.00100",
      transactionFees: "0.0011",
      walletAddress: "0x9566b3io767gj34",
      status: "Completed",
      time: "06:03:24-19:43",
      withdrawRemark: "Lorem ipsum",
    },
  ]);
  const [tabActive, setTabActive] = useState(1);
  const [deposit, setDeposit] = useState(false);
  const handleDepositShow = () => {
    setDeposit(true);
  };
  const handleDepositClose = () => {
    setDeposit(false);
  };

  const buySellColumns = [
    {
      key: "wallet",
      text: "Wallet",
      className: "w_150",
      align: "start",
      sortable: true,
      cell: (record, index) => (
        <div className="d-flex align-items-center justify-content-start gap-3">
          <div className="table_image_wrapper_sm">
            <img src={record.img} alt="" className="img_container_fit" />
          </div>
          <p className="mb-0">{record.wallet}</p>
        </div>
      ),
    },
    {
      key: "walletbalance",
      text: "Wallet Balance",
      className: "w_180",
      align: "center",
      sortable: false,
      cell: (record, index) => {
        return <p className="mb-0 text-center">{record.walletBalance}</p>;
      },
    },
    {
      key: "spotbalance",
      text: "Spot Balance",
      className: "w_180",
      align: "center",
      sortable: false,
      cell: (record, index) => {
        return <p className="mb-0 text-center">{record.spotBalance}</p>;
      },
    },
    {
      key: "derivativebalance",
      text: "Derivative Balance",
      className: "w_180",
      align: "center",
      sortable: false,
      cell: (record) => {
        return <p className="mb-0 text-center">{record.derivativeBalance}</p>;
      },
    },
    {
      key: "unrealized",
      text: "Unrealized p & L",
      className: "w_250",
      align: "center",
      sortable: false,
      cell: (record) => {
        return <p className="mb-0 text-center">{record.unrealized}</p>;
      },
    },

    {
      key: "actions",
      text: "Actions",
      className: "w_300",
      align: "center",
      sortable: false,
      cell: (record) => {
        return (
          <div className="d-flex align-items-center justify-content-center gap-2">
            <button className="status_badge green3" onClick={handleDepositShow}>
              Deposit
            </button>
            <button className="status_badge greeninvert">Refund</button>
          </div>
        );
      },
    },
  ];
  const depositHistoryColumns = [
    {
      key: "contract",
      text: "Contract",
      className: "w_150",
      align: "center",
      sortable: true,
      cell: (record, index) => (
        <p className="mb-0 text-center">{record.contract}</p>
      ),
    },
    {
      key: "qty",
      text: "Qty",
      className: "w_150",
      align: "center",
      sortable: true,
      cell: (record, index) => <p className="mb-0 text-center">{record.qty}</p>,
    },
    {
      key: "contract",
      text: "Contract",
      className: "w_150",
      align: "center",
      sortable: true,
      cell: (record, index) => (
        <p className="mb-0 text-center">{record.contract}</p>
      ),
    },
    {
      key: "filledremaining",
      text: "Filled Remaining",
      className: "w_180",
      align: "center",
      sortable: true,
      cell: (record, index) => (
        <p className="mb-0 text-center">{record.filledRemaining}</p>
      ),
    },
    {
      key: "execPrice",
      text: "Exec Price",
      className: "w_150",
      align: "center",
      sortable: true,
      cell: (record, index) => (
        <p className="mb-0 text-center">{record.execPrice}</p>
      ),
    },
    {
      key: "triggerprice",
      text: "Trigger Price",
      className: "w_150",
      align: "center",
      sortable: true,
      cell: (record, index) => (
        <p className="mb-0 text-center">{record.triggerPrice}</p>
      ),
    },
    {
      key: "orderprice",
      text: "Order Price",
      className: "w_150",
      align: "center",
      sortable: true,
      cell: (record, index) => (
        <p className="mb-0 text-center">{record.orderPrice}</p>
      ),
    },
    {
      key: "ordervalue",
      text: "Order Value",
      className: "w_150",
      align: "center",
      sortable: true,
      cell: (record, index) => (
        <p className="mb-0 text-center">{record.orderValue}</p>
      ),
    },
    {
      key: "tradingfees",
      text: "Trading Fees",
      className: "w_150",
      align: "center",
      sortable: true,
      cell: (record, index) => (
        <p className="mb-0 text-center">{record.tradingFees}</p>
      ),
    },
    {
      key: "type",
      text: "Type",
      className: "w_150",
      align: "center",
      sortable: true,
      cell: (record, index) => (
        <p className="mb-0 text-center">{record.type}</p>
      ),
    },
    {
      key: "status",
      text: "Status",
      className: "w_150",
      align: "center",
      sortable: true,
      cell: (record, index) => (
        <p className="mb-0 text-center">{record.status}</p>
      ),
    },
    {
      key: "order",
      text: "Order",
      className: "w_150",
      align: "center",
      sortable: true,
      cell: (record, index) => (
        <p className="mb-0 text-center">{record.order}</p>
      ),
    },
  ];
  const WithdrawColumns = [
    {
      key: "coin",
      text: "Coin",
      className: "w_150",
      align: "center",
      sortable: true,
      cell: (record, index) => (
        <p className="mb-0 text-center">{record.coin}</p>
      ),
    },
    {
      key: "amount",
      text: "Amount",
      className: "w_150",
      align: "center",
      sortable: true,
      cell: (record, index) => (
        <p className="mb-0 text-center">{record.amount}</p>
      ),
    },
    {
      key: "transactionfees",
      text: "Transaction Fees",
      className: "w_180",
      align: "center",
      sortable: true,
      cell: (record, index) => (
        <p className="mb-0 text-center">{record.transactionFees}</p>
      ),
    },
    {
      key: "walletaddress",
      text: "Wallet Address",
      className: "w_200",
      align: "center",
      sortable: true,
      cell: (record, index) => (
        <p className="mb-0 text-center">{record.walletAddress}</p>
      ),
    },
    {
      key: "status",
      text: "Status",
      className: "w_150",
      align: "center",
      sortable: true,
      cell: (record, index) => (
        <p className="mb-0 text-center">{record.status}</p>
      ),
    },
    {
      key: "time",
      text: "Time",
      className: "w_150",
      align: "center",
      sortable: true,
      cell: (record, index) => (
        <p className="mb-0 text-center">{record.time}</p>
      ),
    },
    {
      key: "withdrawalremarks",
      text: "Withdrawal Remarks",
      className: "w_200",
      align: "center",
      sortable: true,
      cell: (record, index) => (
        <p className="mb-0 text-center">{record.withdrawRemark}</p>
      ),
    },
  ];
  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Emailtemplates",
    no_data_text: "No Email Templates found!",
    language: {
      length_menu: "Show MENU result per page",
      filter: "Filter in records...",
      info: "Showing START to END of TOTAL records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: false,
    show_filter: false,
    show_pagination: false,
    show_info: false,
  };
  const extraButtons = [
    {
      className: "btn btn-primary buttons-pdf",
      title: "Export TEst",
      children: [
        <span>
          <i
            className="glyphicon glyphicon-print fa fa-print"
            aria-hidden="true"
          ></i>
        </span>,
      ],
      onClick: (event) => {
        console.log(event);
      },
    },
    {
      className: "btn btn-primary buttons-pdf",
      title: "Export TEst",
      children: [
        <span>
          <i
            className="glyphicon glyphicon-print fa fa-print"
            aria-hidden="true"
          ></i>
        </span>,
      ],
      onClick: (event) => {
        console.log(event);
      },
      onDoubleClick: (event) => {
        console.log("doubleClick");
      },
    },
  ];

  const handleTabChange = (getId) => {
    setTabActive(getId);
  };
  return (
    <DashLayout>
      {deposit && (
        <EthTransferModal show={deposit} handleClose={handleDepositClose} />
      )}
      <div className="my-4">
        <h5 className="overview__title">Escrow Account</h5>
        <div className="table_card mt-4">
          <div className="table_card_header p-2 p-sm-3 p-md-4 d-flex flex-wrap gap-4 align-items-center justify-content-between">
            <div className="d-flex flex-wrap  align-items-center gap-4">
              <button
                className={`half_round_btn ${tabActive === 1 ? "active" : ""}`}
                onClick={() => handleTabChange(1)}
              >
                Buy & Sell
              </button>
              <button
                className={`half_round_btn ${tabActive === 2 ? "active" : ""}`}
                onClick={() => handleTabChange(2)}
              >
                Deposit History
              </button>
              <button
                className={`half_round_btn ${tabActive === 3 ? "active" : ""}`}
                onClick={() => handleTabChange(3)}
              >
                Withdrawal History
              </button>
            </div>
          </div>
          <div className="custom_table">
            {tabActive === 1 ? (
              <ReactDatatable
                config={config}
                records={buySellRecords}
                columns={buySellColumns}
                // extraButtons={extraButtons}
              />
            ) : tabActive === 2 ? (
              <ReactDatatable
                config={config}
                records={depositHistory}
                columns={depositHistoryColumns}
                // extraButtons={extraButtons}
              />
            ) : (
              <ReactDatatable
                config={config}
                records={withdrawHistory}
                columns={WithdrawColumns}
                // extraButtons={extraButtons}
              />
            )}
          </div>
        </div>

        <BottomTopCards />
      </div>
    </DashLayout>
  );
};

export default Escrow;
