import React from "react";
import mobile from "../Assets/Images/mobile.png";
import coin2 from "../Assets/Images/coin2.png";
import sign1 from "../Assets/Images/sign1.svg";
import shopping from "../Assets/Images/shopping.svg";
function SpecialProductCard({ items }) {
  return (
    <div className="specialProduct_card">
      <div>
        <p className="mb-0 type">{items?.type}</p>
        <p className="mb-0 name">{items?.name}</p>
      </div>
      <div className="img-sec">
        <img src={items.img} alt="img" className="img-fluid" />
      </div>
      <div className="d-flex align-items-center justify-content-between">
        <div className="price_details">
          <p className="original_amt mb-0">$210.00</p>
          <p className="discount_amt mb-0">
            0.15 BNB
            <img src={coin2} className="img-fluid prod_img" />
            $200.00
          </p>
        </div>
        <div className="shopping_img">
          <img src={shopping} className="img-fluid" />
        </div>
      </div>
    </div>
  );
}

export default SpecialProductCard;
