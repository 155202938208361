import React from "react";
import { Container } from "react-bootstrap";
import Banner from "./Banner";
import TodayDealz from "./TodayDealz";
import BestDealz from "./BestDealz";
import MostPopular from "./MostPopular";
import PremiumProduct from "./PremiumProduct";
import PopularSales from "./PopularSales";
import NewReleased from "./NewReleased";
import ShopByCategories from "./ShopByCategories";
import FeaturedProduct from "./FeaturedProduct";
import HomeAllProduct from "./HomeAllProduct";
import DiscoverNow from "./DiscoverNow";
import SpecialProducts from "./SpecialProducts";
import TrendingShoes from "./TrendingShoes";
import BookCollections from "./BookCollections";
import BitdealzMarketPlaceModal from "./Modals/BitdealzMarketPlaceModal";
import SignUpModal from "./Modals/SignUpModal";
import SignUpPasskeyModal from "./Modals/SignUpPasskeyModal";
import SignUpPasskeyCnfmModal from "./Modals/SignUpPasskeyCnfmModal";
import SignupSuccessModal from "./Modals/SignupSuccessModal";
import ChooseNetwork from "./Modals/ChooseNetwork";
import LoginPasskeyModal from "./Modals/LoginPasskeyModal";
import CreateNewPasskeyModal from "./Modals/ExistingPasskeyModal";
import CreateNewPasskeyCnfmModal from "./Modals/CreateNewPasskeyModal";
// import NewPasscodeCreateModal from "./Modals/CnfmNewPassKeyModal";
import PasscodeCreateSuccessModal from "./Modals/PasscodeCreateSuccessModal";
import ShiplocationModal from "./Modals/ShiplocationModal";
import ShipToModal from "./Modals/ShipToModal";

function HomePage() {
  return (
    <div className="homepage">
      <Banner />
      <TodayDealz />
      <BestDealz />
      <MostPopular />
      <BookCollections />
      <TrendingShoes />
      <SpecialProducts />
      <DiscoverNow />
      <HomeAllProduct />
      <FeaturedProduct />
      <ShopByCategories />
      <NewReleased />
      <PopularSales />
      <PremiumProduct />
      {/* <BitdealzMarketPlaceModal /> */}
      {/* <SignUpModal /> */}
      {/* <SignUpPasskeyModal /> */}
      {/* <SignUpPasskeyCnfmModal /> */}
      {/* <SignupSuccessModal /> */}
      {/* <ChooseNetwork /> */}
      {/* <LoginPasskeyModal /> */}
      {/* <CreateNewPasskeyModal /> */}
      {/* <CreateNewPasskeyCnfmModal /> */}
      {/* <NewPasscodeCreateModal /> */}
      {/* <PasscodeCreateSuccessModal /> */}
      {/* <ShiplocationModal /> */}
      {/* <ShipToModal /> */}
    </div>
  );
}

export default HomePage;
