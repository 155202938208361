import React from "react";
import rating from "../Assets/Images/ratings.svg";
import book1 from "../Assets/Images/books/book1_img.png";
import coin2 from "../Assets/Images/coin2.png";
import sign1 from "../Assets/Images/sign1.svg";
import { Link } from "react-router-dom";
function BookingCollectionCard({ items }) {
  return (
    <Link to="" className="text-decoration-none">
      <div className="bookCollection_card">
        <div
          className="book-details"
          style={{ backgroundImage: `url(${items.BgImg})` }}
        >
          <div className="d-flex flex-column align-items-center justify-content-center">
            <div>
              <p className="type mb-0 text-center">Fantacy Book</p>
              <p className="total-prod mb-1 text-center">3 Products</p>
              <div className="ratings">
                <img src={rating} className="img-fluid rating-img" />
                <img src={rating} className="img-fluid rating-img" />
                <img src={rating} className="img-fluid rating-img" />
                <img src={rating} className="img-fluid rating-img" />
                <img src={rating} className="img-fluid rating-img" />
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-center flex-column">
          <div className="book-img">
            <img src={items.Img} className="img-fluid" />
          </div>
          <div className="price_det mt-3 mb-2 text-center">
            <p className="mb-0 name">Sorcery of thorns</p>
            <p className="prod_price mb-0 mt-2">
              0.15
              <img src={coin2} className="img-fluid prod_img" />
              BNB
              <img src={sign1} className="img-fluid sign_img" />
              $108.42
            </p>
          </div>
        </div>
      </div>
    </Link>
  );
}

export default BookingCollectionCard;
