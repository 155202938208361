import React from "react";
import { Button } from "react-bootstrap";

function ShippingAddress() {
  return (
    <div className="shippingAddress">
      <div className="mt-2 AddressInp mb-2">
        <input type="text" placeholder="Full name" />
        <Button className="cnfmBtn">Confirm</Button>
      </div>

      <div className="mt-2 AddressInp mb-2">
        <input type="text" placeholder="Address" />
        <Button className="cnfmBtn">Confirm</Button>
      </div>

      <div className="mt-2 AddressInp mb-2">
        <input type="text" placeholder="House, Apt, Etce" />
        <Button className="cnfmBtn">Confirm</Button>
      </div>

      <div className="mt-2 AddressInp mb-2">
        <input type="text" placeholder="City" />
        <Button className="cnfmBtn">Confirm</Button>
      </div>

      <div className="mt-2 AddressInp mb-2">
        <input type="text" placeholder="Country" />
        <Button className="cnfmBtn">Confirm</Button>
      </div>

      <div className="mt-2 AddressInp mb-2">
        <input type="text" placeholder="Country" />
        <Button className="cnfmBtn">Confirm</Button>
      </div>
    </div>
  );
}

export default ShippingAddress;
