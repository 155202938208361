import React from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import closeBtn from "../../Assets/Images/modal_close.svg";

import signUpWallet from "../../Assets/Images/signUpWallet.png";

import uniswap from "../../Assets/Images/wallet/uniswap.png";
import coinbase from "../../Assets/Images/wallet/coinbase.png";
import metamask from "../../Assets/Images/wallet/metamask.png";
import walletConnect from "../../Assets/Images/wallet/wallet-connect.png";
import passkey from "../../Assets/Images/wallet/passkey.png";
import passkeyLogo from "../../Assets/Images/wallet/passkeyLogo.png";

import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
function SignUpModal({
  show,
  handleClose,
  handleSignupPasskeyShow,
  handlePasskeySignInShow,
}) {

  return (
    <div>
      <Modal
        show={show}
        // onHide={handleClose}
        centered
        size="md"
        className="common_Modal_Popup signUp_modal"
      >
        <Modal.Body>
          <Button className="close_btn" onClick={handleClose}>
            <img src={closeBtn} className="img-fluid" />
          </Button>

          <div className="content">
            <div className="head_sec text-center">
              <p className="name mb-0">Account Signup</p>
              <img src={signUpWallet} className="signUpWallet img-fluid my-3" />
              <p className="des mb-0">
                Signup with the favorite digital wallets or create passkey
                access for your account
              </p>
            </div>
            <div className="mt-4 wallet_lists">
              <Button className="wallet">
                <img src={uniswap} className="img-fluid wallet-img" />
                <span className="txt">Uniswap</span>
              </Button>
              <Button className="wallet">
                <img src={coinbase} className="img-fluid wallet-img" />
                <span className="txt">Coin base</span>
              </Button>
              <Button className="wallet">
                <img src={metamask} className="img-fluid wallet-img" />
                <span className="txt">Meta mask</span>
              </Button>
              <Button className="wallet">
                <img src={walletConnect} className="img-fluid wallet-img" />
                <span className="txt">Wallet connect</span>
              </Button>
              <Button className="wallet" onClick={handleSignupPasskeyShow}>
                <img src={passkeyLogo} className="img-fluid wallet-img" />
                <span className="txt">Passkey </span>
              </Button>
            </div>

            <div className="mt-4 cmn_checkbox">
              <Form.Check type="checkbox" id="checkbox" />
              <label>
                I agree to <Link to="/privacy-policy">Privacy Policy</Link> and
                <Link to="/terms-conditions"> Terms & Conditions</Link>
              </label>
            </div>
            <p className="mt-4 mb-0 text-center already-acc">
              Already have an account?{" "}
              <Button
                className="btn btn-link"
                onClick={handlePasskeySignInShow}
              >
                {" "}
                Signin
              </Button>
            </p>
            <p className="mt-4 mb-0 bitdealz-txt text-center">Bitdealz</p>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default SignUpModal;
