import React from "react";
import Modal from "react-bootstrap/Modal";
import { IoMdClose } from "react-icons/io";
import QRCode from "react-qr-code";
import { Image } from "../../data/Images";

function EthTransferModal({ show, handleClose }) {
  return (
    <div>
      <Modal
        show={show}
        // onHide={handleClose}
        centered
        size="md"
        className="secondary_modal mdl_max_wid"
      >
        <Modal.Body>
          {/* <Button className="close_btn" onClick={handleClose}>
            <img src={closeBtn} className="img-fluid" />
          </Button> */}

          <div className="sm_mdl_header d-flex align-items-center justify-content-between p-3">
            <p className="title_sm mb-0 ">ETH Deposit / Receive</p>
            <button
              className="border-0 outline-0 bg-transparent"
              onClick={handleClose}
            >
              <IoMdClose fontSize={20} />
            </button>
          </div>
          <div className="sm_mdl_body px-3 py-5 d-flex flex-column align-items-center">
            <div className="sm_qr_wrapper p-3">
              {" "}
              {/* <QRCode
                size={256}
                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                // value={value}
                // viewBox={`0 0 256 256`}
              /> */}
              <img
                src={Image.qrcode}
                alt="qrcode"
                className="img_container_fit"
              />
            </div>{" "}
            <div className="address_card p-2 mt-4">
              <div className="row align-items-center mx-auto">
                <div className="col-10 px-0">
                  <div>
                    <p className="strike_gray_text mb-0">Your address</p>
                    <p className="p_bold_sm_xxs mb-0 mt-2">
                      0x481e31655sde65416
                    </p>
                  </div>
                </div>
                <div className="col-2 pe-0 d-flex align-items-center justify-content-end">
                  <button className="border-0 outline-0 bg-transparent">
                    <img
                      src={Image.copyicon}
                      alt="copy"
                      className="img-fluid"
                      style={{ width: "13px" }}
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default EthTransferModal;
