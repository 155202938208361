import React, { useState, useRef } from "react";
import prodImg1 from "../Assets/Images/prod_dec1.png";
import prodImg2 from "../Assets/Images/prod_dec2.png";
import prodImg3 from "../Assets/Images/prod_dec3.png";

import rating from "../Assets/Images/ratings.svg";

import watch1 from "../Assets/Images/WatchImages/img1.png";
import watch2 from "../Assets/Images/WatchImages/img2.png";
import watch3 from "../Assets/Images/WatchImages/img3.png";

import User from "../Assets/Images/userImg.png";

import product1 from "../Assets/Images/Joystick.png";
import product2 from "../Assets/Images/mobile.png";
import product3 from "../Assets/Images/airbots.png";
import product4 from "../Assets/Images/headphone.png";
import ratings from "../Assets/Images/ratings.svg";
import OwlCarousel from "react-owl-carousel";
import { Button } from "react-bootstrap";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { AiFillLike } from "react-icons/ai";
import { AiFillDislike } from "react-icons/ai";
import TodayDealzCard from "./ProductCard1";
import RecentlyViewedProduct from "./RecentlyViewdProduct";

function ProductDescriptions() {
  const [productTabs, setProductTabs] = useState("description");
  const [reviewTabs, setReviewTabs] = useState("product");
  const Specification = [
    {
      id: 1,
      name: "Brand",
      value: "Sony",
    },
    {
      id: 2,
      name: "Model Number",
      value: "wrb-sw-colorfiticon2-std-blu_blu",
    },
    {
      id: 3,
      name: "Color",
      value: "Black",
    },
    {
      id: 4,
      name: "Size",
      value: "Regular",
    },
    {
      id: 5,
      name: "Touchscreen",
      value: "Yes",
    },
    {
      id: 6,
      name: "Water Resistant",
      value: "Yes",
    },
    {
      id: 7,
      name: "Usage",
      value: "Fitness & Outdoor",
    },
    {
      id: 8,
      name: "Dial Material",
      value: "Polycarbonate",
    },
    {
      id: 9,
      name: "Ideal For",
      value: "Men & Women",
    },
    {
      id: 10,
      name: "Compatible OS",
      value: "Android & iOS",
    },
  ];

  const reviews = [
    {
      id: 1,
      name: "John",
      reviewImg: [
        {
          img: watch1,
        },
        {
          img: watch2,
        },
        {
          img: watch3,
        },
        {
          img: watch1,
        },
        {
          img: watch2,
        },
      ],
      date: "Jan 3 2024",
      profile: "",
    },
    {
      id: 2,
      name: "Michel",
      reviewImg: [
        {
          img: watch1,
        },
        {
          img: watch2,
        },
        {
          img: watch3,
        },
      ],
      date: "Feb 14 2024",
      profile: User,
    },
    {
      id: 3,
      name: "Randy",
      reviewImg: [
        {
          img: watch1,
        },
        {
          img: watch2,
        },
        {
          img: watch3,
        },
        {
          img: watch1,
        },
        {
          img: watch2,
        },
      ],
      date: "Mar 20 2024",
      profile: "",
    },
    {
      id: 4,
      name: "Roman",
      reviewImg: [
        {
          img: watch1,
        },
        {
          img: watch2,
        },
        {
          img: watch3,
        },
      ],
      date: "Apr14 2024",
      profile: User,
    },
  ];

  const QuesAns = [
    {
      id: 1,
    },
    {
      id: 2,
    },
  ];

  const FrequentlyBought = [
    {
      id: 1,
      prodImg: product1,
      ProdName: "Lorem ipsum dolor sit amet adi pscing elitr",
      ratings: ratings,
      ProdStatus: "minus",
      ProdStatusVal: "Limited Deal",
    },
    {
      id: 2,
      prodImg: product2,
      ProdName: "Lorem ipsum dolor sit amet adi pscing elitr",
      ratings: ratings,
      ProdStatus: "minus",
      ProdStatusVal: "Limited Deal",
    },
    {
      id: 3,
      prodImg: product3,
      ProdName: "Lorem ipsum dolor sit amet adi pscing elitr",
      ratings: ratings,
      ProdStatus: "minus",
      ProdStatusVal: "Limited Deal",
    },
    {
      id: 4,
      prodImg: product4,
      ProdName: "Lorem ipsum dolor sit amet adi pscing elitr",
      ratings: ratings,
      ProdStatus: "minus",
      ProdStatusVal: "Limited Deal",
    },
    {
      id: 5,
      prodImg: product1,
      ProdName: "Lorem ipsum dolor sit amet adi pscing elitr",
      ratings: ratings,
      ProdStatus: "minus",
      ProdStatusVal: "Limited Deal",
    },
  ];

  const recentlyViewed = [
    {
      id: 1,
      prodImg: product1,
      ProdName: "OPPO Mobile",
      ratings: ratings,
      ProdStatus: "minus",
      ProdStatusVal: "-25%",
    },
    {
      id: 2,
      prodImg: product1,
      ProdName: "OPPO Mobile",
      ratings: ratings,
      ProdStatus: "minus",
      ProdStatusVal: "-25%",
    },
    {
      id: 3,
      prodImg: product1,
      ProdName: "OPPO Mobile",
      ratings: ratings,
      ProdStatus: "minus",
      ProdStatusVal: "-25%",
    },
  ];

  const options = {
    loop: false,
    // center: true,
    // items: 2.5,
    margin: 15,
    autoplay: false,
    dots: false,
    autoplayTimeout: 8500,
    smartSpeed: 450,
    // nav: true,
    responsive: {
      0: {
        items: 1,
      },
      480: {
        items: 1,
      },
      500: {
        items: 1.5,
      },
      768: {
        items: 1.5,
      },
      992: {
        items: 2,
      },
      1200: {
        items: 2.5,
      },
      1800: {
        items: 4.5,
      },
    },
  };

  const descriptionRef = useRef(null);
  const specificationRef = useRef(null);
  const reviewRef = useRef(null);
  const storePolicyRef = useRef(null);
  const chatRef = useRef(null);
  const frequentlyBoughtRef = useRef(null);
  const shippingRef = useRef(null);
  const recentlyViewedRef = useRef(null);

  const handleTabChange = (val, ref, offset = 0) => {
    setProductTabs(val)
    if (ref.current) {
      const element = ref.current;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset + offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };


  return (
    <div className="product_description">
      <div className="header_tabsec">
        <div
          className={
            productTabs == "description" ? "tab_items active" : "tab_items"
          }
          onClick={() => handleTabChange("description", descriptionRef, -150)}
        >
          Description
        </div>
        <div
          className={
            productTabs == "specification" ? "tab_items active" : "tab_items"
          }
          onClick={() =>
            handleTabChange("specification", specificationRef, -150)
          }
        >
          Specifications
        </div>
        <div
          className={
            productTabs == "reviews" ? "tab_items active" : "tab_items"
          }
          onClick={() => handleTabChange("reviews", reviewRef, -150)}
        >
          Reviews
        </div>
        <div
          className={
            productTabs == "store-policies" ? "tab_items active" : "tab_items"
          }
          onClick={() =>
            handleTabChange("store-policies", storePolicyRef, -150)
          }
        >
          Store Policies
        </div>
        <div
          className={productTabs == "chat" ? "tab_items active" : "tab_items"}
          onClick={() => handleTabChange("chat", chatRef, -150)}
        >
          Chat
        </div>
        <div
          className={
            productTabs == "frequently-bought"
              ? "tab_items active"
              : "tab_items"
          }
          onClick={() =>
            handleTabChange("frequently-bought", frequentlyBoughtRef, -150)
          }
        >
          Frequently bought
        </div>
        <div
          className={
            productTabs == "shipping" ? "tab_items active" : "tab_items"
          }
          onClick={() => handleTabChange("shipping", shippingRef, -150)}
        >
          Shipping
        </div>
        <div
          className={
            productTabs == "recently-viewed" ? "tab_items active" : "tab_items"
          }
          onClick={() =>
            handleTabChange("recently-viewed", recentlyViewedRef, -150)
          }
        >
          Recently viewed
        </div>
      </div>
      <div className="tabs_content mt-3">
        <div
          className="description"
          ref={descriptionRef}
          // style={{ height: "100vh", paddingTop: "50px" }}
        >
          <p className="mb-0 header-txt mb-3">Product Description</p>
          <div className="row">
            <div className="col-xl-12 mb-3">
              <div className="big_img">
                <img src={prodImg1} className="img-fluid" />
              </div>
            </div>
            <div className="col-xl-6 mb-2">
              <div className="sml_img">
                <img src={prodImg2} className="img-fluid" />
              </div>
            </div>
            <div className="col-xl-6">
              <div className="sml_img">
                <img src={prodImg3} className="img-fluid" />
              </div>
            </div>
          </div>
          <div className="product_det mt-4 pt-3">
            <p className="head_txt">Product Detail :</p>
            <div className="all_details mt-3">
              <p className="name mb-2">
                Product Dimensions :
                <span className="value"> 22 x 2 x 4.3 cm; 220 Grams</span>
              </p>

              <p className="name mb-2">
                Date First Available :
                <span className="value"> 19 July 2023</span>
              </p>

              <p className="name mb-2">
                Manufacturer :<span className="value"> SKMEI</span>
              </p>

              <p className="name mb-2">
                ASIN :<span className="value"> B0CCFPPDRD</span>
              </p>

              <p className="name mb-2">
                Item model number :<span className="value"> 1990</span>
              </p>

              <p className="name mb-2">
                Country of Origin :<span className="value"> India</span>
              </p>

              <p className="name mb-2">
                Department :<span className="value"> men</span>
              </p>

              <p className="name mb-2">
                Manufacturer :<span className="value"> SKMEI</span>
              </p>

              <p className="name mb-2">
                Packer :<span className="value"> SKMEI</span>
              </p>

              <p className="name mb-2">
                Item Weight :<span className="value"> 220 g Item</span>
              </p>
              <p className="name mb-2">
                Item Dimensions LxWxH :
                <span className="value"> 22 x 2 x 4.3 Centimeters</span>
              </p>
              <p className="name mb-2">
                Net Quantity :<span className="value"> 1.00 count</span>
              </p>
              <p className="name mb-2">
                Generic Name :<span className="value"> Casual Watch</span>
              </p>
            </div>
          </div>
        </div>
        <div className="specification mt-4 pt-2" ref={specificationRef}>
          <p className="mb-0 header-txt mb-3">Specifications</p>
          <div className="row mt-3 pt-1">
            <div className="col-xl-6 mb-2">
              <div className="spec-details">
                {Specification.slice(0, 5).map((item, index) => {
                  return (
                    <div className="item">
                      <div className="spec-name">{item.name}</div>
                      <div className="spec-value">{item.value}</div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="col-xl-6">
              <div className="spec-details">
                {Specification.slice(5).map((item, index) => {
                  return (
                    <div className="item">
                      <div className="spec-name">{item.name}</div>
                      <div className="spec-value">{item.value}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="reviews mt-4 pt-2" ref={reviewRef}>
          <p className="mb-0 header-txt mb-3">Reviews</p>
          <div className="all-reviews mt-4">
            <div className="overall-review">
              <p className="txt mb-0">Over all Review</p>
              <p className="val mt-1 mb-1">5.0</p>
              <div className="d-flex align-items-center gap-1">
                <img src={rating} className="img-fluid ratingImg" />
                <img src={rating} className="img-fluid ratingImg" />
                <img src={rating} className="img-fluid ratingImg" />
                <img src={rating} className="img-fluid ratingImg" />
                <img src={rating} className="img-fluid ratingImg" />
              </div>
            </div>
            <div className="reviews_tabs mt-3">
              <div className="tab_header">
                <div
                  className={reviewTabs == "product" ? "txt active" : "txt"}
                  onClick={() => setReviewTabs("product")}
                >
                  Product Review
                </div>
                <div
                  className={reviewTabs == "store" ? "txt active" : "txt"}
                  onClick={() => setReviewTabs("store")}
                >
                  Store Review
                </div>
              </div>
              {reviewTabs == "product" ? (
                <div>
                  <div className="review_filter mt-3 pt-1">
                    <Button className="active">All</Button>
                    <Button className="">5 Star</Button>
                    <Button className="">4 Star</Button>
                    <Button className="">3 Star</Button>
                    <Button className="">2 Star</Button>
                    <Button className="">1 Star</Button>
                  </div>
                  <div className="user-review mt-3 pt-2">
                    {reviews.map((item, index) => {
                      return (
                        <div className="mb-3 pb-2">
                          <div className="personal-info">
                            <div className="profile">
                              {item.profile ? (
                                <img src={item.profile} className="img-fluid" />
                              ) : (
                                <span className="fst-txt">
                                  {item.name.slice(0, 1)}
                                </span>
                              )}
                            </div>
                            <div>
                              <p className="mb-0 name">{item.name}</p>
                              <p className="date mb-0">{item.date}</p>
                            </div>
                          </div>
                          <div className="d-flex align-items-center gap-1 mt-2 pt-1">
                            <img src={rating} className="img-fluid ratingImg" />
                            <img src={rating} className="img-fluid ratingImg" />
                            <img src={rating} className="img-fluid ratingImg" />
                            <img src={rating} className="img-fluid ratingImg" />
                            <img src={rating} className="img-fluid ratingImg" />
                          </div>
                          <p className="mb-0 review_txt mt-2 pt-1">
                            Lorem ipsum dolor sit amet, consetetur sadipscing
                            elitr, sed diam nonumy eirconsetetur sadipscing
                            elitr Lorem ipsum dolor sit amet, consetetur
                            sadipscing elitr, sed diam nonumy eirconsetetur
                          </p>
                          <div className="reviewImages mt-2 pt-2">
                            {item.reviewImg.map((items, index) => {
                              return (
                                <img src={items.img} className="img-fluid" />
                              );
                            })}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="review-pagination mt-3 pt-2">
                    <Button className="arrow-btn prevArr" disabled>
                      <IoIosArrowBack />
                    </Button>
                    <div className="pageNo">
                      <span className="active">1</span>
                      <span className="">2</span>
                    </div>
                    <Button className="arrow-btn nextArr">
                      <IoIosArrowForward />
                    </Button>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
        <div className="storePolicy mt-4 pt-2" ref={storePolicyRef}>
          <p className="mb-0 header-txt mb-3">Store Policies</p>
          <div className="mt-3">
            <div className="policy-type">
              <p className="name mb-2">Shipping Policy :</p>
              <p className="description mb-2">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua. At vero eos et accusam et
                justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
                takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum
                dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
                sed diam voluptua. At vero eos et accusam et justo duo.
              </p>
              <Button className="checkBtn btn-link">Check here</Button>
            </div>
            <div className="policy-type mt-3 pt-1">
              <p className="name mb-2">Refund Policy :</p>
              <p className="description mb-2">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua. At vero eos et accusam et
                justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
                takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum
                dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
                sed diam voluptua. At vero eos et accusam et justo duo.
              </p>
              <Button className="checkBtn btn-link">Check here</Button>
            </div>
            <div className="policy-type mt-3 pt-1">
              <p className="name mb-2">
                Cancellation / Return / Exchange Policy :
              </p>
              <p className="description mb-2">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua. At vero eos et accusam et
                justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
                takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum
                dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
                sed diam voluptua. At vero eos et accusam et justo duo.
              </p>
              <Button className="checkBtn btn-link">Check here</Button>
            </div>
          </div>
        </div>
        <div className="userChart mt-4 pt-2" ref={chatRef}>
          <p className="mb-0 header-txt mb-3">Chat</p>
          <div className="row">
            <div className="col-12 col-lg-8">
              <div className="user_questionBox">
                <textarea placeholder="Have doubt regrading this product ?" />
                <Button className="postQuesBtn">Post your question</Button>
              </div>
            </div>
          </div>
          <div className="mt-4 Ques_Ans">
            <p className="title-txt mb-0">Questions and Answers</p>
            <hr className="" />
            <div className="all_quesAns mt-3">
              {QuesAns.map((item, index) => {
                return (
                  <div>
                    <div className="d-flex align-items-start gap-1 gap-xxl-2">
                      <p className="name d-flex mb-0">
                        Q<span className="ms-1">:</span>
                      </p>

                      <p className="mb-0 value">
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                        sed diam nonumy eirmod ?
                      </p>
                    </div>
                    <div className="d-flex align-items-start gap-1 gap-xxl-2 mt-1">
                      <p className="name d-flex mb-0">
                        A <span className="ms-1">:</span>
                      </p>

                      <p className="mb-0 value">
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                        sed diam nonumy eirmod tempor invidunt ut labore et
                        dolore magna aliquyam erat, sed diam voluptua. At vero
                        eos et accusam et justo duo dolores et ea rebum. Stet
                        clita kasd gubergren, no sea takimata sanctus est Lorem
                        ipsum dolor sit amet. Lorem ipsum dolor sit amet,
                        consetetur sadipscing elitr, sed diam nonumy eirmod
                        tempor invidunt ut labore et dolore magna aliquyam erat,
                        sed diam voluptua. At vero eos et accusam et justo duo
                        dolores et ea
                      </p>
                    </div>
                    <div className="likes mt-3">
                      <div className="d-flex align-items-center gap-1">
                        <AiFillLike className="like" />
                        <span>1.1 k</span>
                      </div>
                      <div className="d-flex align-items-center gap-1">
                        <AiFillDislike className="dislike" />
                        <span>10</span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="frequentluBought mt-4 pt-2" ref={frequentlyBoughtRef}>
          <p className="mb-0 header-txt mb-3">Frequently bought</p>
          <div className="mt-3">
            <div className="row">
              {FrequentlyBought.map((item, index) => {
                return (
                  <div className="col-12 col-sm-6 col-xl-4 col-xxl-3 mb-3">
                    <TodayDealzCard items={item} />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="frequentluBought mt-4 pt-2" ref={shippingRef}>
          <p className="mb-0 header-txt mb-3">Shipping</p>
          <div className="mt-3">
            <div className="row">
              {FrequentlyBought.map((item, index) => {
                return (
                  <div className="col-12 col-sm-6 col-xl-4 col-xxl-3 mb-3">
                    <TodayDealzCard items={item} />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="recentlyViewed mt-4 " ref={recentlyViewedRef}>
          <p className="mb-0 header-txt mb-3">Recently Viewed</p>
          <div className="mt-3">
            <OwlCarousel
              id="customer-testimonoals"
              className="owl-carousel owl-theme todayDelaz_owlCarousal"
              {...options}
            >
              {recentlyViewed.map((item, index) => {
                return <RecentlyViewedProduct items={item} />;
              })}
            </OwlCarousel>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductDescriptions;
