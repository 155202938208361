// import package

import React, { useRef, useState } from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import Select from "react-select";
import ReactDatatable from "@ashvin27/react-datatable";
import { Link } from "react-router-dom";

// import local
import { Image } from "../data/Images";
import DashLayout from "../Components/DashboardLayout/DashLayout";
import OrderStatus from "../table/OrderStatus";
import FeatureProductCard from "../Components/FeatureProductCard";
import userpf from "../Assets/Images/userpf.svg";
import { IoSearchOutline } from "react-icons/io5";
import { FaCheckCircle } from "react-icons/fa";
import indiaflag from "../Assets/Images/india.svg";
import bnb from "../Assets/Images/bnb.png";
import BottomTopCard from "../Components/BottomTopCards";
import WalletAndNetwork from "../Components/WalletAndNetwork";
import EscrowLock from "../Components/EscrowLock";

const Accounts = () => {
  const [searchFiat, setSearchFiat] = useState("");
  const [searchCrypto, setSearchCrypto] = useState("");
  const [activecrypto, setActivecrypto] = useState(1);
  const [activefiat, setActivefiat] = useState(2);
  const FeaturedProduct = [
    {
      id: 1,
      Img: Image.joystick,
      Name: "Smart Watch for Android",
    },
    {
      id: 2,
      Img: Image.hometheatre,
      Name: "Smart Watch for Android",
    },
    {
      id: 3,
      Img: Image.headphone,
      Name: "Smart Watch for Android",
    },
  ];
  const [networks, setNetworks] = useState([
    {
      img: Image.bsc,
      name: "BSC",
    },
    {
      img: Image.bsc,
      name: "BSC",
    },
    {
      img: Image.bsc,
      name: "BSC",
    },
    {
      img: Image.bsc,
      name: "BSC",
    },
    {
      img: Image.bsc,
      name: "BSC",
    },
    {
      img: Image.bsc,
      name: "BSC",
    },
  ]);
  const [selected, setSelected] = useState({
    value: "Select Coin",
    label: "Select Coin",
    img: Image.bsc,
  });

  const [buySellRecords, setBuySellRecords] = useState([
    {
      id: 1,
      img: Image.btc,
      wallet: "ETH",
      walletBalance: "0.02154 ETH",
      spotBalance: "0.021 ETH",
      derivativeBalance: "0.021 ETH",
      unrealized: "0.021 ETH",
    },
    {
      id: 2,
      img: Image.tron,
      wallet: "BNB",
      walletBalance: "0.02154 ETH",
      spotBalance: "0.021 ETH",
      derivativeBalance: "0.021 ETH",
      unrealized: "0.021 ETH",
    },
    {
      id: 3,
      img: Image.eth,
      wallet: "TRON",
      walletBalance: "0.02154 ETH",
      spotBalance: "0.021 ETH",
      derivativeBalance: "0.021 ETH",
      unrealized: "0.021 ETH",
    },
    {
      id: 4,
      img: Image.coin1,
      wallet: "BTC",
      walletBalance: "0.02154 ETH",
      spotBalance: "0.021 ETH",
      derivativeBalance: "0.021 ETH",
      unrealized: "0.021 ETH",
    },
    {
      id: 5,
      img: Image.btc,
      wallet: "ETH",
      walletBalance: "0.02154 ETH",
      spotBalance: "0.021 ETH",
      derivativeBalance: "0.021 ETH",
      unrealized: "0.021 ETH",
    },
    {
      id: 6,
      img: Image.tron,
      wallet: "TRON",
      walletBalance: "0.02154 ETH",
      spotBalance: "0.021 ETH",
      derivativeBalance: "0.021 ETH",
      unrealized: "0.021 ETH",
    },
  ]);
  const [depositHistory, setDepositHistory] = useState([
    {
      id: 1,
      contract: "BTCUSDT",
      qty: "20.0000",
      filledRemaining: "20.0000/0.000",
      execPrice: "168.20",
      triggerPrice: "168.20",
      orderPrice: "3364.000",
      orderValue: "0.65400",
      tradingFees: "0.65400",
      type: "Buy",
      status: "Completed",
      order: "64dgdg",
    },
    {
      id: 2,
      contract: "BTCUSDT",
      qty: "20.0000",
      filledRemaining: "20.0000/0.000",
      execPrice: "168.20",
      triggerPrice: "168.20",
      orderPrice: "3364.000",
      orderValue: "0.65400",
      tradingFees: "0.65400",
      type: "Buy",
      status: "Completed",
      order: "64dgdg",
    },
    {
      id: 3,
      contract: "BTCUSDT",
      qty: "20.0000",
      filledRemaining: "20.0000/0.000",
      execPrice: "168.20",
      triggerPrice: "168.20",
      orderPrice: "3364.000",
      orderValue: "0.65400",
      tradingFees: "0.65400",
      type: "Buy",
      status: "Completed",
      order: "64dgdg",
    },
    {
      id: 4,
      contract: "BTCUSDT",
      qty: "20.0000",
      filledRemaining: "20.0000/0.000",
      execPrice: "168.20",
      triggerPrice: "168.20",
      orderPrice: "3364.000",
      orderValue: "0.65400",
      tradingFees: "0.65400",
      type: "Buy",
      status: "Completed",
      order: "64dgdg",
    },
    {
      id: 5,
      contract: "BTCUSDT",
      qty: "20.0000",
      filledRemaining: "20.0000/0.000",
      execPrice: "168.20",
      triggerPrice: "168.20",
      orderPrice: "3364.000",
      orderValue: "0.65400",
      tradingFees: "0.65400",
      type: "Buy",
      status: "Completed",
      order: "64dgdg",
    },
    {
      id: 6,
      contract: "BTCUSDT",
      qty: "20.0000",
      filledRemaining: "20.0000/0.000",
      execPrice: "168.20",
      triggerPrice: "168.20",
      orderPrice: "3364.000",
      orderValue: "0.65400",
      tradingFees: "0.65400",
      type: "Buy",
      status: "Completed",
      order: "64dgdg",
    },
    {
      id: 7,
      contract: "BTCUSDT",
      qty: "20.0000",
      filledRemaining: "20.0000/0.000",
      execPrice: "168.20",
      triggerPrice: "168.20",
      orderPrice: "3364.000",
      orderValue: "0.65400",
      tradingFees: "0.65400",
      type: "Buy",
      status: "Completed",
      order: "64dgdg",
    },
  ]);
  const [withdrawHistory, setWithdrawHistory] = useState([
    {
      id: 1,
      coin: "ETH",
      amount: "12.00100",
      transactionFees: "0.0011",
      walletAddress: "0x9566b3io767gj34",
      status: "Completed",
      time: "06:03:24-19:43",
      withdrawRemark: "Lorem ipsum",
    },
    {
      id: 2,
      coin: "ETH",
      amount: "12.00100",
      transactionFees: "0.0011",
      walletAddress: "0x9566b3io767gj34",
      status: "Completed",
      time: "06:03:24-19:43",
      withdrawRemark: "Lorem ipsum",
    },
    {
      id: 3,
      coin: "ETH",
      amount: "12.00100",
      transactionFees: "0.0011",
      walletAddress: "0x9566b3io767gj34",
      status: "Completed",
      time: "06:03:24-19:43",
      withdrawRemark: "Lorem ipsum",
    },
    {
      id: 4,
      coin: "ETH",
      amount: "12.00100",
      transactionFees: "0.0011",
      walletAddress: "0x9566b3io767gj34",
      status: "Completed",
      time: "06:03:24-19:43",
      withdrawRemark: "Lorem ipsum",
    },
    {
      id: 5,
      coin: "ETH",
      amount: "12.00100",
      transactionFees: "0.0011",
      walletAddress: "0x9566b3io767gj34",
      status: "Completed",
      time: "06:03:24-19:43",
      withdrawRemark: "Lorem ipsum",
    },
    {
      id: 6,
      coin: "ETH",
      amount: "12.00100",
      transactionFees: "0.0011",
      walletAddress: "0x9566b3io767gj34",
      status: "Completed",
      time: "06:03:24-19:43",
      withdrawRemark: "Lorem ipsum",
    },
    {
      id: 7,
      coin: "ETH",
      amount: "12.00100",
      transactionFees: "0.0011",
      walletAddress: "0x9566b3io767gj34",
      status: "Completed",
      time: "06:03:24-19:43",
      withdrawRemark: "Lorem ipsum",
    },
  ]);
  const [selectedNetwork, setSelectedNetwork] = useState();
  const [closer, setCloser] = useState();
  const [selectedOption, setSelectedOption] = useState(null);
  const [tabActive, setTabActive] = useState(1);
  const coinRef = useRef();

  const buySellColumns = [
    {
      key: "wallet",
      text: "Wallet",
      className: "w_150",
      align: "start",
      sortable: true,
      cell: (record, index) => (
        <div className="d-flex align-items-center justify-content-start gap-3">
          <div className="table_image_wrapper_sm">
            <img src={record.img} alt="" className="img_container_fit" />
          </div>
          <p className="mb-0">{record.wallet}</p>
        </div>
      ),
    },
    {
      key: "walletbalance",
      text: "Wallet Balance",
      className: "w_180",
      align: "center",
      sortable: false,
      cell: (record, index) => {
        return <p className="mb-0 text-center">{record.walletBalance}</p>;
      },
    },
    {
      key: "spotbalance",
      text: "Spot Balance",
      className: "w_180",
      align: "center",
      sortable: false,
      cell: (record, index) => {
        return <p className="mb-0 text-center">{record.spotBalance}</p>;
      },
    },
    {
      key: "derivativebalance",
      text: "Derivative Balance",
      className: "w_180",
      align: "center",
      sortable: false,
      cell: (record) => {
        return <p className="mb-0 text-center">{record.derivativeBalance}</p>;
      },
    },
    {
      key: "unrealized",
      text: "Unrealized p & L",
      className: "w_250",
      align: "center",
      sortable: false,
      cell: (record) => {
        return <p className="mb-0 text-center">{record.unrealized}</p>;
      },
    },

    {
      key: "actions",
      text: "Actions",
      className: "w_300",
      align: "center",
      sortable: false,
      cell: (record) => {
        return (
          <div className="d-flex align-items-center justify-content-center gap-2">
            <button className="status_badge green3">Deposit</button>
            <button className="status_badge greeninvert">Refund</button>
          </div>
        );
      },
    },
  ];
  const depositHistoryColumns = [
    {
      key: "contract",
      text: "Contract",
      className: "w_150",
      align: "center",
      sortable: true,
      cell: (record, index) => (
        <p className="mb-0 text-center">{record.contract}</p>
      ),
    },
    {
      key: "qty",
      text: "Qty",
      className: "w_150",
      align: "center",
      sortable: true,
      cell: (record, index) => <p className="mb-0 text-center">{record.qty}</p>,
    },
    {
      key: "contract",
      text: "Contract",
      className: "w_150",
      align: "center",
      sortable: true,
      cell: (record, index) => (
        <p className="mb-0 text-center">{record.contract}</p>
      ),
    },
    {
      key: "filledremaining",
      text: "Filled Remaining",
      className: "w_180",
      align: "center",
      sortable: true,
      cell: (record, index) => (
        <p className="mb-0 text-center">{record.filledRemaining}</p>
      ),
    },
    {
      key: "execPrice",
      text: "Exec Price",
      className: "w_150",
      align: "center",
      sortable: true,
      cell: (record, index) => (
        <p className="mb-0 text-center">{record.execPrice}</p>
      ),
    },
    {
      key: "triggerprice",
      text: "Trigger Price",
      className: "w_150",
      align: "center",
      sortable: true,
      cell: (record, index) => (
        <p className="mb-0 text-center">{record.triggerPrice}</p>
      ),
    },
    {
      key: "orderprice",
      text: "Order Price",
      className: "w_150",
      align: "center",
      sortable: true,
      cell: (record, index) => (
        <p className="mb-0 text-center">{record.orderPrice}</p>
      ),
    },
    {
      key: "ordervalue",
      text: "Order Value",
      className: "w_150",
      align: "center",
      sortable: true,
      cell: (record, index) => (
        <p className="mb-0 text-center">{record.orderValue}</p>
      ),
    },
    {
      key: "tradingfees",
      text: "Trading Fees",
      className: "w_150",
      align: "center",
      sortable: true,
      cell: (record, index) => (
        <p className="mb-0 text-center">{record.tradingFees}</p>
      ),
    },
    {
      key: "type",
      text: "Type",
      className: "w_150",
      align: "center",
      sortable: true,
      cell: (record, index) => (
        <p className="mb-0 text-center">{record.type}</p>
      ),
    },
    {
      key: "status",
      text: "Status",
      className: "w_150",
      align: "center",
      sortable: true,
      cell: (record, index) => (
        <p className="mb-0 text-center">{record.status}</p>
      ),
    },
    {
      key: "order",
      text: "Order",
      className: "w_150",
      align: "center",
      sortable: true,
      cell: (record, index) => (
        <p className="mb-0 text-center">{record.order}</p>
      ),
    },
  ];
  const WithdrawColumns = [
    {
      key: "coin",
      text: "Coin",
      className: "w_150",
      align: "center",
      sortable: true,
      cell: (record, index) => (
        <p className="mb-0 text-center">{record.coin}</p>
      ),
    },
    {
      key: "amount",
      text: "Amount",
      className: "w_150",
      align: "center",
      sortable: true,
      cell: (record, index) => (
        <p className="mb-0 text-center">{record.amount}</p>
      ),
    },
    {
      key: "transactionfees",
      text: "Transaction Fees",
      className: "w_180",
      align: "center",
      sortable: true,
      cell: (record, index) => (
        <p className="mb-0 text-center">{record.transactionFees}</p>
      ),
    },
    {
      key: "walletaddress",
      text: "Wallet Address",
      className: "w_200",
      align: "center",
      sortable: true,
      cell: (record, index) => (
        <p className="mb-0 text-center">{record.walletAddress}</p>
      ),
    },
    {
      key: "status",
      text: "Status",
      className: "w_150",
      align: "center",
      sortable: true,
      cell: (record, index) => (
        <p className="mb-0 text-center">{record.status}</p>
      ),
    },
    {
      key: "time",
      text: "Time",
      className: "w_150",
      align: "center",
      sortable: true,
      cell: (record, index) => (
        <p className="mb-0 text-center">{record.time}</p>
      ),
    },
    {
      key: "withdrawalremarks",
      text: "Withdrawal Remarks",
      className: "w_200",
      align: "center",
      sortable: true,
      cell: (record, index) => (
        <p className="mb-0 text-center">{record.withdrawRemark}</p>
      ),
    },
  ];
  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Emailtemplates",
    no_data_text: "No Email Templates found!",
    language: {
      length_menu: "Show MENU result per page",
      filter: "Filter in records...",
      info: "Showing START to END of TOTAL records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: false,
    show_filter: false,
    show_pagination: false,
    show_info: false,
  };
  const extraButtons = [
    {
      className: "btn btn-primary buttons-pdf",
      title: "Export TEst",
      children: [
        <span>
          <i
            className="glyphicon glyphicon-print fa fa-print"
            aria-hidden="true"
          ></i>
        </span>,
      ],
      onClick: (event) => {
        console.log(event);
      },
    },
    {
      className: "btn btn-primary buttons-pdf",
      title: "Export TEst",
      children: [
        <span>
          <i
            className="glyphicon glyphicon-print fa fa-print"
            aria-hidden="true"
          ></i>
        </span>,
      ],
      onClick: (event) => {
        console.log(event);
      },
      onDoubleClick: (event) => {
        console.log("doubleClick");
      },
    },
  ];

  const customOptionRenderer = ({ label, data, value }) => (
    <div
      className="market_select_component px-3 d-flex justify-content-start align-items-center gap-2 mb-1 pointer"
      onClick={() => {
        setSelected({ label, value, img: data.img });
        coinRef.current.blur();
        setCloser(!closer);
      }}
    >
      {console.log("sdhguisd", label, data, value)}
      <img src={data.img} style={{ width: "15px", marginRight: "7px" }} />
      <p className="m-0 overview__selectCompoValue">{label}</p>
    </div>
  );

  const handleTabChange = (getId) => {
    setTabActive(getId);
  };
  const options = [
    { value: "bnb", label: "BNB", img: Image.bsc },
    { value: "tron", label: "TRON", img: Image.bsc },
    { value: "btc", label: "BTC", img: Image.bsc },
  ];
  const [fiatcur, setFiatcur] = useState([
    {
      img: indiaflag,
      coiname: "INR - Indian Rupee",
    },
    {
      img: indiaflag,
      coiname: "USD",
    },
  ]);
  const [cryptocur, setCryptocur] = useState([
    {
      img: bnb,
      coiname: "Binance Coin - BNB",
    },
    {
      img: bnb,
      coiname: "TRON coin - TR",
    },
  ]);

  const handleSearchChange = (e) => {
    setSearchFiat(e.target.value);
  };
  const handleSearchChange1 = (e) => {
    setSearchCrypto(e.target.value);
  };
  const filteredFiat = fiatcur.filter((item) =>
    item.coiname.toLowerCase().includes(searchFiat.toLowerCase())
  );
  const filteredCrypto = cryptocur.filter((item) =>
    item.coiname.toLowerCase().includes(searchCrypto.toLowerCase())
  );
  return (
    <DashLayout>
      <div className="my-4 accounts_page">
        <h5 className="overview__title">Account Management :</h5>
        <div className="d-flex flex-wrap  align-items-center gap-4 mt-4">
          <button
            className={`half_round_btn ${tabActive === 1 ? "active" : ""}`}
            onClick={() => handleTabChange(1)}
          >
            Profile Details
          </button>
          <button
            className={`half_round_btn ${tabActive === 2 ? "active" : ""}`}
            onClick={() => handleTabChange(2)}
          >
            Shipping Details
          </button>
          <button
            className={`half_round_btn ${tabActive === 3 ? "active" : ""}`}
            onClick={() => handleTabChange(3)}
          >
            Currency Management
          </button>
          <button
            className={`half_round_btn ${tabActive === 4 ? "active" : ""}`}
            onClick={() => handleTabChange(4)}
          >
            Passcode
          </button>
          <button
            className={`half_round_btn ${tabActive === 5 ? "active" : ""}`}
            onClick={() => handleTabChange(5)}
          >
            Passkey
          </button>
        </div>
        <div className="tabinnercontent mt-4">
          <div className="innerheadsec">
            <img src={userpf} className="img-fluid headimg" alt="user" />
            <p className="head">
              {tabActive === 1
                ? "Profile Details"
                : tabActive === 2
                ? "Shipping Details"
                : tabActive === 3
                ? "Currency Management"
                : tabActive === 4
                ? "Passcode"
                : tabActive === 5
                ? "Passkey"
                : "Details"}{" "}
              :
            </p>
          </div>
          <div className="innerbtmsec">
            {tabActive === 1 && (
              <div className="pf_details">
                <Form>
                  <Row>
                    <Col lg={5} className="mb-3">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>User Name</Form.Label>
                        <div className="userrow">
                          <Form.Control type="text" placeholder="" />
                          <button className="btn cmn_linearBtn">Apply</button>
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                </Form>
              </div>
            )}
            {tabActive === 2 && (
              <div className="pf_details">
                <Form>
                  <Row>
                    <Col lg={8} className="mb-3">
                      <Row>
                        <Col lg={6} className="mb-3">
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Full Name :</Form.Label>
                            <Form.Control type="text" placeholder="" />
                          </Form.Group>
                        </Col>
                        <Col lg={6} className="mb-3">
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Zip code :</Form.Label>
                            <Form.Control type="text" placeholder="" />
                          </Form.Group>
                        </Col>
                        <Col lg={6} className="mb-3">
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Address :</Form.Label>
                            <Form.Control type="text" placeholder="" />
                          </Form.Group>
                        </Col>
                        <Col lg={6} className="mb-3">
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Phone Number :</Form.Label>
                            <Form.Control type="text" placeholder="" />
                          </Form.Group>
                        </Col>
                        <Col lg={6} className="mb-3">
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>City :</Form.Label>
                            <Form.Control type="text" placeholder="" />
                          </Form.Group>
                        </Col>
                        <Col lg={6} className="mb-3">
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Country :</Form.Label>
                            <Form.Control type="text" placeholder="" />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={12} className="mb-3">
                          <button className="btn cmn_linearBtn">Apply</button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form>
              </div>
            )}
            {tabActive === 3 && (
              <div className="currency_sec">
                <div className="topselectsec">
                  <p className="account__subTitle">
                    Your current blockchain network{" "}
                  </p>
                  <div className="market_coinname_select position-relative">
                    <img
                      src={Image.bsc}
                      className="img-fluid escrowCard__selectImg"
                    />
                    <Select
                      classNamePrefix="escrowCard__select"
                      defaultValue={selectedOption}
                      onChange={setSelected}
                      options={options}
                      value={selected}
                      components={{ Option: customOptionRenderer }}
                      ref={coinRef}
                      placeholder=""
                    />
                  </div>
                </div>
                <Row className="cur_row">
                  <Col lg={4} className="mb-3">
                    <div className="currency_card">
                      <div className="headsec">
                        <p>Choose fiat currency</p>
                      </div>
                      <div className="midsec">
                        <InputGroup className="mb-3">
                          <InputGroup.Text id="basic-addon1">
                            <IoSearchOutline fontSize={23} />
                          </InputGroup.Text>
                          <Form.Control
                            placeholder="Type a currency name"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            value={searchFiat}
                            onChange={handleSearchChange}
                          />
                        </InputGroup>
                        <ul className="listsec">
                          {filteredFiat.map((e, i) => (
                            <li onClick={() => setActivefiat(i)}>
                              <div className="leftsec">
                                <img
                                  src={e.img}
                                  alt=""
                                  className="img-fluid flagimg"
                                />
                                <p className="desccoin">{e.coiname}</p>
                              </div>
                              {activefiat == i && (
                                <div>
                                  <FaCheckCircle fill="#5ba62b" fontSize={18} />
                                </div>
                              )}
                            </li>
                          ))}
                        </ul>
                      </div>
                      <button className="btn cmn_linearBtn">Apply</button>
                    </div>
                  </Col>
                  <Col lg={4} className="mb-3">
                    <div className="currency_card">
                      <div className="headsec">
                        <p>Choose Crypto currency</p>
                      </div>
                      <div className="midsec">
                        <InputGroup className="mb-3">
                          <InputGroup.Text id="basic-addon1">
                            <IoSearchOutline fontSize={23} />
                          </InputGroup.Text>
                          <Form.Control
                            placeholder="Type a Crypto currency name"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            value={searchCrypto}
                            onChange={handleSearchChange1}
                          />
                        </InputGroup>
                        <ul className="listsec">
                          {filteredCrypto.map((e, i) => (
                            <li onClick={() => setActivecrypto(i)}>
                              <div className="leftsec">
                                <img
                                  src={e.img}
                                  alt=""
                                  className="img-fluid flagimg"
                                />
                                <p className="desccoin">{e.coiname}</p>
                              </div>
                              {activecrypto == i && (
                                <div>
                                  <FaCheckCircle fill="#5ba62b" fontSize={18} />
                                </div>
                              )}
                            </li>
                          ))}
                        </ul>
                      </div>
                      <button className="btn cmn_linearBtn">Apply</button>
                    </div>
                  </Col>
                </Row>
              </div>
            )}
            {tabActive === 4 && (
              <div className="passcode_details">
                <Form>
                  <Row>
                    <Col lg={8} className="mb-3">
                      <Row>
                        <Col lg={6} className="mb-3">
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>
                              Enter Existing 6 Digit Passcode
                            </Form.Label>
                            <Form.Control type="text" placeholder="" />
                          </Form.Group>
                        </Col>
                        <Col lg={6} className="mb-3">
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>
                              Enter New 6 Digit Passcode :
                            </Form.Label>
                            <Form.Control type="text" placeholder="" />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={12} className="mb-3">
                          <button className="btn cmn_linearBtn">Apply</button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form>
              </div>
            )}
          </div>
        </div>

        <BottomTopCard />
      </div>
    </DashLayout>
  );
};

export default Accounts;
