import React from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import closeBtn from "../../Assets/Images/modal_close.svg";

import signUpWallet from "../../Assets/Images/passkey.png";

import uniswap from "../../Assets/Images/wallet/uniswap.png";
import coinbase from "../../Assets/Images/wallet/coinbase.png";
import metamask from "../../Assets/Images/wallet/metamask.png";
import walletConnect from "../../Assets/Images/wallet/wallet-connect.png";
import passkey from "../../Assets/Images/wallet/passkey.png";

import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import PinInput from "react-pin-input";
function SignUpPasskeyCnfmModal({ show, handleClose, SignupSuccessShow }) {
  const [pin, setPin] = useState("123456");

  const handleComplete = (value) => {
    setPin(value);
  };
  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        size="md"
        className="common_Modal_Popup signUp_modal"
      >
        <Modal.Body>
          <Button className="close_btn" onClick={handleClose}>
            <img src={closeBtn} className="img-fluid" />
          </Button>

          <div className="content">
            <div className="head_sec text-center">
              <p className="name mb-0">Signup with Passkey</p>
              <img src={signUpWallet} className="signUpWallet img-fluid my-3" />
              <p className="passkey_protect mb-0">Passkey Protected</p>
              <p className="create-passkey mb-0 mt-1">
                Confirm a 6 Digit Passkey code
              </p>
            </div>
            <div className="mt-4 passkey_inp">
              <PinInput
                length={6}
                initialValue={pin}
                secret
                secretDelay={100}
                onChange={(value, index) => {}}
                type="numeric"
                inputMode="number"
                // style={{ padding: "10px" }}
                // inputStyle={{ borderColor: "red" }}
                // inputFocusStyle={{ borderColor: "blue" }}
                onComplete={handleComplete}
                // autoSelect={true}
                // regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
              />
            </div>

            <div className="mt-5 cmn_checkbox justify-content-center">
              <Form.Check type="checkbox" id="checkbox" />
              <label>
                I agree to <Link to="/privacy-policy">Privacy Policy</Link> and
                <Link to="/terms-conditions"> Terms & Conditions</Link>
              </label>
            </div>
            <p className="mt-4 mb-0 text-center">
              <Button
                className="confirmBtn cmn_linearBtn"
                onClick={SignupSuccessShow}
              >
                Signup
              </Button>
            </p>
            <div className="d-flex align-items-center justify-content-center">
              <p className="mt-4 mb-0 bitdealz-txt reset-txt text-center">
                Reset
              </p>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default SignUpPasskeyCnfmModal;
