import React from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import closeBtn from "../../Assets/Images/modal_close.svg";

import logo from "../../Assets/Images/smlLogo.svg";
import seller from "../../Assets/Images/seller.svg";
import customer from "../../Assets/Images/customer.svg";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
function ShiplocationModal({ show, handleClose }) {


  const [addressDefault, setAddressDefault] = useState(0);

  const address = [
    {
      id: 1,
    },
    {
      id: 2,
    },
  ];
  return (
    <div>
      <Modal
        show={show}
        // onHide={handleClose}
        centered
        size="md"
        className="common_Modal_Popup shiplocation_modal"
      >
        <Modal.Body>
          <Button className="close_btn" onClick={handleClose}>
            <img src={closeBtn} className="img-fluid" />
          </Button>
          <div className="header_sec">Ship to location</div>
          <div className="addressList">
            {address.map((item, index) => {
              return (
                <div
                  className={
                    addressDefault == index
                      ? "address_sec active mb-3"
                      : "address_sec mb-3"
                  }
                  onClick={() => setAddressDefault(index)}
                >
                  <p className="address mb-0">
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    diam nonumy eirmod tempor invidunt ut labore et dolore magna
                    aliquyam erat, sed diam voluptua. At vero eos et accusam et
                    justo duo dolores et ea rebum. Stet clita kasd
                  </p>
                  <p className="mb-0 mt-2 default_add">Default address</p>
                </div>
              );
            })}
          </div>
          <div className="apply_btn mb-0 mb-sm-3">
            <Button className="cmn_linearBtn">Apply</Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ShiplocationModal;
