import React from "react";
import { Link } from "react-router-dom";
import trendingShow1 from "../Assets/Images/trending_show1.png";
import trendingShow2 from "../Assets/Images/trending_show2.png";
import trendingShowBig from "../Assets/Images/trendingShoes_big.png";

import { Button } from "react-bootstrap";

function TrendingShoes() {
  return (
    <div className="trendingShoes">
      <div className="container">
        <div className="cmnHeading_sec">
          <p className="mb-0 heading_txt">Trending Shoes Collections</p>
          <Link to="/" className="text-decoration-none">
            <p className="mb-0 see-all">See all</p>
          </Link>
        </div>
        <div className="trendingShoes_card">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 lft-col">
              {/* <div className="lft_img_sec">
                <div className="center_cnt">
                  <div className="text-center">
                    <p className="mb-0 name">BEST SELLING</p>
                    <Button className="showNow_btn shopBtn">Shop now</Button>
                  </div>
                </div>
              </div> */}

              <div className="lft_img_big">
                <img src={trendingShowBig} className="" />

                <div className="center_cnt">
                  <div className="text-center">
                    <p className="mb-0 name">BEST SELLING</p>
                    <Link to="/" className="text-decoration-none">
                      <Button className="showNow_btn shopBtn">Shop now</Button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-7 col-xl-7 ryt-col">
              <div className="row">
                <div className="col-12">
                  <div
                    className="ryt_img_sec fst"
                    // style={{ background: `url(${trendingShow1})` }}
                  >
                    <img src={trendingShow1} className="img-fluid" />
                    <div className="content">
                      <span className="offer mb-2">40% OFF</span>
                      <p className="mb-0 name">Premium Shoes</p>
                      <p className="mb-0 name">Products</p>
                      <Link to="/" className="text-decoration-none">
                        <Button className="showNow_btn shopBtn">
                          Shop now
                        </Button>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div
                    className="ryt_img_sec snd"
                    // style={{ background: `url(${trendingShow2})` }}
                  >
                    <img src={trendingShow2} className="img-fluid" />
                    <div className="content">
                      <span className="offer mb-2">40% OFF</span>
                      <p className="mb-0 name">Premium Shoes</p>
                      <p className="mb-0 name">Products</p>
                      <Link to="/" className="text-decoration-none">
                        <Button className="showNow_btn shopBtn">
                          Shop now
                        </Button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TrendingShoes;
