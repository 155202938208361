import React, { useEffect } from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import closeBtn from "../../Assets/Images/modal_close.svg";

import signUpWallet from "../../Assets/Images/signUpWallet.png";

import uniswap from "../../Assets/Images/wallet/uniswap.png";
import coinbase from "../../Assets/Images/wallet/coinbase.png";
import metamask from "../../Assets/Images/wallet/metamask.png";
import walletConnect from "../../Assets/Images/wallet/wallet-connect.png";
import checked from "../../Assets/Images/checked.png";

import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";

function ConnectWalletModal({ show, handleClose }) {
  const [wallet, setWallet] = useState("");
  useEffect(() => {
    localStorage.setItem("walletConnect", false);
  }, []);

  const handleWallet = (val) => {
    setWallet(val);
    localStorage.setItem("walletConnect", true);
    handleClose();
  };

  return (
    <div>
      <Modal
        show={show}
        // onHide={handleClose}
        centered
        size="md"
        className="common_Modal_Popup signUp_modal connect_wallet_modal"
      >
        <Modal.Body>
          <Button className="close_btn" onClick={handleClose}>
            <img src={closeBtn} className="img-fluid" />
          </Button>

          <div className="content">
            <div className="head_sec text-center">
              <p className="name mb-0">Connect with wallets</p>
              <img src={signUpWallet} className="signUpWallet img-fluid my-3" />
              <p className="des mb-0">
                Place order with your favorite wallets of your choice below
              </p>
            </div>
            <div className="mt-4 wallet_lists">
              <Button
                className={wallet == "uniswap" ? "active wallet" : "wallet"}
                onClick={() => handleWallet("uniswap")}
              >
                <div className="walletInfo">
                  <img src={uniswap} className="img-fluid wallet-img" />
                  <span className="txt">Uniswap</span>
                </div>
                <img src={checked} alt="checked" className="checkImg" />
              </Button>
              <Button
                className={wallet == "coinBase" ? "active wallet" : "wallet"}
                onClick={() => handleWallet("coinBase")}
              >
                <div className="walletInfo">
                  <img src={coinbase} className="img-fluid wallet-img" />
                  <span className="txt">Coin base</span>
                </div>
                <img src={checked} alt="checked" className="checkImg" />
              </Button>
              <Button
                className={wallet == "metaMask" ? "active wallet" : "wallet"}
                onClick={() => handleWallet("metaMask")}
              >
                <div className="walletInfo">
                  <img src={metamask} className="img-fluid wallet-img" />
                  <span className="txt">Meta mask</span>
                </div>
                <img src={checked} alt="checked" className="checkImg" />
              </Button>
              <Button
                className={
                  wallet == "walletConnect" ? "active wallet" : "wallet"
                }
                onClick={() => handleWallet("walletConnect")}
              >
                <div className="walletInfo">
                  <img src={walletConnect} className="img-fluid wallet-img" />
                  <span className="txt">Wallet connect</span>
                </div>
                <img src={checked} alt="checked" className="checkImg" />
              </Button>
            </div>

            <p className="mt-4 pt-2 fw-500 mb-0 bitdealz-txt text-center">
              Bitdealz
            </p>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ConnectWalletModal;
