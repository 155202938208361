import React from "react";
import { Link } from "react-router-dom";
import joystick from "../Assets/Images/Joystick.png";
import hometheatre from "../Assets/Images/home_theatre.png";
import headphone from "../Assets/Images/headphone.png";
import tv from "../Assets/Images/tv.png";
import watch from "../Assets/Images/watch.png";

import FeatureProductCard from "./FeatureProductCard";

function FeaturedProduct() {
  const FeaturedProduct = [
    {
      id: 1,
      Img: joystick,
      Name: "Smart Watch for Android",
    },
    {
      id: 2,
      Img: hometheatre,
      Name: "Smart Watch for Android",
    },
    {
      id: 3,
      Img: headphone,
      Name: "Smart Watch for Android",
    },
    {
      id: 4,
      Img: tv,
      Name: "Smart Watch for Android",
    },
    {
      id: 5,
      Img: watch,
      Name: "Smart Watch for Android",
    },

    {
      id: 6,
      Img: joystick,
      Name: "Smart Watch for Android",
    },
    {
      id: 7,
      Img: hometheatre,
      Name: "Smart Watch for Android",
    },
    {
      id: 8,
      Img: headphone,
      Name: "Smart Watch for Android",
    },
    {
      id: 9,
      Img: tv,
      Name: "Smart Watch for Android",
    },
    {
      id: 10,
      Img: watch,
      Name: "Smart Watch for Android",
    },

    {
      id: 11,
      Img: joystick,
      Name: "Smart Watch for Android",
    },
    {
      id: 12,
      Img: hometheatre,
      Name: "Smart Watch for Android",
    },
    {
      id: 13,
      Img: headphone,
      Name: "Smart Watch for Android",
    },
    {
      id: 14,
      Img: tv,
      Name: "Smart Watch for Android",
    },
    {
      id: 15,
      Img: watch,
      Name: "Smart Watch for Android",
    },

    {
      id: 16,
      Img: joystick,
      Name: "Smart Watch for Android",
    },
  ];

  return (
    <div className="featured_product">
      <div className="featuredProduct_container container">
        <div className="cmnHeading_sec">
          <p className="mb-0 heading_txt">Featured products</p>
          <Link to="/" className="text-decoration-none">
            <p className="mb-0 see-all">See all</p>
          </Link>
        </div>

        <div className="card_sec">
          <div className="row">
            {FeaturedProduct.map((item, index) => {
              return (
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 col-xxl-3 col">
                  <Link to="/" className="text-decoration-none">
                    <FeatureProductCard items={item} />
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default FeaturedProduct;
