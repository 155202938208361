import React from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import closeBtn from "../../Assets/Images/modal_close.svg";
import { FaChevronDown } from "react-icons/fa";
import country1 from "../../Assets/Images/india.svg";
import country2 from "../../Assets/Images/US.svg";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import { CiSearch } from "react-icons/ci";
function ShipToModal({ show, handleClose, handleChooseCountryShow }) {
  const [countryList, setCountryList] = useState(false);

  const countrySearch = [
    {
      icon: country1,
      name: "USD - US Doller",
      active: true,
    },
    {
      icon: country2,
      name: "INR - Indian Rupee",
      active: false,
    },
    {
      icon: country1,
      name: "USD - US Doller",
      active: false,
    },
  ];

  const handleCountrySelect = () => {
    setCountryList(!countryList);
  };

  return (
    <div>
      <Modal
        show={show}
        // onHide={handleClose}
        centered
        size="sm"
        className="common_Modal_Popup shipTo_modal"
      >
        <Modal.Body>
          <Button className="close_btn" onClick={handleClose}>
            <img src={closeBtn} className="img-fluid" />
          </Button>
          <div className="content-Sec">
            <p className="header-txt mb-0">Ship to</p>
            <div className="country_select mt-3">
              <Button
                className="mt-2 country_nameBtn"
                onClick={handleCountrySelect}
              >
                India <FaChevronDown />
              </Button>

              {countryList && (
                <div className="country_list">
                  <div className="custom_search">
                    <input type="text" className="" placeholder="Search" />
                    <CiSearch className="search_iocn" />
                  </div>
                  <div className="search_items">
                    <p className="mb-0 pb-1 recommended">Recommended</p>
                    {countrySearch.map((item, index) => {
                      return (
                        <div
                          className="search_items_list"
                          onClick={handleChooseCountryShow}
                        >
                          <div className="logoName">
                            <img src={item.icon} className="search_logos" />
                            <span className="search_name">{item.name}</span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
            <Button className="applyBtn cmn_linearBtn mt-4">Apply</Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ShipToModal;
