import React from "react";
import { Button } from "react-bootstrap";
import { IoIosArrowDown } from "react-icons/io";
import checked from "../Assets/Images/checked.png";
import sign1 from "../Assets/Images/sign1.svg";
import couponBg1 from "../Assets/Images/coupons/couponBg1.png";
import bnb from "../Assets/Images/bnb.png";
function ProductDiscountSummary() {
  return (
    <div className="discount_promo">
      <div className="header-sec">
        <p className="mb-0 promo_ques">Have Promocodes ?</p>
        <p className="enter mb-0">
          Enter here <IoIosArrowDown />
        </p>
      </div>
      <div className="mt-2 shopFavourite">
        Shop your favorite items with crypto currency and apply great discounts
        to save big with bitdealz
      </div>
      <div className="mt-2 promocodeInp">
        <input type="text" placeholder="Enter Promocode" />
        <Button className="applyBtn cmn_greenBtn">Apply</Button>
      </div>
      <div className="discount_Promo mt-2">
        <div
          className="item"
          style={{
            backgroundImage: `URL(${couponBg1})`,
          }}
        >
          <p className="des mb-0">
            Free shipping to all order when shipping fees less than or equal to
            price
          </p>
          <div className="amount">
            <p className="coin mb-0">
              <img src={bnb} className="img-fluid coinImg" />
              <span>0.0024</span>
              <span>BNB</span>
              <img src={checked} className="img-fluid checked" />
            </p>
            <img src={sign1} className="img-fluid signImg" />
            <span className="amt-txt mb-0">$ 2.00</span>
          </div>
          <div className="coupon-id">
            <span className="address">AHJUYHG023154LOK</span>
            <Button className="cmn_linearBtn apply-btn">Apply</Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductDiscountSummary;
