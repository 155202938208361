import React from "react";
import { Link } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";

import travalBag from "../Assets/Images/traval_back.png";
import watch from "../Assets/Images/watch.png";
import bike from "../Assets/Images/bike.png";
import tv from "../Assets/Images/tv.png";

function MostPopularCard({ items }) {
  const Products = [
    {
      id: 1,
      prodImg: travalBag,
      category: "Limited Time Only",
      ProdName: "Suitcases",
      ProdStatus: "minus",
      ProdStatusVal: "-25%",
    },
    {
      id: 2,
      prodImg: watch,
      category: "Limited Time Only",
      ProdName: "Watch",
      ProdStatus: "new",
      ProdStatusVal: "New",
    },
    {
      id: 3,
      prodImg: bike,
      category: "Limited Time Only",
      ProdName: "Bike",
      ProdStatus: "minus",
      ProdStatusVal: "-25%",
    },
    {
      id: 4,
      prodImg: tv,
      category: "Limited Time Only",
      ProdName: "Sony Tv",
      ProdStatus: "trending",
      ProdStatusVal: "Trending",
    },
  ];
  return (
    <div className="mostPopular_card">
      <div className="header_sec">
        <p className="mb-0 title_txt">{items.Name}</p>
        <Link to="/" className="text-decoration-none">
          <p className="mb-0 see-all">
            <IoIosArrowForward size={15} />
          </p>
        </Link>
      </div>
      <div className="productItems_list">
        <div className="row">
          {Products.map((item, index) => {
            return (
              <div className="col-6 col-xl-6 col">
                <Link to="/" className="text-decoration-none">
                  <div className="items">
                    <div
                      className={
                        item.ProdStatus == "minus"
                          ? "status minus"
                          : item.ProdStatus == "new"
                          ? "status new"
                          : "status trending"
                      }
                    >
                      {item?.ProdStatusVal}
                    </div>
                    <div className="productImg">
                      <img src={item?.prodImg} className="img-fluid" />
                    </div>
                    <div className="des">
                      <p className="title_1 mb-0">{item?.category}</p>
                      <p className="title_2 mb-0">{item?.ProdName}</p>
                    </div>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default MostPopularCard;
