import React from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import closeBtn from "../../Assets/Images/modal_close.svg";
import { FaChevronDown } from "react-icons/fa";
import country1 from "../../Assets/Images/india.svg";
import country2 from "../../Assets/Images/US.svg";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import { CiSearch } from "react-icons/ci";

import facebook from "../../Assets/Images/social_media/facebook.svg";
import instagram from "../../Assets/Images/social_media/instagram.png";
import twitter from "../../Assets/Images/social_media/twitter.png";
import whatsapp from "../../Assets/Images/social_media/whatsapp.png";
import telegram from "../../Assets/Images/social_media/telegram.png";
import messanger from "../../Assets/Images/social_media/messanger.png";
import linkedin from "../../Assets/Images/social_media/linkedin.png";
import youtube from "../../Assets/Images/social_media/youtube.png";
import snapchat from "../../Assets/Images/social_media/snapchat.png";
function ShareProductModal({ show, handleClose }) {
  const [countryList, setCountryList] = useState(false);

  const countrySearch = [
    {
      icon: country1,
      name: "USD - US Doller",
      active: true,
    },
    {
      icon: country2,
      name: "INR - Indian Rupee",
      active: false,
    },
    {
      icon: country1,
      name: "USD - US Doller",
      active: false,
    },
  ];

  const handleCountrySelect = () => {
    setCountryList(!countryList);
  };

  return (
    <div>
      <Modal
        show={show}
        // onHide={handleClose}
        centered
        size="sm"
        className="common_Modal_Popup shipTo_modal shareProduct_modal"
      >
        <Modal.Body>
          <Button className="close_btn" onClick={handleClose}>
            <img src={closeBtn} className="img-fluid" />
          </Button>
          <div className="content-Sec">
            <p className="header-txt mb-0">Share your product</p>
            <div className="socialMedia_list mt-3">
              <a href="#" target="_blank">
                <img src={facebook} className="img-fluid socialImages" />
              </a>
              <a href="" target="_blank">
                <img src={instagram} className="img-fluid socialImages" />
              </a>
              <a href="" target="_blank">
                <img src={twitter} className="img-fluid socialImages" />
              </a>
              <a href="" target="_blank">
                <img src={whatsapp} className="img-fluid socialImages" />
              </a>
              <a href="" target="_blank">
                <img src={telegram} className="img-fluid socialImages" />
              </a>
              <a href="" target="_blank">
                <img src={messanger} className="img-fluid socialImages" />
              </a>
              <a href="" target="_blank">
                <img src={linkedin} className="img-fluid socialImages" />
              </a>
              <a href="" target="_blank">
                <img src={youtube} className="img-fluid socialImages" />
              </a>
              <a href="" target="_blank">
                <img src={snapchat} className="img-fluid socialImages" />
              </a>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ShareProductModal;
