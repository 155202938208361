import React from "react";
// import joystick from "../Assets/Images/Joystick.png";
// import hometheatre from "../Assets/Images/home_theatre.png";
// import headphone from "../Assets/Images/headphone.png";
// import tv from "../Assets/Images/tv.png";
// import watch from "../Assets/Images/watch.png";
import coin2 from "../Assets/Images/coin2.png";
import sign1 from "../Assets/Images/sign1.svg";
function FeatureProductCard({ items }) {
  return (
    <div className="feature_product_card">
      <div className="prod_img">
        <img src={items.Img} className="img-fluid" />
      </div>
      <div className="prod_details">
        <p className="name mb-0">{items.Name}</p>
        <p className="prod_price mb-0 mt-2">
          0.15
          <img src={coin2} className="img-fluid prod_img" />
          BNB
          <img src={sign1} className="img-fluid sign_img" />
          $108.42
        </p>
      </div>
    </div>
  );
}

export default FeatureProductCard;
