import React from "react";
import mobile from "../Assets/Images/mobile.png";
import sign1 from "../Assets/Images/sign1.svg";
import bnb from "../Assets/Images/bnb.png";
import verticalLine from "../Assets/Images/vertical_line.png";
import Dropdown from "react-bootstrap/Dropdown";
import Lottie from "lottie-react";
import bellLottie from "../Assets/Json/bell.json";
import lock from "../Assets/Json/lock.json";
import { Button } from "react-bootstrap";
import { FaQuestion } from "react-icons/fa";
import { Link } from "react-router-dom";
function ProductPriceSummary({ shoppingProduct }) {
  return (
    <div className="productPrice_summary">
      <div className="Img">
        <img src={shoppingProduct.img} className="prod_img" />
      </div>
      <div className="details">
        <p className="mb-0 name">{shoppingProduct.name}</p>
        <div className="amount mt-2">
          <div className="d-flex align-items-center gap-2 mx-1">
            <span className="new-amt">$98.40</span>
            <span className="old-amt">$108.42</span>
          </div>
        </div>
        <div className="mt-2 prod_subInfo">
          <div className="quantity">
            <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                Qty : 1
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item href="#">1</Dropdown.Item>
                <Dropdown.Item href="#">2</Dropdown.Item>
                <Dropdown.Item href="#">3</Dropdown.Item>
                <Dropdown.Item href="#">4</Dropdown.Item>
                <Dropdown.Item href="#">5</Dropdown.Item>
                <Dropdown.Item href="#">6</Dropdown.Item>
                <Dropdown.Item href="#">7</Dropdown.Item>
                <Dropdown.Item href="#">8</Dropdown.Item>
                <Dropdown.Item href="#">9</Dropdown.Item>
                <Dropdown.Item href="#">10</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <img src={verticalLine} alt="" className="img-fluid vertical-line" />
          <Button className="delete btn-link">Delete</Button>
          <img src={verticalLine} alt="" className="img-fluid vertical-line" />
          <Button className="saveForlater btn-link">Save for later</Button>
          <img src={verticalLine} alt="" className="img-fluid vertical-line" />
          <div className="position-relative">
            <div className="escrow_lock">
              <Lottie animationData={lock} className="lock_lottie" />
              <div className="lock_dropdown">
                <a href="#" className="a">
                  {" "}
                  Escrow Lock{" "}
                  <Button className="ques">
                    <FaQuestion size={5} />
                  </Button>
                </a>
                <div className="hidden_info dropdown-content">
                  <p className="header_txt mb-0">Info for customers :</p>
                  <div className="info_details mt-1">
                    Escrow Lock protect customer's cryptocurrency from frauds
                    and gives ability for easy re-claim back of your funds.
                    Check more info here.
                  </div>
                  <p className="more-info mb-0 mt-1">
                    Click more info.
                    <Link to=""> here</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductPriceSummary;
