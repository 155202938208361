import React, { useEffect, useRef, useState } from "react";
import DashLayout from "../Components/DashboardLayout/DashLayout";
import { Col, Row } from "react-bootstrap";
import { Image } from "../data/Images";
import { getTime } from "../Common";
import EmojiPicker from "emoji-picker-react";
import WalletAndNetwork from "../Components/WalletAndNetwork";
import EscrowLock from "../Components/EscrowLock";
const Chat = () => {
  // const time = getTime()
  const [msgCont, setMsgCont] = useState("");
  const [receivedMsg, setReceivedMsg] = useState([
    {
      message: "Hello",
      time: getTime(),
    },
  ]);
  const [sendMsg, setSendMsg] = useState([]);
  const containerRef = useRef();
  const [showEmoji, setShowEmoji] = useState(false);

  const handleSend = () => {
    if (msgCont.length > 0) {
      setSendMsg((newMsg) => [
        ...newMsg,
        { message: msgCont, time: getTime() },
      ]);
      setMsgCont("");
      setShowEmoji(false);
    } else {
      alert(`Donesn't send Empty Msg`);
    }
  };
  const scrollToBottom = () => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  };
  console.log("sendMsg", sendMsg);
  const emojiPicker = (e) => {
    console.log("errrrrr", e);
    setMsgCont(msgCont + e.emoji);
  };
  useEffect(() => {
    scrollToBottom();
  }, [msgCont]);
  return (
    <>
      <DashLayout>
        <div className="my-4">
          <h5 className="overview__title">Chat</h5>
          {/* <Row className="mt-4">
            <Col md={5} sm={9}>
              <input
                type="text"
                placeholder="Search"
                className="chat__searcher py-2 px-3 rounded-1 w-100"
              />
            </Col>
          </Row> */}
          <Row className="mt-4 h-100 mb-4">
            <Col md={5} className="mb-4 mb-md-0">
              <div className="h-100 chat__recentChatter">
                <p className="f-14 fw-500 mb-3">Recent Chat</p>
                <input
                  type="text"
                  placeholder="Search"
                  className="chat__searcher py-2 px-3 rounded-1 w-100"
                />
                <div className="chat__shadowBoxScroller mt-3">
                  <div className="chat__shadowBoxHolder p-1">
                    <div className="chat__shadowBox rounded-2 p-3">
                      <div className="d-flex justify-content-start align-items-center gap-2">
                        <img
                          src={Image.userImg}
                          className="img-fluid chat__userImg rounded-5"
                          alt=""
                        />
                        <div>
                          <p className="m-0 chat__senderName">Stephen</p>
                          <p className="m-0 chat__senderLabel">Stephen</p>
                        </div>
                      </div>
                      <p className="m-0 chat__senderDesc mt-3">
                        ipsum dolor sit amet, consetetur sadipscing elitr, sed
                        diam nonumy eirmod tempor invidunt ut labore et dolore
                        magna aliquyam erat, sed diam voluptua. At vero eos et
                        accusam et justo duo dolores et ea rebum. Stet clita
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={7}>
              <div className="chat__chatBox position-relative rounded-2">
                {showEmoji && (
                  <div className="chat__emojiHolder">
                    <EmojiPicker
                      onEmojiClick={(e) => {
                        emojiPicker(e);
                      }}
                    />
                  </div>
                )}
                <div className="chat__chatTopBar p-2 rounded-2">
                  <div className="d-flex justify-content-start align-items-center gap-2">
                    <img
                      src={Image.userImg}
                      className="img-fluid chat__userImg rounded-5"
                      alt=""
                    />
                    <div>
                      <p className="m-0 chat__senderName">Stephen</p>
                      <p className="m-0 chat__senderLabel">Stephen</p>
                    </div>
                  </div>
                </div>
                <div className="chat__msgScroller p-2" ref={containerRef}>
                  <div className=" d-flex justify-content-start align-items-center w-100 mb-2">
                    {receivedMsg?.map((msg) => (
                      <div className="chat__receivedMsg">
                        <div>
                          <div className="chat__receivedMsgCont py-1 px-3 rounded-1">
                            <p className="m-0 chat__receivedMsgDesc text-start">
                              {msg.message}
                            </p>
                          </div>
                          <p className="m-0 chat__receivedTimer text-end mt-1">
                            {msg.time}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="d-flex flex-column justify-content-end align-items-end w-100 mb-2">
                    {sendMsg?.map((msg) => (
                      <div className="chat__sendMsg ">
                        <div>
                          <div className="chat__sendMsgCont py-1 px-3 rounded-1">
                            <p className="m-0 chat__receivedMsgDesc text-start">
                              {msg.message}
                            </p>
                          </div>
                          <p className="m-0 chat__receivedTimer text-end mt-1">
                            {msg.time}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="chat__addonsHolder rounded-2 p-2 mx-3 mt-3">
                  <input
                    onChange={(e) => setMsgCont(e.target.value)}
                    type="text"
                    value={msgCont}
                    placeholder={`What's happening ?`}
                    className={`chat__msgTyper`}
                  />
                  <div className="chat__plugIns d-flex justify-content-between align-items-center mt-1">
                    <div className="d-flex justify-content-start align-items-center gap-2">
                      <button className="chat__plugInsBtn position-relative">
                        <img src={Image.attach} alt="" />
                        <input type="file" className="chat__filesUploader" />
                      </button>
                      <button className="chat__plugInsBtn position-relative">
                        <img src={Image.imager} alt="" />
                        <input type="file" className="chat__filesUploader" />
                      </button>
                      <button
                        className="chat__plugInsBtn"
                        onClick={() => setShowEmoji(!showEmoji)}
                      >
                        <img src={Image.emoji} alt="" />
                      </button>
                    </div>
                    <button
                      className="chat__greenSendBtn rounded-2"
                      onClick={handleSend}
                    >
                      <i class="fa-regular fa-paper-plane"></i>
                    </button>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </DashLayout>
    </>
  );
};

export default Chat;
