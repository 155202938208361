import React, { useState, useEffect } from "react";
import Header from "../Components/Layout/Header";
import Footer from "../Components/Layout/Footer";
import CartHeader from "../Components/CartHeader";
import ShippingAddressList from "../Components/ShippingAddressList";
import FrequentlyAskedQus from "../Components/FrequentlyAskedQus";
import OrderDetails from "../Components/OrderDetails";
import Lottie from "lottie-react";
import lock from "../Assets/Json/lock1.json";

function Checkout() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div className="">
      <Header />
      <CartHeader />
      <div className="checkout mt-4">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-7 col-lg-7 col-xl-8 mb-3">
              <ShippingAddressList />
              <div className="mt-3">
                <FrequentlyAskedQus />
              </div>
              <div className="mt-3">
                <div className="trust_message_deta">
                  <Lottie animationData={lock} className="lock_lottie" />
                  Safe and secure payment, Easy Returns, 100 % Trusted Products
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-8 col-md-5 col-lg-5 col-xl-4 mb-3">
              <OrderDetails />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Checkout;
