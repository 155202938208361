// import package

import React, { useRef, useState } from "react";
import { Button, Col, Dropdown, Row } from "react-bootstrap";
import Select from "react-select";
import Form from "react-bootstrap/Form";
// import local
import { Image } from "../data/Images";
import DashLayout from "../Components/DashboardLayout/DashLayout";
import { Link } from "react-router-dom";
import logos from "../Assets/Images/smlLogo.svg";
import WalletAndNetwork from "../Components/WalletAndNetwork";
import EscrowLock from "../Components/EscrowLock";
const Notifications = () => {
  const [notify, setNotify] = useState([
    {
      img: logos,
      desc: "Explore the best selling range",
      date: "16 Apr, 2024",
    },
    {
      img: logos,
      desc: "Explore the best selling range",
      date: "16 Apr, 2024",
    },
    {
      img: logos,
      desc: "Explore the best selling range",
      date: "16 Apr, 2024",
    },
    {
      img: logos,
      desc: "Explore the best selling range",
      date: "16 Apr, 2024",
    },
    {
      img: logos,
      desc: "Explore the best selling range",
      date: "16 Apr, 2024",
    },
  ]);

  return (
    <DashLayout>
      <div className="my-4 allnotify_page">
        <h5 className="overview__title">All Notifications :</h5>
        <Row>
          <Col sm={12} className="mb-4 mt-3">
            <div className="notifycard">
              <ul>
                {notify.map((e) => (
                  <li>
                    <div>
                      <img className="img-fluid pfimg" src={e.img} alt="" />
                    </div>
                    <div>
                      <p className="notifydesc">{e.desc} </p>
                      <p className="notifydate">{e.date}</p>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </Col>
        </Row>
      </div>
    </DashLayout>
  );
};

export default Notifications;
