import React from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import closeBtn from "../../Assets/Images/modal_close.svg";
import { FaChevronDown } from "react-icons/fa";
import warning from "../../Assets/Images/warning.png";

function DeleteProducts({ show, handleClose }) {
  return (
    <div>
      <Modal
        show={show}
        // onHide={handleClose}
        centered
        size="sm"
        className="common_Modal_Popup delete_shipping_modal"
      >
        <Modal.Body>
          <Button className="close_btn" onClick={handleClose}>
            <img src={closeBtn} className="img-fluid" />
          </Button>
          <div className="content-Sec">
            <div className="header-section mb-0">
              <img src={warning} className="img-fluid warningImg" />
              <p className="mb-0 txt">Are You Sure delete this products ?</p>
            </div>
            <div className="btn_sec mt-2">
              <Button
                className="cancelBtn cmn_grayBtn mt-4"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button className="try_anotherBtn cmn_linearBtn mt-4">Yes</Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default DeleteProducts;
