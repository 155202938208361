import React, { useState } from "react";
import { Button } from "react-bootstrap";
import ReactDatatable from "@ashvin27/react-datatable";
import { Image } from "../data/Images";
import DeleteInviteLink from "./Modals/DeleteInviteLink";
function DeliveryMan() {
  const [tabActive, setTabActive] = useState("Delivery Team");
  const [inviteLink, setInviteLink] = useState(false);
  const teamRecords = [
    {
      id: 1,
      name: "Speedy",
      email: "John@gmail.com",
      deliverytype: [
        {
          name: "Shipping by weight",
        },
        {
          name: "Shipping by distance",
        },
      ],
      walletaddress: "0XYUIO98JU989KLO",
      networktype: [
        {
          name: "BNB",
          logo: Image.coin1,
        },
        {
          name: "BNB",
          logo: Image.coin1,
        },
        {
          name: "BNB",
          logo: Image.coin1,
        },
      ],
      tasks: "Flower Deliveries",
      paymentrate: "0.0230 BNB - $10 per Delivery",
    },
  ];
  const invitedRecords = [
    {
      id: 1,
      email: "John@gmail.com",
      invitation: "Yes",
    },
  ];

  const teamColumns = [
    {
      key: "name",
      text: "Nick Name",
      className: "",
      align: "center",
      sortable: true,
      cell: (record, index) => <p className="mb-0">{record.name}</p>,
    },

    {
      key: "email",
      text: "Email",
      className: "",
      align: "center",
      sortable: false,
      cell: (record, index) => {
        return <p className="mb-0 text-center">{record.email}</p>;
      },
    },
    {
      key: "deliverytype",
      text: "Delivery Type",
      className: "",
      align: "center",
      sortable: false,
      cell: (record, index) => {
        {
          console.log("records", record);
        }
        return (
          <div className="mb-0 d-flex flex-column align-items-center">
            {record.deliverytype.map((item, index) => {
              return (
                <p className="type_listing">
                  {item.name}
                  <img src={Image.checked} alt="" className="img-fluid" />
                </p>
              );
            })}
          </div>
        );
      },
    },

    {
      key: "walletaddress",
      text: "Wallet Address",
      className: "",
      align: "center",
      sortable: false,
      cell: (record, index) => {
        return <p className="mb-0 text-center">{record.walletaddress}</p>;
      },
    },
    {
      key: "networktype",
      text: "Network Type",
      className: "",
      align: "center",
      sortable: false,
      cell: (record, index) => {
        return (
          <div className="mb-0 d-flex flex-column align-items-center">
            {record.networktype.map((item, index) => {
              return (
                <p className="network_type">
                  <img src={item.logo} alt="" className="img-fluid" />
                  {item.name}
                  <img src={Image.checked} alt="" className="img-fluid" />
                </p>
              );
            })}
          </div>
        );
      },
    },
    {
      key: "tasks",
      text: "Tasks",
      className: "",
      align: "center",
      sortable: false,
      cell: (record) => {
        return <p className="mb-0 text-center">{record.tasks}</p>;
      },
    },

    {
      key: "paymentrate",
      text: "Payment rate",
      className: "w_250",
      align: "center",
      sortable: false,
      cell: (record) => {
        return <p className="mb-0 text-center">{record.paymentrate}</p>;
      },
    },
  ];

  const invitedColumns = [
    {
      key: "email",
      text: "Email",
      className: "",
      align: "center",
      sortable: false,
      cell: (record, index) => {
        return <p className="mb-0 text-center">{record.email}</p>;
      },
    },
    {
      key: "invitation",
      text: "Invitation Send",
      className: "",
      align: "center",
      sortable: true,
      cell: (record, index) => (
        <p className="mb-0 text-center">{record.invitation}</p>
      ),
    },
    {
      key: "revoke",
      text: "Revoke Invitation",
      className: "",
      align: "center",
      sortable: false,
      cell: (record, index) => {
        return (
          <p
            className="mb-0 text-center cursor-pointer delete_invitation"
            onClick={handleInviteLinkShow}
          >
            Delete
          </p>
        );
      },
    },
  ];

  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Emailtemplates",
    no_data_text: "No Email Templates found!",
    language: {
      length_menu: "Show MENU result per page",
      filter: "Filter in records...",
      info: "Showing START to END of TOTAL records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: false,
    show_filter: false,
    show_pagination: false,
    show_info: false,
  };

  const handleInviteLinkShow = () => {
    setInviteLink(true);
  };
  const handleInviteLinkClose = () => {
    setInviteLink(false);
  };

  const InviteDelete = [
    {
      id: 1,
    },
    {
      id: 2,
    },
    {
      id: 3,
    },
    {
      id: 4,
    },
  ];

  return (
    <>
      {inviteLink && (
        <DeleteInviteLink
          show={inviteLink}
          handleClose={handleInviteLinkClose}
        />
      )}
      <div className="delivery_Man">
        <div className="send_invite_sec">
          <textarea className="" placeholder="Enter your message" />
          <div className="email_sec">
            <input
              type="text"
              className="emailInp"
              placeholder="Enter your mail ID"
            />
            <Button className="sendInvite_btn cmn-linearBtn">
              Send Invite
            </Button>
          </div>
        </div>
        <div className="table_sec">
          <div className="tab_head">
            <Button
              className={
                tabActive == "Delivery Team" ? "tab_Btn active" : "tab_Btn"
              }
              onClick={() => setTabActive("Delivery Team")}
            >
              Delivery Team
            </Button>
            <Button
              className={tabActive == "Invited" ? "tab_Btn active" : "tab_Btn"}
              onClick={() => setTabActive("Invited")}
            >
              Invited
            </Button>
            <Button
              className={tabActive == "Delete" ? "tab_Btn active" : "tab_Btn"}
              onClick={() => setTabActive("Delete")}
            >
              Delete
            </Button>
          </div>
          <div className="tab_content mt-4">
            <div className="custom_table deliveryman_table">
              {tabActive == "Delivery Team" ? (
                <>
                  {/* Delivery Team */}
                  <ReactDatatable
                    config={config}
                    records={teamRecords}
                    columns={teamColumns}
                    // extraButtons={extraButtons}
                  />
                </>
              ) : tabActive == "Invited" ? (
                <>
                  {/* Invited */}
                  <ReactDatatable
                    config={config}
                    records={invitedRecords}
                    columns={invitedColumns}
                    // extraButtons={extraButtons}
                  />{" "}
                </>
              ) : (
                <>
                  {/* Delete */}
                  <div className="row invite_delete">
                    {InviteDelete.map((item, index) => {
                      return (
                        <div className="col col-xl-6">
                          <div className="delete_invite">
                            <div className="head_sec">Invite (Deleted)</div>
                            <div className="cnt_details">
                              <span className="gray">Mail :</span>{" "}
                              Johndae@gmail.com
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DeliveryMan;
