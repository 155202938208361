// import package

import React, { useRef, useState, useEffect } from "react";
import { Button, Col, Dropdown, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
// import local
import { Image } from "../data/Images";
import DashLayout from "../Components/DashboardLayout/DashLayout";
import { Link } from "react-router-dom";
import Select from "react-select";
import GoogleMapReact from "google-map-react";
import { TbCurrentLocation } from "react-icons/tb";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const AddCoupon = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [coupon, setCoupon] = useState();
  const [expiry, setExpiry] = useState();

  const [dateTime, setDateTime] = useState(false);
  const [minMax, setMinMax] = useState(false);
  const [catSubcat, setCatSubcat] = useState(false);

  const couponType = [
    { value: "default", label: "Default" },
    { value: "flatDeal", label: "Flat Deal" },
  ];

  const shopId = [
    { value: "all", label: "All" },
    { value: "one", label: "One" },
    { value: "two", label: "Two" },
    { value: "three", label: "Three" },
  ];

  const buyShopId = [
    { value: "all", label: "All" },
    { value: "one", label: "One" },
    { value: "two", label: "Two" },
    { value: "three", label: "Three" },
  ];

  const pricePercentage = [
    { value: "prive", label: "price" },
    { value: "percentage", label: "Percentage" },
  ];

  const expiryOption = [
    { value: "all", label: "All" },
    { value: "time", label: "Time" },
    { value: "day", label: "Day" },
  ];

  const selectDays = [
    { value: "all", label: "All" },
    { value: "sunday", label: "Sunday" },
    { value: "monday", label: "Monday" },
    { value: "tuesday", label: "Tuesday" },
    { value: "wednesday", label: "Wednesday" },
    { value: "thursday", label: "Thursday" },
    { value: "friday", label: "Friday" },
    { value: "saturday", label: "Saturday" },
  ];

  const categoryOption = [
    { value: "cat1", label: "Cat1" },
    { value: "cat2", label: "Cat2" },
  ];

  const subCategoryOption = [
    { value: "subCat1", label: "SubCat1" },
    { value: "subCat2", label: "SubCat2" },
  ];

  const handleCoupon = (val) => {
    setCoupon(val);
  };

  const handleExpiry = (val) => {
    setExpiry(val);
  };

  const handleDateTime = (e) => {
    setDateTime(e.target.checked);
  };
  const handleMinMax = (e) => {
    setMinMax(e.target.checked);
  };
  const handleCatSubcat = (e) => {
    setCatSubcat(e.target.checked);
  };

  return (
    <DashLayout>
      <div className="my-4 addCoupons">
        <div className="mt-3">
          <div className="coupon_details">
            <h5 className="overview__title">Add Coupon</h5>
            <div className="coupon_inp mt-3">
              <div className="row mb-4 mb-lg-4">
                <div className="col-lg-4 col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4">
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Coupon Title</Form.Label>
                    <Form.Control type="text" placeholder="" />
                  </Form.Group>
                </div>
                <div className="col-lg-4 col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4">
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput2"
                  >
                    <Form.Label>Description</Form.Label>
                    <Form.Control type="text" placeholder="" />
                  </Form.Group>
                </div>
                <div className="col-lg-4 col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4">
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput3"
                  >
                    <Form.Label>Coupon Code</Form.Label>
                    <Form.Control type="text" placeholder="" />
                  </Form.Group>
                </div>
                <div className="col-lg-4 col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4">
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Coupon Type</Form.Label>
                    <div className="">
                      <Select
                        classNamePrefix="cmn_CouponSelect"
                        options={couponType}
                        onChange={handleCoupon}
                        value={coupon}
                      />
                    </div>
                  </Form.Group>
                </div>
                <div className="col-lg-4 col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4">
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Shop Id</Form.Label>
                    <div className="">
                      <Select
                        classNamePrefix="cmn_CouponSelect"
                        options={shopId}
                        isMulti
                      />
                    </div>
                  </Form.Group>
                </div>

                <>
                  {/* coupon type */}
                  {/* {coupon && ( */}
                  <>
                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Buy Product Id</Form.Label>
                        <div className="">
                          <Select
                            classNamePrefix="cmn_CouponSelect"
                            options={buyShopId}
                            isMulti
                          />
                        </div>
                      </Form.Group>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput4"
                      >
                        <Form.Label>Value</Form.Label>
                        <Form.Control type="text" placeholder="" />
                      </Form.Group>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput5"
                      >
                        <Form.Label>Above Value</Form.Label>
                        <Form.Control type="text" placeholder="" />
                      </Form.Group>
                    </div>
                    <div className="col-lg-4 col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput"
                      >
                        <Form.Label>Price/Percentage</Form.Label>
                        <div className="">
                          <Select
                            classNamePrefix="cmn_CouponSelect"
                            options={pricePercentage}
                          />
                        </div>
                      </Form.Group>
                    </div>
                  </>
                  {/* )} */}
                </>

                <div className="col-lg-4 col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4">
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Expiry</Form.Label>
                    <div className="">
                      <Select
                        classNamePrefix="cmn_CouponSelect"
                        options={expiryOption}
                        onChange={handleExpiry}
                        value={expiry}
                        defaultValue={{ value: "all", label: "All" }}
                      />
                    </div>
                  </Form.Group>
                </div>

                <>
                  {expiry?.value == "time" ? (
                    <>
                      <div className="col-lg-4 col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4">
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput"
                        >
                          <Form.Label>Start Time</Form.Label>
                          <div className="dateTime_picker">
                            <DatePicker
                              selected={startDate}
                              onChange={(date) => setStartDate(date)}
                              timeInputLabel="Time:"
                              dateFormat="MM/dd/yyyy h:mm aa"
                              showTimeInput
                            />
                          </div>
                        </Form.Group>
                      </div>
                      <div className="col-lg-4 col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4">
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput"
                        >
                          <Form.Label>End Time</Form.Label>
                          <div className="dateTime_picker">
                            <DatePicker
                              selected={endDate}
                              onChange={(date) => setEndDate(date)}
                              timeInputLabel="Time:"
                              dateFormat="MM/dd/yyyy h:mm aa"
                              showTimeInput
                            />
                          </div>
                        </Form.Group>
                      </div>
                    </>
                  ) : expiry?.value == "day" ? (
                    <div className="col-lg-4 col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput"
                      >
                        <Form.Label>Select Days</Form.Label>
                        <div className="selectdays">
                          <Select
                            classNamePrefix="cmn_CouponSelect"
                            options={selectDays}
                          />
                        </div>
                      </Form.Group>
                    </div>
                  ) : (
                    <></>
                  )}
                </>

                <div className="col-lg-4 col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4">
                  <Form.Group
                    className=""
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Coupon Image</Form.Label>

                    <div className="cmn_choose_file">
                      <input type="file"></input>
                    </div>
                  </Form.Group>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-lg-4 col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4">
                  <div className="common_checkInp">
                    <div className="head_sec">
                      <Form.Check
                        type="checkbox"
                        id="checkbox"
                        className="cmn_checkBox"
                        onChange={handleDateTime}
                      />
                      <p className="form-label mb-0">Date & Time</p>
                    </div>
                  </div>
                </div>
                {dateTime && (
                  <div className="row mt-2">
                    <div className="col-lg-4 col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4">
                      <Form.Group
                        className="mb-0 mb-sm-3"
                        controlId="exampleForm.ControlInput"
                      >
                        <Form.Label>Start Time</Form.Label>
                        <div className="dateTime_picker">
                          <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            timeInputLabel="Time:"
                            dateFormat="MM/dd/yyyy h:mm aa"
                            showTimeInput
                          />
                        </div>
                      </Form.Group>
                    </div>
                    <div className="col-lg-4 col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput"
                      >
                        <Form.Label>End Time</Form.Label>
                        <div className="dateTime_picker">
                          <DatePicker
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            timeInputLabel="Time:"
                            dateFormat="MM/dd/yyyy h:mm aa"
                            showTimeInput
                          />
                        </div>
                      </Form.Group>
                    </div>
                  </div>
                )}
              </div>
              <div className="row mb-3">
                <div className="col-lg-4 col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4">
                  <div className="common_checkInp">
                    <div className="head_sec">
                      <Form.Check
                        type="checkbox"
                        id="checkbox"
                        className="cmn_checkBox"
                        onChange={handleMinMax}
                      />
                      <p className="form-label mb-0">Min & Max</p>
                    </div>
                  </div>
                </div>
                {minMax && (
                  <div className="row mt-2">
                    <div className="col-lg-4 col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4">
                      <Form.Group
                        className="mb-3 mb-sm-0"
                        controlId="exampleForm.ControlInput"
                      >
                        <Form.Label>Minimum Value</Form.Label>
                        <Form.Control type="number" placeholder="" />
                      </Form.Group>
                    </div>
                    <div className="col-lg-4 col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4">
                      <Form.Group
                        className="mb-3 mb-sm-0"
                        controlId="exampleForm.ControlInput"
                      >
                        <Form.Label>Maximum Value</Form.Label>
                        <Form.Control type="number" placeholder="" />
                      </Form.Group>
                    </div>
                  </div>
                )}
              </div>
              <div className="row">
                <div className="col-lg-4 col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4">
                  <div className="common_checkInp">
                    <div className="head_sec">
                      <Form.Check
                        type="checkbox"
                        id="checkbox"
                        className="cmn_checkBox"
                        onChange={handleCatSubcat}
                      />
                      <p className="form-label mb-0">Category & SubCategory</p>
                    </div>
                  </div>
                </div>
                {catSubcat && (
                  <div className="row mt-2">
                    <div className="col-lg-4 col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4">
                      <Form.Group
                        className="mb-3 mb-sm-0"
                        controlId="exampleForm.ControlInput"
                      >
                        <Form.Label>Category</Form.Label>
                        <div className="">
                          <Select
                            classNamePrefix="cmn_CouponSelect"
                            options={categoryOption}
                          />
                        </div>
                      </Form.Group>
                    </div>
                    <div className="col-lg-4 col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4">
                      <Form.Group
                        className="mb-3 mb-sm-0"
                        controlId="exampleForm.ControlInput"
                      >
                        <Form.Label>Sub Category</Form.Label>
                        <div className="">
                          <Select
                            classNamePrefix="cmn_CouponSelect"
                            options={subCategoryOption}
                          />
                        </div>
                      </Form.Group>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="mt-4">
              <div className="d-flex align-items-center gap-3 cancel_nxt_Btn">
                <Button className="btn cmn_grayBtn">Cancel</Button>
                <Button className="btn cmn-linearBtn">Next</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashLayout>
  );
};

export default AddCoupon;
