import React from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import closeBtn from "../../Assets/Images/modal_close.svg";

import signUpWallet from "../../Assets/Images/passkey5.png";

import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";

function PasskeyCreatedModal({ show, handleClose }) {
  return (
    <div>
      <Modal
        show={show}
        // onHide={handleClose}
        centered
        size="md"
        className="common_Modal_Popup signUp_modal mdl_max_wid"
      >
        <Modal.Body>
          <Button className="close_btn" onClick={handleClose}>
            <img src={closeBtn} className="img-fluid" />
          </Button>

          <div className="content">
            <div className="head_sec text-center">
              <p className="title_md mb-0">Passkey Login</p>
              <img src={signUpWallet} className="signUpWallet img-fluid my-4" />
            </div>
            <div className="">
              <p className="p_bold_sm text-center">
                Passkey created on your security key 
              </p>
              <p className="p_normal_xs text-center mx-auto">
                You can now use your passkey on this security key to verify it's
                you !
              </p>
            </div>{" "}
            <div className="d-flex flex-column align-items-center gap-1 mt-5">
              <Button className="theme_linear_button w-100" onClick={handleClose}>Done</Button>
              {/* <button
                className="transparent_button w-100"
                onClick={handleClose}
              >
                Skip
              </button> */}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default PasskeyCreatedModal;
