import React from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import closeBtn from "../../Assets/Images/modal_close.svg";

import signUpWallet from "../../Assets/Images/passkey1.png";

import uniswap from "../../Assets/Images/wallet/uniswap.png";
import coinbase from "../../Assets/Images/wallet/coinbase.png";
import metamask from "../../Assets/Images/wallet/metamask.png";
import walletConnect from "../../Assets/Images/wallet/wallet-connect.png";
import passkey from "../../Assets/Images/wallet/passkey.png";

import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";

function PasskeyLoginModal({
  show,
  handleClose,
  handleDeviceLoginShow,
  handleSecurityPKLoginShow,
}) {
  const [passkeyId, setPasskeyId] = useState(1);
  return (
    <div>
      <Modal
        show={show}
        // onHide={handleClose}
        centered
        size="md"
        className="common_Modal_Popup signUp_modal mdl_max_wid"
      >
        <Modal.Body>
          <Button className="close_btn" onClick={handleClose}>
            <img src={closeBtn} className="img-fluid" />
          </Button>

          <div className="content">
            <div className="head_sec text-center">
              <p className="title_md mb-0">Passkey Login</p>
              <img src={signUpWallet} className="signUpWallet img-fluid my-4" />
            </div>
            <div className="d-flex flex-column align-items-center gap-3">
              <Button
                className={`${
                  passkeyId === 1 ? "active" : ""
                } w-100 full_width_btn`}
                onClick={() => setPasskeyId(1)}
              >
                iphone, ipad, or Android device
              </Button>
              <Button
                className={`${
                  passkeyId === 2 ? "active" : ""
                } w-100 full_width_btn`}
                onClick={() => setPasskeyId(2)}
              >
                Security Key
              </Button>
            </div>{" "}
            <div className="d-flex flex-column align-items-center gap-1 mt_4rem ">
              <Button
                className="theme_linear_button w-100"
                onClick={
                  passkeyId == 1
                    ? handleDeviceLoginShow
                    : handleSecurityPKLoginShow
                }
              >
                Next
              </Button>
              <Button
                className="transparent_button w-100"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default PasskeyLoginModal;
