import React from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import closeBtn from "../../Assets/Images/modal_close.svg";

import signUpWallet from "../../Assets/Images/passkey.png";


import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";

function PasscodeCreateSuccessModal({ show, handleClose, handlePasscodeLoginSuccessShow }) {

  return (
    <div>
      <Modal
        show={show}
        // onHide={handleClose}
        centered
        size="md"
        className="common_Modal_Popup signUp_modal login_modal"
      >
        <Modal.Body>
          <Button className="close_btn" onClick={handleClose}>
            <img src={closeBtn} className="img-fluid" />
          </Button>

          <div className="content">
            <div className="head_sec text-center">
              <p className="name mb-0">Success</p>
              <img src={signUpWallet} className="signUpWallet img-fluid my-3" />
              <p className="passkey_protect mb-0">
                Your new secure passcode is successfully created !
              </p>
              <p className="create-passkey mb-0 mt-2">Proceed to login</p>
            </div>

            <div className="login_sec">
              <p className="mb-0 text-center">
                <Button
                  className="confirmBtn cmn_linearBtn"
                  onClick={handlePasscodeLoginSuccessShow}
                >
                  Login
                </Button>
              </p>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default PasscodeCreateSuccessModal;
