// import package

import React, { useRef, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
// import local
import { Image } from "../data/Images";
import DashLayout from "../Components/DashboardLayout/DashLayout";
import { Link } from "react-router-dom";
import { RxDividerVertical } from "react-icons/rx";
import { FaAngleDown, FaAngleUp } from "react-icons/fa6";

const QaSeller = () => {
  const [answerReply, setAnswerReply] = useState();
  const [answerShow, setAnswerShow] = useState();
  const [answerIcon, setAnswerIcon] = useState();

  const Questions = [
    {
      id: 1,
      qus: "Is it has wires to connect it",
      ans: "It has a hdmi cable in box.",
    },
    {
      id: 2,
      qus: "It's have good quality",
      ans: "Yes, It's have good quality.",
    },
  ];

  const handleAnsSubmit = (index) => {
    setAnswerShow(index);
  };

  const handleAnsReply = (index) => {
    if (answerReply === "" || answerReply === undefined) {
      setAnswerReply(index);
      setAnswerIcon(index);
    } else if (index === answerReply) {
      setAnswerReply();
      setAnswerIcon();
    } else {
      setAnswerReply(index);
      setAnswerIcon(index);
    }
  };

  return (
    <DashLayout>
      <div className="my-3 qa_seller">
        <h5 className="overview__title">QA Seller</h5>
        <div className="qus_ans mt-4">
          <div className="heading_txt">Question & Answers</div>
          <div className="content_sec">
            {Questions.map((item, index) => {
              return (
                <div className="particular_qus" key={index}>
                  <p className="qus_txt mb-2">Q: {item.qus}</p>
                  {answerShow == index ? (
                    <div className="ans_showHide">
                      <p className="ans_txt mb-0">A:{item?.ans}</p>
                      <div className="hideShowBtns">
                        <Button className="showBtn">Show Answer</Button>
                        <span className="vertical_line" />
                        <Button className="HideBtn">Hide Answer</Button>
                      </div>
                    </div>
                  ) : (
                    <div className="ans_replay">
                      <Button
                        onClick={() => handleAnsReply(index)}
                        className="answer_txt mb-0 d-flex align-items-center gap-1"
                      >
                        Answer
                        {answerReply === index ? (
                          <FaAngleUp />
                        ) : (
                          <FaAngleDown />
                        )}
                      </Button>
                      {answerReply === index && (
                        <>
                          <div className="ans_field mt-3">
                            <Form.Control
                              as="textarea"
                              rows={5}
                              placeholder=""
                            />
                          </div>
                          <div className="mt-3">
                            <Button
                              className="cmn-linearBtn SubmitBtn"
                              onClick={() => handleAnsSubmit(index)}
                            >
                              Submit
                            </Button>
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </DashLayout>
  );
};

export default QaSeller;
