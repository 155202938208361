import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { FaArrowDown } from "react-icons/fa6";
import { FaArrowRight } from "react-icons/fa6";
import sign1 from "../Assets/Images/sign1.svg";
import bnb from "../Assets/Images/bnb.png";
import ProductPriceSummary from "./ProductPriceSummary";
import mobile from "../Assets/Images/mobile.png";
import headphone from "../Assets/Images/headphone.png";
import joystick from "../Assets/Images/Joystick.png";
import checked from "../Assets/Images/checked.png";
import reload from "../Assets/Images/reload.png";
import ProductDiscountSummary from "./ProductDiscountSummary";
import ShippingAddress from "./ShippingAddress";
import { Link } from "react-router-dom";

function ProductSummary() {
  const [showPrice, setShowPrice] = useState(false);
  const [showDiscount, setShowDiscount] = useState(false);
  const [showShipping, setShowShipping] = useState(false);
  const shoppingProduct = [
    {
      id: 1,
      img: mobile,
      name: "Lenovo IdeaPad Slim 1 Intel Celeron Dual Core N4020- (8 GB/256 GB SSD/Windows 11 Home) 14IGL7 Thin and Light Laptop (14 Inch, Cloud Grey, 1.3 Kg, With MS Office)",
    },
    {
      id: 2,
      img: headphone,
      name: "Lenovo IdeaPad Slim 1 Intel Celeron Dual Core N4020- (8 GB/256 GB SSD/Windows 11 Home) 14IGL7 Thin and Light Laptop (14 Inch, Cloud Grey, 1.3 Kg, With MS Office)",
    },
    {
      id: 3,
      img: joystick,
      name: "Lenovo IdeaPad Slim 1 Intel Celeron Dual Core N4020- (8 GB/256 GB SSD/Windows 11 Home) 14IGL7 Thin and Light Laptop (14 Inch, Cloud Grey, 1.3 Kg, With MS Office)",
    },
  ];
  const discountProduct = [
    {
      id: 1,
    },
  ];
  return (
    <div className="product_summary">
      <div className="heading_txt">Summary Details :</div>
      <div className="p-3">
        <div className="mb-0 priceDetails">
          <div className="price">
            <div className="info">
              <p className="mb-0 cmnTxt">
                {/* <img src={checked} className="img-fluid checkedImg" /> */}
                Price (4 Items )
                <Button
                  className="arrow"
                  onClick={() => setShowPrice(!showPrice)}
                >
                  {showPrice ? (
                    <FaArrowDown size={8} />
                  ) : (
                    <FaArrowRight size={8} />
                  )}
                </Button>{" "}
              </p>
              <div className="amount">
                <span className="coin">
                  <span>0.15</span>
                  <img src={bnb} className="img-fluid coinImg" />
                  <span>BNB</span>
                </span>
                <img src={sign1} className="img-fluid signImg" />
                <div className="d-flex align-items-center gap-2 mx-1">
                  <span className="new-amt">$108.42</span>
                </div>
              </div>
            </div>
            {showPrice ? (
              <div className="mt-3">
                <div className="row shoppingCart_row">
                  {shoppingProduct.map((item, index) => {
                    return (
                      <div className="col-xl-12 col">
                        <ProductPriceSummary shoppingProduct={item} />
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>

          <div className="mt-2 pt-1 discount">
            <div className="info">
              <p className="mb-0 cmnTxt">
                {/* <img src={checked} className="img-fluid checkedImg" /> */}
                Discounts
                <Button
                  className="arrow"
                  onClick={() => setShowDiscount(!showDiscount)}
                >
                  {showDiscount ? (
                    <FaArrowDown size={8} />
                  ) : (
                    <FaArrowRight size={8} />
                  )}
                </Button>{" "}
              </p>
              <div className="amount">
                <span className="coin">
                  <span>0.15</span>
                  <img src={bnb} className="img-fluid coinImg" />
                  <span>BNB</span>
                </span>
                <img src={sign1} className="img-fluid signImg" />
                <div className="d-flex align-items-center gap-2 mx-1">
                  <span className="new-amt">$108.42</span>
                </div>
              </div>
            </div>
            {showDiscount ? (
              <div className="mt-2">
                <div className="row shoppingCart_row">
                  {discountProduct.map((item, index) => {
                    return (
                      <div className="col-xl-12 col">
                        <ProductDiscountSummary productDiscount={item} />
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>

          <div className="mt-2 pt-1 shipping">
            <div className="info">
              <p className="mb-0 cmnTxt">
                {/* <img src={checked} className="img-fluid checkedImg" /> */}
                Shippings
                <Button
                  className="arrow"
                  onClick={() => setShowShipping(!showShipping)}
                >
                  {showShipping ? (
                    <FaArrowDown size={8} />
                  ) : (
                    <FaArrowRight size={8} />
                  )}
                </Button>{" "}
              </p>
              <div className="amount">
                <span className="coin">
                  <span>0.15</span>
                  <img src={bnb} className="img-fluid coinImg" />
                  <span>BNB</span>
                </span>
                <img src={sign1} className="img-fluid signImg" />
                <div className="d-flex align-items-center gap-2 mx-1">
                  <span className="new-amt">$108.42</span>
                </div>
              </div>
            </div>
            {showShipping ? (
              <div className="mt-2">
                <div className="row shoppingCart_row">
                  <div className="col-xl-12 col">
                    <ShippingAddress />
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>

          <div className="mt-2 pt-1 total">
            <div className="info">
              <p className="mb-0 cmnTxt">
                {/* <img src={checked} className="img-fluid checkedImg" /> */}
                Total
              </p>
              <div className="amount">
                <span className="coin">
                  <span>0.15</span>
                  <img src={bnb} className="img-fluid coinImg" />
                  <span>BNB</span>
                </span>
                <img src={sign1} className="img-fluid signImg" />
                <div className="d-flex align-items-center gap-2 mx-1">
                  <span className="new-amt">$108.42</span>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-2 pt-1 blockchain">
            <div className="info">
              <p className="mb-0 cmnTxt">
                {/* <img src={checked} className="img-fluid checkedImg" /> */}
                Blockchain
                <img src={reload} className="img-fluid reloadImg" />
              </p>
              <div className="amount">
                <span className="coin">
                  <img src={bnb} className="img-fluid coinImg" />
                  <span>BSC - Binance Smart chain</span>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="btns mt-3 pt-2">
          <Button className="shopMore_btn">Shop More</Button>
          <Link to="/checkout" className="text-decoraion-none">
            <Button className="checkout_btn cmn_greenBtn">Checkout</Button>
          </Link>
        </div>
        <p className="mt-3 mb-0 freeDelivery_eligible">
          You order is eligible for Free delivery
          <img src={checked} alt="checked" className="checkedImg ms-2" />
        </p>
      </div>
    </div>
  );
}

export default ProductSummary;
