import React from "react";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import banner1 from "../Assets/Images/Best_Dealz/banner1.png";
import banner2 from "../Assets/Images/Best_Dealz/banner2.png";
import banner3 from "../Assets/Images/Best_Dealz/banner3.png";
import BestdealzCard from "./BestdealzCard";
import MostPopularCard from "./MostPopularCard";

function MostPopular() {
  const options = {
    loop: true,
    // center: true,
    items: 3.5,
    margin: 15,
    autoplay: false,
    dots: false,
    autoplayTimeout: 8500,
    smartSpeed: 450,
    // nav: true,
    responsive: {
      0: {
        items: 1,
      },
      480: {
        items: 1.5,
      },
      576: {
        items: 1.5,
      },
      768: {
        items: 2.5,
      },
      1200: {
        items: 3.5,
      },
      1800: {
        items: 4.5,
      },
    },
  };

  const MostPopular = [
    {
      id: 1,
      Name: "Travel Essentials For You",
    },
    {
      id: 2,
      Name: "Most Loved Gifts",
    },
    {
      id: 3,
      Name: "Season Top's Picks",
    },
    {
      id: 4,
      Name: "Travel Essentials For You",
    },
    {
      id: 5,
      Name: "Most Loved Gifts",
    },
    {
      id: 6,
      Name: "Season Top's Picks",
    },
  ];

  return (
    <div className="most_popular">
      <div className="mostPopular_container container">
        <div className="cmnHeading_sec">
          <p className="mb-0 heading_txt">Most Popular</p>
          <Link to="/" className="text-decoration-none">
            <p className="mb-0 see-all">See all</p>
          </Link>
        </div>
      </div>
      <div className="mostPopularCard">
        <div className="container">
          <OwlCarousel
            id="customer-testimonoals"
            className="owl-carousel owl-theme"
            {...options}
          >
            {MostPopular.map((item, index) => {
              return <MostPopularCard items={item} />;
            })}
          </OwlCarousel>
        </div>
      </div>
    </div>
  );
}

export default MostPopular;
