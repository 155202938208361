import React from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import closeBtn from "../../Assets/Images/modal_close.svg";
import { FaChevronDown } from "react-icons/fa";
import warning from "../../Assets/Images/warning.png";
import logo from "../../Assets/Images/smlLogo.svg";
import seller from "../../Assets/Images/seller.svg";
import customer from "../../Assets/Images/customer.svg";
import Dropdown from "react-bootstrap/Dropdown";
import country1 from "../../Assets/Images/india.svg";
import country2 from "../../Assets/Images/US.svg";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import { CiSearch } from "react-icons/ci";
function ChoosenCountryModal({ show, handleClose, handleSelectCountryShow }) {
  return (
    <div>
      <Modal
        show={show}
        // onHide={handleClose}
        centered
        size="sm"
        className="common_Modal_Popup choosenCountry_modal"
      >
        <Modal.Body>
          <Button className="close_btn" onClick={handleClose}>
            <img src={closeBtn} className="img-fluid" />
          </Button>
          <div className="content-Sec">
            <div className="header-section mb-0">
              <img src={warning} className="img-fluid warningImg" />
              <p className="mb-0 txt">
                The chosen place of residence is not supported by seller
              </p>
            </div>
            <div className="btn_sec mt-2">
              <Button
                className="cancelBtn cmn_grayBtn mt-4"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                className="try_anotherBtn cmn_linearBtn mt-4"
                onClick={handleSelectCountryShow}
              >
                Try another place
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ChoosenCountryModal;
