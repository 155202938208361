// import package

import React, { useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Select from "react-select";
import ReactDatatable from "@ashvin27/react-datatable";

// import local
import { Image } from "../data/Images";
import DashLayout from "../Components/DashboardLayout/DashLayout";
import WishlistCard from "../Components/WishlistCard";

const Wishlists = () => {
  const FeaturedProduct = [
    {
      id: 1,
      Img: Image.joystick,
      Name: "Smart Watch for Android",
    },
    {
      id: 2,
      Img: Image.hometheatre,
      Name: "Smart Watch for Android",
    },
    {
      id: 3,
      Img: Image.headphone,
      Name: "Smart Watch for Android",
    },
  ];
  const [networks, setNetworks] = useState([
    {
      img: Image.bsc,
      name: "BSC",
    },
    {
      img: Image.bsc,
      name: "BSC",
    },
    {
      img: Image.bsc,
      name: "BSC",
    },
    {
      img: Image.bsc,
      name: "BSC",
    },
    {
      img: Image.bsc,
      name: "BSC",
    },
    {
      img: Image.bsc,
      name: "BSC",
    },
  ]);
  const [selected, setSelected] = useState({
    value: "Select Coin",
    label: "Select Coin",
    img: Image.bsc,
  });

  const [wishlistDetails, setWishlistsDetails] = useState([
    {
      id: 1,
      img: Image.watch,
      productName: "Noise smart Watch",
      originalPrice: "210.00",
      discountedPrice: "200.00",
    },
    {
      id: 2,
      img: Image.tv,
      productName: "Smart TV",
      originalPrice: "400.00",
      discountedPrice: "350.00",
    },
    {
      id: 3,
      img: Image.shoe,
      productName: "Nike Shoe",
      originalPrice: "500.00",
      discountedPrice: "400.00",
    },
    {
      id: 4,
      img: Image.mobile,
      productName: "Mobile Phone",
      originalPrice: "210.00",
      discountedPrice: "200.00",
    },
    {
      id: 5,
      img: Image.joystick,
      productName: "Gaming Joystick",
      originalPrice: "210.00",
      discountedPrice: "200.00",
    },
    {
      id: 6,
      img: Image.shoe,
      productName: "Nike Shoe",
      originalPrice: "210.00",
      discountedPrice: "200.00",
    },
    {
      id: 7,
      img: Image.tv,
      productName: "Smart TV",
      originalPrice: "400.00",
      discountedPrice: "350.00",
    },
    {
      id: 8,
      img: Image.headphone,
      productName: "Headphone",
      originalPrice: "500.00",
      discountedPrice: "400.00",
    },
    {
      id: 9,
      img: Image.coupon1,
      productName: "Mobile Phone",
      originalPrice: "210.00",
      discountedPrice: "200.00",
    },
    {
      id: 10,
      img: Image.watch,
      productName: "Noise smart Watch",
      originalPrice: "210.00",
      discountedPrice: "200.00",
    },
  ]);
  const [selectedNetwork, setSelectedNetwork] = useState();
  const [closer, setCloser] = useState();
  const [selectedOption, setSelectedOption] = useState(null);
  const coinRef = useRef();

  const columns = [
    {
      key: "invoice",
      text: "Invoice",
      className: "w_150",
      align: "start",
      sortable: true,
      cell: (record, index) => (
        <div className="d-flex align-items-center justify-content-start gap-3">
          <input type="checkbox" />
          <p className="mb-0">{record.invoicenumber}</p>
        </div>
      ),
    },
    {
      key: "seller",
      text: "Seller",
      className: "w_150",
      align: "center",
      sortable: false,
      cell: (record, index) => {
        return <p className="mb-0 text-center">{record.seller}</p>;
      },
    },
    {
      key: "products",
      text: "Products",
      className: "w_250",
      align: "start",
      sortable: false,
      cell: (record, index) => {
        return (
          <div className="d-flex align-items-start justify-content-start gap-3">
            <div className="table_image_wrapper_sm">
              <img
                src={record.img}
                alt={record.productName}
                className="img_container_fit"
              />
            </div>
            <p className="mb-0">{record.productName}</p>
          </div>
        );
      },
    },
    {
      key: "price",
      text: "Price",
      className: "w_300",
      align: "center",
      sortable: false,
      cell: (record) => {
        return (
          <div className="d-flex align-items-center gap-2 justify-content-center">
            <p className="mb-0">{record.priceFrom}</p>
            <div className="table_image_wrapper_xs d-flex align-items-center justify-content-center">
              <img src={Image.coin1} alt="coin" className="img_container_fit" />
            </div>
            <p className="mb-0">BNB</p>
            <p className="mb-0">~</p>
            <p className="mb-0">$ {record.priceTo}</p>
          </div>
        );
      },
    },
    {
      key: "blockchain",
      text: "Block Chain",
      className: "w_300",
      align: "center",
      sortable: false,
      cell: (record) => {
        return (
          <div className="d-flex align-items-center justify-content-center gap-2">
            <div className="table_image_wrapper_xs d-flex align-items-center justify-content-center">
              <img src={Image.coin1} alt="coin" className="img_container_fit" />
            </div>
            <p className="mb-0">{record.blockChain}</p>
          </div>
        );
      },
    },

    {
      key: "status",
      text: "Status",
      className: "activity",
      align: "center",
      sortable: false,
      cell: (record) => {
        return (
          <div
            className={`status_badge d-flex align-items-center justify-content-center ${
              record.status === "Completed"
                ? "green"
                : record.status === "Open"
                ? "pink"
                : record.status === "On Hold"
                ? "red"
                : record.status === "Process"
                ? "purple"
                : ""
            }`}
          >
            {record.status}
          </div>
        );
      },
    },
  ];
  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Emailtemplates",
    no_data_text: "No Email Templates found!",
    language: {
      length_menu: "Show MENU result per page",
      filter: "Filter in records...",
      info: "Showing START to END of TOTAL records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: false,
    show_filter: false,
    show_pagination: false,
    show_info: false,
  };
  const extraButtons = [
    {
      className: "btn btn-primary buttons-pdf",
      title: "Export TEst",
      children: [
        <span>
          <i
            className="glyphicon glyphicon-print fa fa-print"
            aria-hidden="true"
          ></i>
        </span>,
      ],
      onClick: (event) => {
        console.log(event);
      },
    },
    {
      className: "btn btn-primary buttons-pdf",
      title: "Export TEst",
      children: [
        <span>
          <i
            className="glyphicon glyphicon-print fa fa-print"
            aria-hidden="true"
          ></i>
        </span>,
      ],
      onClick: (event) => {
        console.log(event);
      },
      onDoubleClick: (event) => {
        console.log("doubleClick");
      },
    },
  ];

  const customOptionRenderer = ({ label, data, value }) => (
    <div
      className="market_select_component"
      onClick={() => {
        setSelected({ label, value, img: data.img });
        coinRef.current.blur();
        setCloser(!closer);
      }}
    >
      {console.log("sdhguisd", label, data, value)}
      <img src={data.img} style={{ width: "20px", marginRight: "10px" }} />
      {label}
    </div>
  );

  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];
  return (
    <DashLayout>
      <div className="my-4">
        <h5 className="overview__title">Wishlists</h5>
        <div className="coupon_body_card p-3">
          <div className="row">
            {wishlistDetails.map((item) => (
              <div className="col-sm-6 col-md-4 col-xl-3 col-xxxl-2_5 mb-4">
                <WishlistCard item={item} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </DashLayout>
  );
};

export default Wishlists;
