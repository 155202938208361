import React, { useState ,useEffect } from "react";
import SpecialProductCard from "../Components/SpecialProductCard";
import afterBar from "../Assets/Images/productList_after.svg";
import powerbank from "../Assets/Images/power_bank.png";
import mobile from "../Assets/Images/mobile.png";
import PopularSalesCard from "../Components/PopularSalesCard";
import airbuts from "../Assets/Images/airbots.png";
import bike from "../Assets/Images/bike.png";
import headphone from "../Assets/Images/headphone.png";
import joystick from "../Assets/Images/Joystick.png";
import shoe from "../Assets/Images/shoe.png";
import watch from "../Assets/Images/watch1.png";
import { Link } from "react-router-dom";
import Header from "../Components/Layout/Header";
import Footer from "../Components/Layout/Footer";

import homeTheatre from "../Assets/Images/home_theatre.png";
import tv from "../Assets/Images/tv.png";
import CartHeader from "../Components/CartHeader";
import ShoppingCart from "../Components/ShoppingCart";
import ProductSummary from "../Components/ProductSummary";

function AddToCart() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div className="">
      <Header />
      <CartHeader />
      <div className="addToCart mt-4">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-7 col-lg-7 col-xl-8 mb-3">
              <ShoppingCart />
            </div>
            <div className="col-12 col-sm-8 col-md-5 col-lg-5 col-xl-4 mb-3">
              <ProductSummary /> 
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default AddToCart;
