import React from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import closeBtn from "../../Assets/Images/modal_close.svg";
import Form from "react-bootstrap/Form";
import logo from "../../Assets/Images/smlLogo.svg";
import seller from "../../Assets/Images/seller.svg";
import customer from "../../Assets/Images/customer.svg";
import bsc from "../../Assets/Images/bsc.png";
import { Link } from "react-router-dom";
import ReactFlagsSelect from "react-flags-select";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
function AddAddressModal({ show, handleClose }) {
  const [select, setSelect] = useState("IN");
  const [phoneNumber, setPhoneNumber] = useState("");
  const onSelect = (code) => setSelect(code);
  return (
    <div>
      <Modal
        show={show}
        // onHide={handleClose}
        centered
        size="md"
        className="common_Modal_Popup address_modal"
      >
        <Modal.Body>
          <Button className="close_btn" onClick={handleClose}>
            <img src={closeBtn} className="img-fluid" />
          </Button>

          <div className="content">
            <p className="title-txt fw-500 mb-0">Add new address</p>
            <div className="row mt-3">
              <div className="col-12 col-sm-6 mb-3">
                <div className="address_info">
                  <Form.Group
                    className=""
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Name</Form.Label>
                    <Form.Control type="text" placeholder="Enter Name" />
                  </Form.Group>
                </div>
              </div>

              <div className="col-12 col-sm-6 mb-3">
                <div className="address_info">
                  <Form.Label>Country / Region</Form.Label>
                  <ReactFlagsSelect
                    selected={select}
                    onSelect={onSelect}
                    countries={["IN", "GB", "IE", "IT", "NL", "SE"]}
                    placeholder="India"
                    className="country-name-input"
                    name="country"
                  />
                </div>
              </div>

              <div className="col-12 col-sm-6 mb-3">
                <div className="address_info">
                  <Form.Group
                    className=""
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Mobile Number</Form.Label>
                    <PhoneInput
                      country={"ae"}
                      // onChange={(phone) => this.setState({ phone })}
                      className="country-mobile-input"
                      value={phoneNumber}
                      name="phoneNumber"
                    />
                  </Form.Group>
                </div>
              </div>

              <div className="col-12 col-sm-6 mb-3">
                <div className="address_info">
                  <Form.Group
                    className=""
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Locality</Form.Label>
                    <Form.Control type="text" placeholder="Enter Locality" />
                  </Form.Group>
                </div>
              </div>

              <div className="col-lg-12 mb-3">
                <div className="address_info">
                  <Form.Group
                    className=""
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Address</Form.Label>
                    <Form.Control as="textarea" rows={4} />
                  </Form.Group>
                </div>
              </div>

              <div className="col-12 col-sm-6 mb-3">
                <div className="address_info">
                  <Form.Group
                    className=""
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>City</Form.Label>
                    <Form.Control type="text" placeholder="Enter City" />
                  </Form.Group>
                </div>
              </div>

              <div className="col-12 col-sm-6 mb-3">
                <div className="address_info">
                  <Form.Group
                    className=""
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>State</Form.Label>
                    <Form.Control type="text" placeholder="Enter State" />
                  </Form.Group>
                </div>
              </div>

              <div className="col-12 col-sm-6 mb-3 mb-sm-0">
                <div className="address_info">
                  <Form.Group
                    className=""
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Zipcode</Form.Label>
                    <Form.Control type="text" placeholder="Enter Zipcode" />
                  </Form.Group>
                </div>
              </div>

              <div className="col-12 col-sm-6">
                <div className="address_info">
                  <Form.Group
                    className=""
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Optional Field</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Optional Field"
                    />
                  </Form.Group>
                </div>
              </div>
            </div>
            <div className="mt-3 commonRadio_btn">
              <Form.Check
                type="radio"
                label="Set as default address"
                id="Set as default address"
              />
            </div>
            <div className="btns mt-4 mt-lg-4 mt-xxl-5">
              <Button className="cancelBtn cmn_grayBtn">Cancel</Button>
              <Button className="cnfmBtn cmn_linearBtn">Confirm</Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default AddAddressModal;
