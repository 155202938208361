import React from "react";
import bigImg from "../Assets/Images/new-released/bigImg.png";
import smlImg1 from "../Assets/Images/new-released/smlImg1.png";
import smlImg2 from "../Assets/Images/new-released/smlImg2.png";
import halfImg from "../Assets/Images/new-released/halfImg.png";
import { Link } from "react-router-dom";
function NewReleased() {
  return (
    <div className="new-released">
      <div className="newReleased_container container">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-6 fst_and_lst_pd pe-lg-0">
            <Link to="/" className="text-decoration-none">
              <div className="lft_bigImg">
                <img src={bigImg} alt="img" className="img-fluid" />
              </div>
            </Link>
          </div>
          {/* <div className="col-12 col-sm-10 col-md-10 col-lg-5 col-xl-5 col">
            <div className="row">
              <div className="col-12 col-sm-6 col-md-6 col-xl-6 col">
                <Link to="/" className="text-decoration-none">
                  <div className="ctr_smlImg">
                    <img src={smlImg1} alt="img" className="img-fluid" />
                  </div>
                </Link>
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-xl-6 col">
                <Link to="/" className="text-decoration-none">
                  <div className="ctr_smlImg">
                    <img src={smlImg2} alt="img" className="img-fluid" />
                  </div>
                </Link>
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-xl-6 col">
                <Link to="/" className="text-decoration-none">
                  <div className="ctr_smlImg">
                    <img src={smlImg2} alt="img" className="img-fluid" />
                  </div>
                </Link>
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-xl-6 col">
                <Link to="/" className="text-decoration-none">
                  <div className="ctr_smlImg">
                    <img src={smlImg1} alt="img" className="img-fluid" />
                  </div>
                </Link>
              </div>
            </div>
          </div> */}
          <div className="col-12 col-sm-6 pe-sm-0 col-md-6 col-lg-3 fst_and_lst_pd ps-lg-0 pe-lg-0">
            <Link to="/" className="text-decoration-none">
              <div className="ryt_halfImg">
                <img src={smlImg1} alt="img" className="img-fluid" />
              </div>
            </Link>
          </div>
          <div className="col-12 col-sm-6 ps-sm-0 col-md-6 col-lg-3 fst_and_lst_pd ps-lg-0">
            <Link to="/" className="text-decoration-none">
              <div className="ryt_halfImg">
                <img src={smlImg2} alt="img" className="img-fluid" />
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewReleased;
