import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { FaArrowDown } from "react-icons/fa6";
import { FaArrowRight } from "react-icons/fa6";
import sign1 from "../Assets/Images/sign1.svg";
import bnb from "../Assets/Images/bnb.png";
import ProductPriceSummary from "./ProductPriceSummary";
import mobile from "../Assets/Images/mobile.png";
import headphone from "../Assets/Images/headphone.png";
import joystick from "../Assets/Images/Joystick.png";
import checked from "../Assets/Images/checked.png";
import reload from "../Assets/Images/reload.png";
import account from "../Assets/Images/accimg.png";
import shopping from "../Assets/Images/shopping3.png";
import ProductDiscountSummary from "./ProductDiscountSummary";
import ShippingAddress from "./ShippingAddress";
import Lottie from "lottie-react";
import lock from "../Assets/Json/lock.json";
import { FaQuestion } from "react-icons/fa";
import { Link } from "react-router-dom";
import { FiInfo } from "react-icons/fi";
import PaymentCompleted from "../Assets/Json/paymentComplete.json";
import ConnectWalletModal from "./Modals/ConnectWalletModal";
function OrderDetails() {
  const [showPrice, setShowPrice] = useState(false);
  const [showDiscount, setShowDiscount] = useState(false);
  const [showShipping, setShowShipping] = useState(false);
  const [connectWallet, setConnectWallet] = useState(false);
  const [walletConnect, setWalletConnect] = useState(false);

  useEffect(() => {
    const loc = localStorage.getItem("walletConnect");
    if (loc) {
      setWalletConnect(true);
    } else {
      setWalletConnect(false);
    }
  }, [connectWallet]);

  // console.log("walletConnect", walletConnect);

  const shoppingProduct = [
    {
      id: 1,
      img: mobile,
      name: "Lenovo IdeaPad Slim 1 Intel Celeron Dual Core N4020- (8 GB/256 GB SSD/Windows 11 Home) 14IGL7 Thin and Light Laptop (14 Inch, Cloud Grey, 1.3 Kg, With MS Office)",
    },
    {
      id: 2,
      img: headphone,
      name: "Lenovo IdeaPad Slim 1 Intel Celeron Dual Core N4020- (8 GB/256 GB SSD/Windows 11 Home) 14IGL7 Thin and Light Laptop (14 Inch, Cloud Grey, 1.3 Kg, With MS Office)",
    },
    {
      id: 3,
      img: joystick,
      name: "Lenovo IdeaPad Slim 1 Intel Celeron Dual Core N4020- (8 GB/256 GB SSD/Windows 11 Home) 14IGL7 Thin and Light Laptop (14 Inch, Cloud Grey, 1.3 Kg, With MS Office)",
    },
  ];
  const discountProduct = [
    {
      id: 1,
    },
  ];

  const handleConnectWalletShow = () => {
    setConnectWallet(true);
  };

  const handleConnectWalletClose = () => {
    setConnectWallet(false);
  };

  return (
    <>
      {connectWallet && (
        <ConnectWalletModal
          show={connectWallet}
          handleClose={handleConnectWalletClose}
        />
      )}
      <div className="order_details">
        <div className="product_summary">
          {/* <div className="heading_txt">Summary Details :</div> */}
          <div className="head_sec">
            <img src={shopping} className="img-fluid shoppingImg" />
            Order Details
          </div>
          <div className="details_sec">
            {walletConnect ? (
              <div className="after_walletConnect">
                <div className="payment_completed_lottie">
                  <Lottie
                    animationData={PaymentCompleted}
                    className="payComp_lottie"
                    loop={true}
                  />
                </div>
                <div className="payment_completed_cnt">
                  <p className="mb-2 title-txt">Payment Completed</p>
                  <p className="des mb-0">
                    Thank you for shopping with Bitdealz Your order is
                    successfully completed !
                  </p>
                </div>
              </div>
            ) : (
              <div className="before_walletConnect">
                <div className="mb-0 priceDetails p-3">
                  <Button className="priceLocked_btn mt-1 mb-3">
                    <span></span>Price Locked : 01:00
                    <FiInfo size={16} />
                  </Button>
                  <div className="pt-2">
                    <div className="price">
                      <div className="info">
                        <p className="mb-0 cmnTxt">
                          <img src={checked} className="img-fluid checkedImg" />
                          Price (4 Items )
                          <Button
                            className="arrow"
                            onClick={() => setShowPrice(!showPrice)}
                          >
                            {showPrice ? (
                              <FaArrowDown size={8} />
                            ) : (
                              <FaArrowRight size={8} />
                            )}
                          </Button>{" "}
                        </p>
                        <div className="amount">
                          <span className="coin">
                            <span>0.15</span>
                            <img src={bnb} className="img-fluid coinImg" />
                            <span>BNB</span>
                          </span>
                          <img src={sign1} className="img-fluid signImg" />
                          <div className="d-flex align-items-center gap-2 mx-1">
                            <span className="new-amt">$108.42</span>
                          </div>
                        </div>
                      </div>
                      {showPrice ? (
                        <div className="mt-3">
                          <div className="row shoppingCart_row">
                            {shoppingProduct.map((item, index) => {
                              return (
                                <div className="col-xl-12 col">
                                  <ProductPriceSummary shoppingProduct={item} />
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>

                    <div className="mt-2 pt-1 discount">
                      <div className="info">
                        <p className="mb-0 cmnTxt">
                          <img src={checked} className="img-fluid checkedImg" />
                          Discounts
                          <Button
                            className="arrow"
                            onClick={() => setShowDiscount(!showDiscount)}
                          >
                            {showDiscount ? (
                              <FaArrowDown size={8} />
                            ) : (
                              <FaArrowRight size={8} />
                            )}
                          </Button>{" "}
                        </p>
                        <div className="amount">- $10</div>
                      </div>
                      {showDiscount ? (
                        <div className="mt-2">
                          <div className="row shoppingCart_row">
                            {discountProduct.map((item, index) => {
                              return (
                                <div className="col-xl-12 col">
                                  <ProductDiscountSummary
                                    productDiscount={item}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>

                    <div className="mt-2 pt-1 shipping">
                      <div className="info">
                        <p className="mb-0 cmnTxt">
                          <img src={checked} className="img-fluid checkedImg" />
                          Shippings
                          <Button
                            className="arrow"
                            onClick={() => setShowShipping(!showShipping)}
                          >
                            {showShipping ? (
                              <FaArrowDown size={8} />
                            ) : (
                              <FaArrowRight size={8} />
                            )}
                          </Button>{" "}
                        </p>
                        <div className="amount">- $10</div>
                      </div>
                      {showShipping ? (
                        <div className="mt-2">
                          <div className="row shoppingCart_row">
                            <div className="col-xl-12 col">
                              <ShippingAddress />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>

                    <div className="mt-2 pt-1 total">
                      <div className="info">
                        <p className="mb-0 cmnTxt">
                          <img src={checked} className="img-fluid checkedImg" />
                          Total
                        </p>
                        <div className="amount">- $10</div>
                      </div>
                    </div>

                    <div className="mt-2 pt-1 blockchain">
                      <div className="info">
                        <p className="mb-0 cmnTxt">
                          <img src={checked} className="img-fluid checkedImg" />
                          Blockchain
                          <img src={reload} className="img-fluid reloadImg" />
                        </p>
                        <div className="amount">
                          <span className="coin">
                            <img src={bnb} className="img-fluid coinImg" />
                            <span>BSC - Binance Smart chain</span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="btns mt-3 pt-2 px-3">
                  <Button
                    className="shopMore_btn"
                    onClick={handleConnectWalletShow}
                  >
                    Escrow Lock
                  </Button>
                  <Button
                    className="checkout_btn cmn_greenBtn"
                    onClick={handleConnectWalletShow}
                  >
                    Place Order
                  </Button>
                </div>
                <p className="mt-3 mb-0 freeDelivery_eligible">
                  You order is eligible for Free delivery
                  <img
                    src={checked}
                    alt="checked"
                    className="checkedImg ms-2"
                  />
                </p>
                <div className="position-relative py-2 ps-2">
                  <div className="escrow_lock">
                    <Lottie animationData={lock} className="lock_lottie" />
                    <div className="lock_dropdown">
                      <a href="#" className="a">
                        {" "}
                        Escrow Lock{" "}
                        <Button className="ques">
                          <FaQuestion size={7} />
                        </Button>
                      </a>
                      <div className="hidden_info dropdown-content">
                        <p className="heading_txtt mb-0">
                          Info for customers :
                        </p>
                        <div className="info_details mt-1">
                          Escrow Lock protect customer's cryptocurrency from
                          frauds and gives ability for easy re-claim back of
                          your funds. Check more info here.
                        </div>
                        <p className="more-info mb-0 mt-1">
                          Click more info.
                          <Link to=""> here</Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <p className="soldBy mb-0 py-2 ps-3">
                  Sold by : <Button className="btn-link">Current Store</Button>
                </p>
              </div>
            )}

            <div className="account">
              <p className="acc-txt mb-0">Account :</p>
              <div className="mt-1 d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <img
                    src={account}
                    alt="acc"
                    className="img-fluid accImg me-2"
                  />
                  <span className="acc_Dec">ueuriuava77w7w979ww77w9e79we8</span>
                </div>
                <Button className="arrowBtn">
                  <FaArrowRight size={8} />
                </Button>{" "}
              </div>
            </div>
          </div>
          <div className="foot_sec">
            <p className="mb-0">Total Payment : 0.129</p>
            <div className="amount">
              <span className="coin">
                <img src={bnb} className="img-fluid coinImg" />
                <span>BNB</span>
              </span>
              <img src={sign1} className="img-fluid signImg" />
              <div className="d-flex align-items-center gap-2 mx-1">
                <span className="new-amt">$108.42</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OrderDetails;
