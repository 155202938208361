import React from 'react'
import { Link } from 'react-router-dom';
import FeatureProductCard from './FeatureProductCard';
import { Image } from '../data/Images';

const BottomTopCards = () => {
    const FeaturedProduct = [
        {
          id: 1,
          Img: Image.joystick,
          Name: "Smart Watch for Android",
        },
        {
          id: 2,
          Img: Image.hometheatre,
          Name: "Smart Watch for Android",
        },
        {
          id: 3,
          Img: Image.headphone,
          Name: "Smart Watch for Android",
        },
      ];
  return (
    <>
    <div className="mt-5">
            <h5 className="overview__title">Top Products</h5>

            <div className="card_sec mt-4">
              <div className="row">
                {FeaturedProduct.map((item, index) => {
                  return (
                    <div className="col-12 col-sm-6 col-md-6 col-lg-4  col-xxl-4 col mb-4">
                      <Link to="/" className="text-decoration-none">
                        <FeatureProductCard items={item} />
                      </Link>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
    </>
  )
}

export default BottomTopCards