import React from "react";
import { FaLocationDot } from "react-icons/fa6";
import { IoIosArrowForward } from "react-icons/io";
import { Button } from "react-bootstrap";
import ShoppingCartProduct from "./ShoppingCartProduct";
import sign1 from "../Assets/Images/sign1.svg";
import bnb from "../Assets/Images/bnb.png";
import mobile from "../Assets/Images/mobile.png";
import headphone from "../Assets/Images/headphone.png";
import joystick from "../Assets/Images/Joystick.png";
function ShoppingCart() {
  const shoppingProduct = [
    {
      id: 1,
      img: mobile,
      name: "Lenovo IdeaPad Slim 1 Intel Celeron Dual Core N4020- (8 GB/256 GB SSD/Windows 11 Home) 14IGL7 Thin and Light Laptop (14 Inch, Cloud Grey, 1.3 Kg, With MS Office)",
    },
    {
      id: 2,
      img: headphone,
      name: "Lenovo IdeaPad Slim 1 Intel Celeron Dual Core N4020- (8 GB/256 GB SSD/Windows 11 Home) 14IGL7 Thin and Light Laptop (14 Inch, Cloud Grey, 1.3 Kg, With MS Office)",
    },
    {
      id: 3,
      img: joystick,
      name: "Lenovo IdeaPad Slim 1 Intel Celeron Dual Core N4020- (8 GB/256 GB SSD/Windows 11 Home) 14IGL7 Thin and Light Laptop (14 Inch, Cloud Grey, 1.3 Kg, With MS Office)",
    },
  ];
  return (
    <div className="shopping_Cart">
      <div className="shipHeading">
        <span>Shopping cart :</span>
        <Button className="locationBtn">
          <FaLocationDot />
          India
          <IoIosArrowForward />
        </Button>
      </div>
      <div className="p-3">
        <div className="row shoppingCart_row">
          {shoppingProduct.map((item, index) => {
            return (
              <div className="col-12 col-sm-12 col-xl-12 col">
                <ShoppingCartProduct shoppingProduct={item} />
              </div>
            );
          })}
        </div>
      </div>
      <div className="subTotal">
        <p className="mb-0">Sub Total (4 items ) :</p>
        <div className="amount ms-2">
          <span className="coin">
            <span>0.15</span>
            <img src={bnb} className="img-fluid coinImg mx-1" />
            <span>BNB</span>
          </span>
          <img src={sign1} className="img-fluid signImg" />
          <div className="d-flex align-items-center gap-2 mx-1">
            <span className="new-amt">$108.42</span>
          </div>
        </div>
      </div>
      <div className="promocode">
        <input
          type="text"
          className="promocodeInp"
          placeholder="Enter Promocode"
        />
        <Button className="discountBtn cmn_greenBtn">Get Discount</Button>
      </div>
    </div>
  );
}

export default ShoppingCart;
