import React from "react";
import { Link } from "react-router-dom";
import fb from "../../Assets/Images/socialIcons/fb.svg";
import linkedin from "../../Assets/Images/socialIcons/linkedin.svg";
import twitter from "../../Assets/Images/socialIcons/twitter.svg";
import yt from "../../Assets/Images/socialIcons/yt.svg";

function Footer() {
  return (
    <div className="footer">
      <div className="pre_footer">
        <div className="container">
          <div className="content_sec">
            <div>
              <p className="mb-0 heading_txt">Customer Area</p>
              <div className="sub_content">
                <Link to="/" className="mb-0 txt">
                  Your Account
                </Link>
                <Link to="/" className="mb-0 txt">
                  Refund policy
                </Link>
                <Link to="/" className="mb-0 txt">
                  Digital Policy
                </Link>
                <Link to="/" className="mb-0 txt">
                  Shipping Policy
                </Link>
                <Link to="/" className="mb-0 txt">
                  Customer FAQ
                </Link>
                <Link to="/" className="mb-0 txt">
                  Search Stores
                </Link>
              </div>
            </div>
            <div>
              <p className="mb-0 heading_txt">Seller Area</p>
              <div className="sub_content">
                <Link to="/" className="mb-0 txt">
                  Login
                </Link>
                <Link to="/" className="mb-0 txt">
                  Register
                </Link>
                <Link to="/" className="mb-0 txt">
                  Seller FAQ
                </Link>
              </div>
            </div>
            <div>
              <p className="mb-0 heading_txt">Affiliate Area</p>
              <div className="sub_content">
                <Link to="/" className="mb-0 txt">
                  Login
                </Link>
                <Link to="/" className="mb-0 txt">
                  Register
                </Link>
                <Link to="/" className="mb-0 txt">
                  Earnings
                </Link>
              </div>
            </div>
            <div>
              <p className="mb-0 heading_txt">About Us</p>
              <div className="sub_content">
                <Link to="/" className="mb-0 txt">
                  Bitdealz Brand
                </Link>
                <Link to="/" className="mb-0 txt">
                  Introduction
                </Link>
                <Link to="/" className="mb-0 txt">
                  How it works
                </Link>
                <Link to="/" className="mb-0 txt">
                  Contact Us
                </Link>
              </div>
            </div>
            <div>
              <p className="mb-0 heading_txt">Services</p>
              <div className="sub_content">
                <Link to="/" className="mb-0 txt">
                  Payment Gateway
                </Link>
                <Link to="/" className="mb-0 txt">
                  Coin Market Trends
                </Link>
                <Link to="/" className="mb-0 txt">
                  DeFi Exchange
                </Link>
                <Link to="/" className="mb-0 txt">
                  Defi Games
                </Link>
                <Link to="/" className="mb-0 txt">
                  Defi Wallet
                </Link>
                <Link to="/" className="mb-0 txt">
                  Defi Escrow
                </Link>

                <Link to="/" className="mb-0 txt">
                  Movies & TV
                </Link>
                <Link to="/" className="mb-0 txt">
                  Crypto Currency
                </Link>
                <Link to="/" className="mb-0 txt">
                  NFT Marketplace
                </Link>
                <Link to="/" className="mb-0 txt">
                  Defi Video Marketplace
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr className="cus_hr" />
      <div className="post_footer">
        <div className="container">
          <div className="content_secs">
            <p className="copyRight mb-0">
              @ 2024.BitDealz.All Rights Reserved
            </p>
            <div className="sub_contents">
              <Link to="/" className="mb-0 txt">
                Privacy
              </Link>
              <Link to="/" className="mb-0 txt">
                Terms
              </Link>
              <Link to="/" className="mb-0 txt">
                Disclaimer
              </Link>
            </div>
            <div className="social_icons">
              <a href="#" target="_blank">
                <img src={fb} className="icons" />
              </a>
              <a href="#" target="_blank">
                <img src={linkedin} className="icons" />
              </a>
              <a href="#" target="_blank">
                <img src={twitter} className="icons" />
              </a>
              <a href="#" target="_blank">
                <img src={yt} className="icons" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
