import React, { useState } from "react";
import SpecialProductCard from "../Components/SpecialProductCard";
import afterBar from "../Assets/Images/productList_after.svg";
import powerbank from "../Assets/Images/power_bank.png";
import mobile from "../Assets/Images/mobile.png";
import PopularSalesCard from "../Components/PopularSalesCard";
import airbuts from "../Assets/Images/airbots.png";
import bike from "../Assets/Images/bike.png";
import headphone from "../Assets/Images/headphone.png";
import joystick from "../Assets/Images/Joystick.png";
import shoe from "../Assets/Images/shoe.png";
import watch from "../Assets/Images/watch1.png";
import { Link } from "react-router-dom";
import Header from "../Components/Layout/Header";
import Footer from "../Components/Layout/Footer";

import homeTheatre from "../Assets/Images/home_theatre.png";
import tv from "../Assets/Images/tv.png";

function TodaysDeal() {
  const [specialProducts, setSpecialProducts] = useState("best-deals");

  const handleActive = (val) => {
    setSpecialProducts(val);
  };

  const SpecialProducts = [
    {
      id: 1,
      type: "Power Banks",
      name: "Power Banks 1130 Mah",
      img: powerbank,
    },
    {
      id: 2,
      type: "Power Banks",
      name: "Power Banks 1130 Mah",
      img: mobile,
    },
    {
      id: 3,
      type: "Power Banks",
      name: "Power Banks 1130 Mah",
      img: airbuts,
    },
    {
      id: 4,
      type: "Power Banks",
      name: "Power Banks 1130 Mah",
      img: bike,
    },
    {
      id: 5,
      type: "Power Banks",
      name: "Power Banks 1130 Mah",
      img: headphone,
    },
    {
      id: 6,
      type: "Power Banks",
      name: "Power Banks 1130 Mah",
      img: joystick,
    },

    {
      id: 7,
      type: "Power Banks",
      name: "Power Banks 1130 Mah",
      img: shoe,
    },
    {
      id: 8,
      type: "Power Banks",
      name: "Power Banks 1130 Mah",
      img: watch,
    },

    {
      id: 9,
      type: "Power Banks",
      name: "Power Banks 1130 Mah",
      img: powerbank,
    },
    {
      id: 10,
      type: "Power Banks",
      name: "Power Banks 1130 Mah",
      img: mobile,
    },
    {
      id: 11,
      type: "Power Banks",
      name: "Power Banks 1130 Mah",
      img: airbuts,
    },
    {
      id: 12,
      type: "Power Banks",
      name: "Power Banks 1130 Mah",
      img: bike,
    },
  ];

  const PopularSales = [
    {
      id: 1,
      Img: homeTheatre,
      type: "Home Theatre",
      modal: "Power Banks 1130 Mah",
    },
    {
      id: 2,
      Img: mobile,
      type: "Oppo Reno 11",
      modal: "Power Banks 1130 Mah",
    },
    {
      id: 3,
      Img: bike,
      type: "Ninja Z2000",
      modal: "Power Banks 1130 Mah",
    },
    {
      id: 4,
      Img: tv,
      type: "Sony Z40",
      modal: "Power Banks 1130 Mah",
    },
    {
      id: 5,
      Img: homeTheatre,
      type: "Home Theatre",
      modal: "Power Banks 1130 Mah",
    },
    {
      id: 6,
      Img: mobile,
      type: "Oppo Reno 11",
      modal: "Power Banks 1130 Mah",
    },
    {
      id: 7,
      Img: bike,
      type: "Ninja Z2000",
      modal: "Power Banks 1130 Mah",
    },
    {
      id: 8,
      Img: tv,
      type: "Sony Z40",
      modal: "Power Banks 1130 Mah",
    },
  ];

  return (
    <div className="">
      <Header />
      <div className="specialProducts todaysDeal_pg">
        <div className="container">
          <div className="cmnHeading_sec">
            <p className="mb-0 heading_txt">Today's Deals</p>
          </div>
          <div className="SplProd_Tabs mt-3">
            <div className="values_list">
              <div
                className={
                  specialProducts == "best-deals" ? "items active" : "items"
                }
                onClick={() => handleActive("best-deals")}
              >
                <span> Best Dealz </span>
              </div>
              <div
                className={
                  specialProducts == "tv-radio" ? "items active" : "items"
                }
                onClick={() => handleActive("tv-radio")}
              >
                <span>Tv & Radio</span>
              </div>
              <div
                className={
                  specialProducts == "smartphone" ? "items active" : "items"
                }
                onClick={() => handleActive("smartphone")}
              >
                <span>Smartphone</span>
              </div>
              <div
                className={
                  specialProducts == "audio" ? "items active" : "items"
                }
                onClick={() => handleActive("audio")}
              >
                <span>Audio</span>
              </div>
              <div
                className={
                  specialProducts == "camera" ? "items active" : "items"
                }
                onClick={() => handleActive("camera")}
              >
                <span>Camera</span>
              </div>
              <div
                className={
                  specialProducts == "watch" ? "items active" : "items"
                }
                onClick={() => handleActive("watch")}
              >
                <span>Smart watches</span>
              </div>
              <div
                className={
                  specialProducts == "headphone" ? "items active" : "items"
                }
                onClick={() => handleActive("headphone")}
              >
                <span>Headphones</span>
              </div>
            </div>
          </div>
          <div className="row mt-sm-3 mt-4">
            {specialProducts == "best-deals" ? (
              <>
                {SpecialProducts.map((item, index) => {
                  return (
                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-2 mb-3">
                      <Link to="/" className="text-decoration-none">
                        <SpecialProductCard items={item} />
                      </Link>
                    </div>
                  );
                })}
              </>
            ) : (
              <>
                {SpecialProducts.map((item, index) => {
                  return (
                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-2 mb-3">
                      <Link to="/" className="text-decoration-none">
                        <SpecialProductCard items={item} />
                      </Link>
                    </div>
                  );
                })}
              </>
            )}
          </div>
          <div className="related-products">
            <div className="cmnHeading_sec">
              <p className="mb-0 heading_txt">Related Products</p>
              <Link to="/" className="text-decoration-none">
                <p className="mb-0 see-all">See all</p>
              </Link>
            </div>
            <div className="relatedProductsCard">
              <div className="row">
                {PopularSales.map((item, index) => {
                  return (
                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-2 col">
                      <PopularSalesCard items={item} />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default TodaysDeal;
