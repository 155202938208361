// import npm

import React from "react";
import { IoCloseCircleOutline } from "react-icons/io5";

const WishlistCard = (props) => {
  const { id, productName, originalPrice, img, discountedPrice } = props.item;
  console.log("propsss", productName);
  return (
    <div className="wishlist_card" key={id}>
      <div className="wishlist_top ">
        <div className="d-flex align-items-center justify-content-end">
          <button className="border-0 outline-0 bg-transparent">
            <IoCloseCircleOutline color="#f34841" fontSize={20} />
          </button>
        </div>
        <div className="d-flex justify-content-center mt-2 mt-xxl-3">
          {" "}
          <div className="wish_image_wrap">
            <img src={img} alt={productName} className="img_container_fit" />
          </div>
        </div>

        <div className="mt-4 d-flex flex-column align-items-center">
          <p className="overview__title mb-1">{productName}</p>
          <div className="d-flex align-items-center">
            <p className=" mb-0">
              <del className="strike_gray_text">${originalPrice}</del>
              <span className="orange_text_sm"> ${discountedPrice}</span>
            </p>
            {/* <p className="ms-2"> */}
          </div>
        </div>
      </div>
      <button className="wishlist__greenBtn w-100">Go to Cart</button>
    </div>
  );
};

export default WishlistCard;
