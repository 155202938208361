import React from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import closeBtn from "../../Assets/Images/modal_close.svg";

import signUpWallet from "../../Assets/Images/passkey.png";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import PinInput from "react-pin-input";
function ResetPasskeyModal({ show, handleClose }) {
  const [pin, setPin] = useState("");

  const handleComplete = (value) => {
    setPin(value);
  };
  return (
    <div>
      <Modal
        show={show}
        // onHide={handleClose}
        centered
        size="md"
        className="common_Modal_Popup signUp_modal"
      >
        <Modal.Body>
          <Button className="close_btn" onClick={handleClose}>
            <img src={closeBtn} className="img-fluid" />
          </Button>

          <div className="content">
            <div className="head_sec text-center">
              <p className="name mb-0">Reset Passkey</p>
              <img src={signUpWallet} className="signUpWallet img-fluid my-3" />
              <p className="passkey_protect mb-0 mt-4">Reset Passkey</p>
              <p className="create-passkey mb-0 mt-1">Enter 6 Digit Passkey</p>
            </div>
            <div className="mt-4 passkey_inp">
            <PinInput
                length={6}
                initialValue=''
                secret
                secretDelay={100}
                onChange={(value, index) => {}}
                type="numeric"
                inputMode="number"
               
                // style={{ padding: "10px" }}
                // inputStyle={{ borderColor: "red" }}
                // inputFocusStyle={{ borderColor: "blue" }}
                onComplete={handleComplete}
                // autoSelect={true}
                // regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
              />
            </div>

            <p className="mt-5 mb-0 text-center">
              <Button
                className="confirmBtn cmn_linearBtn"
                // onClick={CnfmSignupPasskeyShow}
              >
                Confirm
              </Button>
            </p>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ResetPasskeyModal;
