// import package

import React, { useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Select from "react-select";
import ReactDatatable from "@ashvin27/react-datatable";

// import local
import { Image } from "../data/Images";
import DashLayout from "../Components/DashboardLayout/DashLayout";
import OrderStatus from "../table/OrderStatus";
import FeatureProductCard from "../Components/FeatureProductCard";
import { Link } from "react-router-dom";
import BottomTopCards from "../Components/BottomTopCards";
import WalletAndNetwork from "../Components/WalletAndNetwork";
import EscrowLock from "../Components/EscrowLock";

const Orders = () => {
  const [tableRecords, setTableRecords] = useState([
    {
      id: 1,
      invoicenumber: 123456,
      seller: "Current Store",
      img: Image.headphone,
      productName: "Headphone",
      priceFrom: "0.129",
      priceTo: "103.42",
      blockChain: "Binance Smart Coin",
      deliverystatus: "Completed",
      status: "Process",
    },
    {
      id: 2,
      invoicenumber: 123457,
      seller: "Current Store",
      img: Image.hometheatre,
      productName: "Lenovo Home Theatre",
      priceFrom: "0.129",
      priceTo: "103.42",
      blockChain: "Binance Smart Coin",
      deliverystatus: "Completed",
      status: "Open",
    },
    {
      id: 3,
      invoicenumber: 123458,
      seller: "Current Store",
      img: Image.joystick,
      productName: "Gaming Joystick",
      priceFrom: "0.129",
      priceTo: "103.42",
      blockChain: "Binance Smart Coin",
      deliverystatus: "Completed",
      status: "On Hold",
    },
    {
      id: 4,
      invoicenumber: 123459,
      seller: "Current Store",
      img: Image.headphone,
      productName: "Headphone",
      priceFrom: "0.129",
      priceTo: "103.42",
      blockChain: "Binance Smart Coin",
      deliverystatus: "Completed",
      status: "On Hold",
    },
    {
      id: 5,
      invoicenumber: 123460,
      seller: "Current Store",
      img: Image.hometheatre,
      productName: "Lenovo Home Theatre",
      priceFrom: "0.129",
      priceTo: "103.42",
      blockChain: "Binance Smart Coin",
      deliverystatus: "Completed",
      status: "Completed",
    },
    {
      id: 6,
      invoicenumber: 123461,
      seller: "Current Store",
      img: Image.joystick,
      productName: "Gaming Joystick",
      priceFrom: "0.129",
      priceTo: "103.42",
      blockChain: "Binance Smart Coin",
      deliverystatus: "Completed",
      status: "Process",
    },
  ]);

  const columns = [
    {
      key: "invoice",
      text: "Invoice",
      className: "w_150",
      align: "start",
      sortable: true,
      cell: (record, index) => (
        <div className="d-flex align-items-center justify-content-start gap-3">
          <input type="checkbox" />
          <p className="mb-0">{record.invoicenumber}</p>
        </div>
      ),
    },
    {
      key: "seller",
      text: "Seller",
      className: "w_150",
      align: "center",
      sortable: false,
      cell: (record, index) => {
        return <p className="mb-0 text-center">{record.seller}</p>;
      },
    },
    {
      key: "products",
      text: "Products",
      className: "w_250",
      align: "start",
      sortable: false,
      cell: (record, index) => {
        return (
          <div className="d-flex align-items-start justify-content-start gap-3">
            <div className="table_image_wrapper_sm">
              <img
                src={record.img}
                alt={record.productName}
                className="img_container_fit"
              />
            </div>
            <p className="mb-0">{record.productName}</p>
          </div>
        );
      },
    },
    {
      key: "price",
      text: "Price",
      className: "w_250",
      align: "center",
      sortable: false,
      cell: (record) => {
        return (
          <div className="d-flex align-items-center gap-2 justify-content-center">
            <p className="mb-0">{record.priceFrom}</p>
            <div className="table_image_wrapper_xs d-flex align-items-center justify-content-center">
              <img src={Image.coin1} alt="coin" className="img_container_fit" />
            </div>
            <p className="mb-0">BNB</p>
            <p className="mb-0">~</p>
            <p className="mb-0">$ {record.priceTo}</p>
          </div>
        );
      },
    },
    {
      key: "blockchain",
      text: "Block Chain",
      className: "w_250",
      align: "center",
      sortable: false,
      cell: (record) => {
        return (
          <div className="d-flex align-items-center justify-content-center gap-2">
            <div className="table_image_wrapper_xs d-flex align-items-center justify-content-center">
              <img src={Image.coin1} alt="coin" className="img_container_fit" />
            </div>
            <p className="mb-0">{record.blockChain}</p>
          </div>
        );
      },
    },
    {
      key: "deliverystatus",
      text: "Delivery Status",
      className: "w_150",
      align: "center",
      sortable: false,
      cell: (record) => {
        return (
          <div className="d-flex align-items-center justify-content-center gap-2">
            <p className="mb-0">{record.deliverystatus}</p>
            <button className="border-0 outline-0 bg-transparent">
              <img src={Image.link} alt="link" className="img-fluid" />
            </button>
          </div>
        );
      },
    },

    {
      key: "action",
      text: "Action",
      className: "w_300",
      align: "center",
      sortable: false,
      cell: (record) => {
        return (
          // <div
          //   className={`status_badge d-flex align-items-center justify-content-center ${
          //     record.status === "Completed"
          //       ? "green"
          //       : record.status === "Open"
          //       ? "pink"
          //       : record.status === "On Hold"
          //       ? "red"
          //       : record.status === "Process"
          //       ? "purple"
          //       : ""
          //   }`}
          // >
          //   {record.status}
          // </div>
          <div className="d-flex align-items-center justify-content-center gap-2">
            <button className="status_badge green2">Escrow</button>
            <button className="status_badge orange">Refund</button>
            <button className="status_badge blue">Seller contact</button>
          </div>
        );
      },
    },
  ];
  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Emailtemplates",
    no_data_text: "No Email Templates found!",
    language: {
      length_menu: "Show MENU result per page",
      filter: "Filter in records...",
      info: "Showing START to END of TOTAL records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: false,
    show_filter: false,
    show_pagination: false,
    show_info: false,
  };
  const extraButtons = [
    {
      className: "btn btn-primary buttons-pdf",
      title: "Export TEst",
      children: [
        <span>
          <i
            className="glyphicon glyphicon-print fa fa-print"
            aria-hidden="true"
          ></i>
        </span>,
      ],
      onClick: (event) => {
        console.log(event);
      },
    },
    {
      className: "btn btn-primary buttons-pdf",
      title: "Export TEst",
      children: [
        <span>
          <i
            className="glyphicon glyphicon-print fa fa-print"
            aria-hidden="true"
          ></i>
        </span>,
      ],
      onClick: (event) => {
        console.log(event);
      },
      onDoubleClick: (event) => {
        console.log("doubleClick");
      },
    },
  ];

  return (
    <DashLayout>
      <div className="my-4">
        <h5 className="overview__title">Order Status</h5>
        <div className="table_card mt-4">
          <div className="table_card_header p-2 p-sm-3 p-md-4 d-flex flex-wrap gap-4 align-items-center justify-content-between">
            <div className="d-flex flex-wrap  align-items-center gap-3">
              <button className="green_btn">Add</button>
              <button className="gray_rect_btn">
                <img
                  src={Image.deleteIcon}
                  alt="delete"
                  className="img-fluid"
                  style={{ width: "20px" }}
                />
              </button>
              <button className="gray_rect_btn">
                {" "}
                <img
                  src={Image.infoIcon}
                  alt="info"
                  className="img-fluid"
                  style={{ width: "15px" }}
                />
              </button>
              <button className="gray_rect_btn">
                {" "}
                <img
                  src={Image.docsIcon}
                  alt="docs"
                  className="img-fluid"
                  style={{ width: "13px" }}
                />
              </button>
            </div>
            <div>
              <input type="text" placeholder="Search" className="gray_input" />
            </div>
          </div>
          <div className="custom_table">
            <ReactDatatable
              config={config}
              records={tableRecords}
              columns={columns}
              // extraButtons={extraButtons}
            />
          </div>
        </div>

        <BottomTopCards />
      </div>
    </DashLayout>
  );
};

export default Orders;
