import React from "react";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import banner1 from "../Assets/Images/Best_Dealz/banner1.png";
import banner2 from "../Assets/Images/Best_Dealz/banner2.png";
import banner3 from "../Assets/Images/Best_Dealz/banner3.png";
import BestdealzCard from "./BestdealzCard";

function BestDealz() {
  const options = {
    loop: true,
    // center: true,
    items: 3.5,
    margin: 15,
    autoplay: false,
    dots: false,
    autoplayTimeout: 8500,
    smartSpeed: 450,
    // nav: true,
    responsive: {
      0: {
        items: 1,
      },
      480: {
        items: 1.5,
      },
      576: {
        items: 1.5,
      },
      768: {
        items: 2.5,
      },
      1200: {
        items: 3.5,
      },
      1800: {
        items: 3.5,
      },
    },
  };

  const BestDealz = [
    {
      id: 1,
      BgImg: banner1,
      Name: "Smart Watch",
      Type: "Android",
    },
    {
      id: 2,
      BgImg: banner2,
      Name: "Smart Watch",
      Type: "Android",
    },
    {
      id: 3,
      BgImg: banner1,
      Name: "Smart Watch",
      Type: "Android",
    },
    {
      id: 4,
      BgImg: banner2,
      Name: "Smart Watch",
      Type: "Android",
    },
    {
      id: 5,
      BgImg: banner1,
      Name: "Smart Watch",
      Type: "Android",
    },
    {
      id: 6,
      BgImg: banner2,
      Name: "Smart Watch",
      Type: "Android",
    },
  ];

  return (
    <div className="best_dealz">
      <div className="bestDealz_container container">
        <div className="cmnHeading_sec">
          <p className="mb-0 heading_txt">Best Deals</p>
          <Link to="/" className="text-decoration-none">
            <p className="mb-0 see-all">See all</p>
          </Link>
        </div>
      </div>
      <div className="best_dealz_card">
        <div className="container">
          <OwlCarousel
            id="customer-testimonoals"
            className="owl-carousel owl-theme todayDelaz_owlCarousal"
            {...options}
          >
            {BestDealz.map((item, index) => {
              return <BestdealzCard items={item} />;
            })}
          </OwlCarousel>
        </div>
      </div>
    </div>
  );
}

export default BestDealz;
