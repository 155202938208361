import React from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import closeBtn from "../../Assets/Images/modal_close.svg";

import logo from "../../Assets/Images/smlLogo.svg";
import seller from "../../Assets/Images/seller.svg";
import customer from "../../Assets/Images/customer.svg";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
function BitdealzMarketPlaceModal({
  show,
  handleClose,
  handleAccountSignupShow,
}) {
  const [acc, setAcc] = useState("customer");

  return (
    <div>
      <Modal
        show={show}
        // onHide={handleClose}
        centered
        size="md"
        className="common_Modal_Popup bitdealz_marketplace_modal"
      >
        <Modal.Body>
          <Button className="close_btn" onClick={handleClose}>
            <img src={closeBtn} className="img-fluid" />
          </Button>

          <div className="content">
            <div className="head_sec text-center">
              <img src={logo} className="logo img-fluid" />
              <p className="name mb-0 mt-2">Bitdealz Marketplace</p>
            </div>
            <div className="type">
              <Button
                className={acc == "customer" ? "active account" : "account"}
                onClick={() => setAcc("customer")}
              >
                <span className="acc-img">
                  <img src={customer} className="img-fluid" />
                </span>
                <span className="txt">Customer Account</span>
              </Button>
              <Button
                className={
                  acc == "seller" ? "active account mt-4" : "account mt-4"
                }
                onClick={() => setAcc("seller")}
              >
                <span className="acc-img">
                  <img src={seller} className="img-fluid" />
                </span>
                <span className="txt">Seller Account</span>
              </Button>
            </div>
            <div className="mt-4 cmn_checkbox">
              <Form.Check type="checkbox" id="checkbox" />
              <label>
                I agree to <Link to="/privacy-policy">Privacy Policy</Link> and
                <Link to="/terms-conditions"> Terms & Conditions</Link>
              </label>
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <Button className="signupBtn" onClick={handleAccountSignupShow}>
                SignUp
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default BitdealzMarketPlaceModal;
