// import package

import React, { useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Select from "react-select";
import ReactDatatable from "@ashvin27/react-datatable";
import Form from "react-bootstrap/Form";
// import local
import { Image } from "../data/Images";
import DashLayout from "../Components/DashboardLayout/DashLayout";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import DeleteProducts from "../Components/Modals/DeleteProducts";

const Products = () => {
  const [deleteProduct, setDeleteProduct] = useState(false);

  const [tableRecords, setTableRecords] = useState([
    {
      id: 1,
      productId: "0zjas45d013sf3drt",
      image: Image.productImg,
      name: "Hopper Jetpro",
      category: "Cycle",
      listingStatus: "active",
      price: "10000",
      stocks: "10",
      status: "approved",
    },
    {
      id: 2,
      productId: "0zjas45d013sf3drt",
      image: Image.productImg,
      name: "Hopper Jetpro",
      category: "Cycle",
      listingStatus: "inactive",
      price: "10000",
      stocks: "10",
      status: "pending",
    },
    {
      id: 3,
      productId: "0zjas45d013sf3drt",
      image: Image.productImg,
      name: "Hopper Jetpro",
      category: "Cycle",
      listingStatus: "active",
      price: "10000",
      stocks: "10",
      status: "reject",
    },
  ]);

  const columns = [
    {
      key: "productId",
      text: "Product ID",
      className: "w_150",
      align: "start",
      sortable: true,
      cell: (record, index) => (
        <div className="d-flex align-items-center justify-content-start gap-3">
          {/* <div className="cmn_checkBox"> */}
          <Form.Check type="checkbox" id="checkbox" className="cmn_checkBox" />
          {/* </div> */}
          <p className="mb-0">{record.productId}</p>
        </div>
      ),
    },
    {
      key: "logo",
      text: "Store Logo",
      className: "w_150",
      align: "start",
      sortable: false,
      cell: (record, index) => {
        return (
          <div className="product_Tbl_logo">
            <img src={record.image} className="img-fluid" />
          </div>
        );
      },
    },
    {
      key: "name",
      text: "Product Name",
      className: "w_150",
      align: "start",
      sortable: false,
    },
    {
      key: "category",
      text: "Category",
      className: "",
      align: "start",
      sortable: false,
    },
    {
      key: "listingStatus",
      text: "Listing Status",
      className: "product_Tbl_status w_150",
      align: "start",
      sortable: false,
      cell: (record) => {
        return (
          <div className="btn_list text-center">
            <button
              className={
                record.listingStatus == "active" ? "active" : "inactive"
              }
            >
              {record.listingStatus == "active" ? "Active" : "In Active"}
            </button>
          </div>
        );
      },
    },
    {
      key: "price",
      text: "Price",
      className: "",
      align: "start",
      sortable: false,
    },
    {
      key: "stocks",
      text: "Stocks",
      className: "",
      align: "start",
      sortable: false,
    },
    {
      key: "status",
      text: "Status",
      className: "product_Tbl_status",
      align: "start",
      sortable: false,
      cell: (record) => {
        return (
          <div className="btn_list">
            <button
              className={
                record.status == "approved"
                  ? "approved"
                  : record.status == "pending"
                  ? "pending"
                  : "reject"
              }
            >
              {record.status == "approved"
                ? "Approved"
                : record.status == "pending"
                ? "Pending"
                : "Reject"}
            </button>
          </div>
        );
      },
    },
    {
      key: "action",
      text: "Action",
      className: "product_Tbl_action",
      align: "center",
      sortable: false,
      cell: (record) => {
        return (
          <div className="">
            <Dropdown className="product_Tbl_action_drop">
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                <img src={Image.horizontalDot} className="img-fluid dotImg" />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item>Edit</Dropdown.Item>
                <Dropdown.Item>View</Dropdown.Item>
                <Dropdown.Item>Delete</Dropdown.Item>
                <Dropdown.Item>Quantity</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        );
      },
    },
  ];

  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Emailtemplates",
    no_data_text: "No Email Templates found!",
    language: {
      length_menu: "Show MENU result per page",
      filter: "Filter in records...",
      info: "Showing START to END of TOTAL records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: false,
    show_filter: false,
    show_pagination: false,
    show_info: false,
  };

  const handleDeleteProduct = () => {
    setDeleteProduct(true);
  };

  const handleClose = () => {
    setDeleteProduct(false);
  };

  return (
    <DashLayout>
      {/* Delete Product Modal */}
      {deleteProduct && (
        <DeleteProducts show={deleteProduct} handleClose={handleClose} />
      )}
      <div className="my-3 stores_list">
        <h5 className="overview__title">Products</h5>
        <div className="table_card mt-4">
          <div className="table_card_header p-2 p-sm-3 p-md-4 d-flex flex-wrap gap-4 align-items-center justify-content-between">
            <div className="d-flex flex-wrap  align-items-center gap-3">
              <Link to="/add-product">
                <button className="green_btn">Add</button>
              </Link>
              <button className="gray_rect_btn" onClick={handleDeleteProduct}>
                <img
                  src={Image.deleteIcon}
                  alt="delete"
                  className="img-fluid"
                  style={{ width: "20px" }}
                />
              </button>
              <button className="gray_rect_btn">
                {" "}
                <img
                  src={Image.infoIcon}
                  alt="info"
                  className="img-fluid"
                  style={{ width: "15px" }}
                />
              </button>
            </div>
            <div>
              <input type="text" placeholder="Search" className="gray_input" />
            </div>
          </div>
          <div className="custom_table">
            <ReactDatatable
              config={config}
              records={tableRecords}
              columns={columns}
              // extraButtons={extraButtons}
            />
          </div>
        </div>
      </div>
    </DashLayout>
  );
};

export default Products;
