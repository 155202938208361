import React from "react";
import homeTheatre from "../Assets/Images/home_theatre.png";
import watch from "../Assets/Images/watch1.png";
import airbuds from "../Assets/Images/airbots.png";
import mobile from "../Assets/Images/mobile.png";
import tv from "../Assets/Images/tv.png";
import shoe from "../Assets/Images/shoe.png";
import { Link } from "react-router-dom";
function HomeAllProduct() {
  return (
    <div className="homeAll_products">
      <div className="homeAll_product container">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 mb-2 pe-1 ps-1 ps-xl-2 pe-xl-2">
            <Link to="/" className="text-decoration-none">
              <div className="latest_products latest">
                <span className="prod_type latest">Latest</span>
                <div className="d-flex align-items-center justify-content-center">
                  <div className="img_sec">
                    <img
                      src={homeTheatre}
                      alt="home theatre"
                      className="img-fluid"
                    />
                  </div>
                </div>
                <p className="name mb-0">Home Theatre 5.1</p>
              </div>
            </Link>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
            <div className="row">
              <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mb-2 pe-1 ps-1">
                <Link to="/" className="text-decoration-none">
                  <div className="cmn_col3_sec trending">
                    <span className="prod_type">Trending</span>
                    <div className="p-3 d-flex align-items-center justify-content-center">
                      <div className="img_sec">
                        <img
                          src={watch}
                          alt="home theatre"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                    <p className="name mb-0">Smart Watches</p>
                  </div>
                </Link>
              </div>

              <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mb-2 pe-1 ps-1">
                <Link to="/" className="text-decoration-none">
                  <div className="cmn_col3_sec new">
                    <span className="prod_type">New</span>
                    <div className="p-3 d-flex align-items-center justify-content-center">
                      <div className="img_sec">
                        <img src={airbuds} alt="watch" className="img-fluid" />
                      </div>
                    </div>
                    <p className="name mb-0">Earbuds</p>
                  </div>
                </Link>
              </div>

              <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mb-2 pe-1 ps-1">
                <Link to="/" className="text-decoration-none">
                  <div className="cmn_col3_sec popular">
                    <span className="prod_type">Popular</span>
                    <div className="p-3 d-flex align-items-center justify-content-center">
                      <div className="img_sec">
                        <img
                          src={mobile}
                          alt="mobile phone"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                    <p className="name mb-0">Mobile phone</p>
                  </div>
                </Link>
              </div>

              <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mb-2 pe-1 ps-1">
                <Link to="/" className="text-decoration-none">
                  <div className="cmn_col3_sec transparent">
                    <span className="prod_type"></span>
                    <div className="p-3 d-flex align-items-center justify-content-center">
                      <div className="img_sec">
                        <img src={tv} alt="tv" className="img-fluid" />
                      </div>
                    </div>
                    <p className="name mb-0">Smart TV</p>
                  </div>
                </Link>
              </div>

              <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-2 pe-1 ps-1">
                <Link to="/" className="text-decoration-none">
                  <div className="cmn_col3_sec cmn_col6_sec transparent">
                    <span className="prod_type"></span>
                    <div className="p-3 d-flex align-items-center justify-content-center">
                      <div className="img_sec">
                        <img src={tv} alt="tv" className="img-fluid" />
                      </div>
                    </div>
                    <p className="name mb-0">Smart TV</p>
                  </div>
                </Link>
              </div>

              <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mb-2 pe-1 ps-1">
                <Link to="/" className="text-decoration-none">
                  <div className="cmn_col3_sec popular">
                    <span className="prod_type">Popular</span>
                    <div className="p-3 d-flex align-items-center justify-content-center">
                      <div className="img_sec">
                        <img src={shoe} alt="shoes" className="img-fluid" />
                      </div>
                    </div>
                    <p className="name mb-0">Shoes</p>
                  </div>
                </Link>
              </div>

              <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mb-2 pe-1 ps-1">
                <Link to="/" className="text-decoration-none">
                  <div className="cmn_col3_sec transparent">
                    <span className="prod_type"></span>
                    <div className="p-3 d-flex align-items-center justify-content-center">
                      <div className="img_sec">
                        <img src={watch} alt="watch" className="img-fluid" />
                      </div>
                    </div>
                    <p className="name mb-0">Watch</p>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeAllProduct;
