// import package

import React, { useRef, useState } from "react";
import { Button, Col, Dropdown, Row } from "react-bootstrap";
import Select from "react-select";
import Form from "react-bootstrap/Form";
// import local
import { Image } from "../data/Images";
import DashLayout from "../Components/DashboardLayout/DashLayout";
import { Link } from "react-router-dom";
import WalletAndNetwork from "../Components/WalletAndNetwork";
import EscrowLock from "../Components/EscrowLock";

const Address = () => {
  return (
    <DashLayout>
      <div className="my-4 addresspage">
        <h5 className="overview__title">Address :</h5>
        <Row className="mt-3">
          <Col xxl={8} xl={8} sm={12} className="mb-4">
            <Form>
              <Row>
                <Col lg={6} className="mb-3">
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>
                      Name <span className="spanstar">*</span>
                    </Form.Label>
                    <Form.Control type="text" placeholder="" />
                  </Form.Group>
                </Col>
                <Col lg={6} className="mb-3">
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>
                      Street <span className="spanstar">*</span>
                    </Form.Label>
                    <Form.Control type="text" placeholder="" />
                  </Form.Group>
                </Col>
                <Col lg={6} className="mb-3">
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>
                      City <span className="spanstar">*</span>
                    </Form.Label>
                    <Form.Control type="text" placeholder="" />
                  </Form.Group>
                </Col>
                <Col lg={6} className="mb-3">
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>
                      State <span className="spanstar">*</span>
                    </Form.Label>
                    <Dropdown className="dropstyle">
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        Dropdown Button
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                        <Dropdown.Item href="#/action-2">
                          Another action
                        </Dropdown.Item>
                        <Dropdown.Item href="#/action-3">
                          Something else
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Form.Group>
                </Col>
                <Col lg={6} className="mb-3">
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>
                      Zipcode <span className="spanstar">*</span>
                    </Form.Label>
                    <Form.Control type="number" placeholder="" />
                  </Form.Group>
                </Col>
                <Col lg={6} className="mb-3">
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>
                      Country <span className="spanstar">*</span>
                    </Form.Label>
                    <Dropdown className="dropstyle">
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        Dropdown Button
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                        <Dropdown.Item href="#/action-2">
                          Another action
                        </Dropdown.Item>
                        <Dropdown.Item href="#/action-3">
                          Something else
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Form.Group>
                </Col>
                <Col lg={12} className="mb-3">
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>
                      Phone <span className="spanstar">*</span>
                    </Form.Label>
                    <Form.Control type="number" placeholder="" />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={12} className="mb-3 btnrow">
                  <button className="btn cmn_cancel">Cancel</button>
                  <button className="btn cmn_linearBtn">Apply</button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </div>
    </DashLayout>
  );
};

export default Address;
