// import package

import React, { useState } from "react";
import Lottie from "lottie-react";
import { MdContentCopy, MdOutlineKeyboardArrowRight } from "react-icons/md";
import Countdown, { zeroPad } from "react-countdown";

// import local
import cutlottie from "../Assets/Json/cut.json";

const CouponCard = ({ item }) => {
  const { id, img, offPercent, couponCode, expiryTime } = item;
  console.log("expiryTime", expiryTime);
  const [coupon, setCoupon] = useState(couponCode);
  const renderer = ({ hours, minutes, seconds }) => (
    <span>
      {zeroPad(hours)}h : {zeroPad(minutes)}m : {zeroPad(seconds)}s
    </span>
  );
  return (
    <div className="coupon_card p-0 d-flex position-relative">
      <div className="coupon_left p-2 pe-0">
        <div className="coupon_img_wraper">
          <img src={img} alt={id} className="img_container_fit" />
        </div>
      </div>
      <div className="coupon_middle position-relative">
        <div className="coupon_vertical"></div>
        <div className="cut_lottie position-absolute">
          <Lottie
            animationData={cutlottie}
            className="shop_lottie"
            loop={true}
          />
        </div>
      </div>
      <div className="coupon_right pb-5 pb-sm-0">
        <p className="fw_black_sm mb-2">Flat {offPercent} Off</p>
        <p className="gray_text_sm mb-2 d-flex align-items-center">
          Code{" "}
          <span className="ms-2 d-flex align-items-center">
            : {coupon}
            <button
              className="border-0 outline-0 bg-transparent ms-1"
              onClick={() => {
                navigator.clipboard.writeText(couponCode);
              }}
            >
              <MdContentCopy fill="#616161" />
            </button>
          </span>
        </p>
        <p className="gray_text_sm">
          Expiry <span className="ms-2"> :</span>
          <span className="red_text_sm ms-1">
            <Countdown date={Date.now() + expiryTime} renderer={renderer} />
          </span>
        </p>
      </div>
      <button className="green_btn_sm">
        View Products <MdOutlineKeyboardArrowRight />
      </button>
    </div>
  );
};

export default CouponCard;
