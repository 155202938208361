import React, { useState, useRef, useEffect } from "react";
import Header from "../Components/Layout/Header";
import Footer from "../Components/Layout/Footer";
import Lottie from "lottie-react";
import bellLottie from "../Assets/Json/bell.json";
import delivery from "../Assets/Json/delivery.json";
import lock from "../Assets/Json/lock.json";
import bnb from "../Assets/Images/bnb.png";
import checked from "../Assets/Images/checked.png";
import sign1 from "../Assets/Images/sign1.svg";
import reload from "../Assets/Images/reload.png";
import star from "../Assets/Images/ratings.svg";
import buy from "../Assets/Images/buy.png";
import shop from "../Assets/Images/shop2.png";
import { PiShareFatFill } from "react-icons/pi";
import { FaQuestion } from "react-icons/fa";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import arbitrum from "../Assets/Images/arbitrum.png";
import polygon from "../Assets/Images/polygon.png";

import img1 from "../Assets/Images/WatchImages/img1.png";
import img2 from "../Assets/Images/WatchImages/img2.png";
import img3 from "../Assets/Images/WatchImages/img3.png";
import coupon from "../Assets/Images/coupon.png";

import couponBg1 from "../Assets/Images/coupons/couponBg1.png";
import couponBg2 from "../Assets/Images/coupons/couponBg2.png";
import couponBg3 from "../Assets/Images/coupons/couponBg3.png";
import couponBg4 from "../Assets/Images/coupons/couponBg4.png";

import { RxDividerVertical } from "react-icons/rx";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaArrowDown } from "react-icons/fa6";
import { FaArrowRight } from "react-icons/fa6";
import { IoIosArrowUp } from "react-icons/io";
import { FaLocationDot } from "react-icons/fa6";
import { IoIosArrowForward } from "react-icons/io";
import { FaPlus } from "react-icons/fa";
import { FaMinus } from "react-icons/fa";
import { IoIosArrowDown } from "react-icons/io";
import ReactImageMagnify from "react-image-magnify";
import ShipToModal from "../Components/Modals/ShipToModal";
import ChoosenCountryModal from "../Components/Modals/ChoosenCountryModal";
import ShareProductModal from "../Components/Modals/ShareProductsModal";
import ProductDescriptions from "../Components/ProductDescriptions";

// import WatchImg1 from "../Assets/Images/WatchImages/img1.png";
// import WatchImg2 from "../Assets/Images/WatchImages/img2.png";
// import WatchImg3 from "../Assets/Images/WatchImages/img3.png";

import WatchImg1 from "../Assets/Images/WatchImages/New/img1.jpg";
import WatchImg2 from "../Assets/Images/WatchImages/New/img2.jpg";
import WatchImg3 from "../Assets/Images/WatchImages/New/img3.jpg";
import WatchImg4 from "../Assets/Images/WatchImages/New/img4.jpg";
import WatchImg5 from "../Assets/Images/WatchImages/New/img5.jpg";
import WatchImg6 from "../Assets/Images/WatchImages/New/img6.jpg";
import WatchImg7 from "../Assets/Images/WatchImages/New/img7.jpg";
import WatchImg8 from "../Assets/Images/WatchImages/New/img8.jpg";
import WatchImg9 from "../Assets/Images/WatchImages/New/img9.jpg";
import WatchImg10 from "../Assets/Images/WatchImages/New/img10.jpg";

function ProductDetails() {
  const [itemInfo, setItemInfo] = useState(false);
  const [count, setCount] = useState(1);
  const [coupons, setCoupons] = useState(false);
  const [wishProduct, setWishProduct] = useState(false);

  const [shipTo, setShipTo] = useState(false);

  const [chooseCountry, setChooseCountry] = useState(false);
  const [socialShare, setsocialShare] = useState(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  
  const ProductPhotos = [
    {
      img: img1,
      active: true,
    },
    {
      img: img2,
    },
    {
      img: img3,
    },
    {
      img: img1,
    },
    {
      img: img2,
    },
    {
      img: img3,
    },
    {
      img: img1,
    },
    {
      img: img2,
    },
  ];

  const CouponsList = [
    {
      bgImage: couponBg1,
    },
    {
      bgImage: couponBg2,
    },
    {
      bgImage: couponBg3,
    },
    {
      bgImage: couponBg4,
    },
  ];

  const handleShipToShow = () => {
    setShipTo(true);
  };
  const handleShipToClose = () => {
    setShipTo(false);
  };

  const handleChooseCountryShow = () => {
    setShipTo(false);
    setChooseCountry(true);
  };
  const handleChooseCountryClose = () => {
    setChooseCountry(false);
  };

  const handleSelectCountryShow = () => {
    setChooseCountry(false);
    setShipTo(true);
  };

  const handleSocialShareShow = () => {
    setsocialShare(true);
  };
  const handleSocialShareClose = () => {
    setsocialShare(false);
  };

  const images = [
    WatchImg1,
    WatchImg2,
    WatchImg3,
    WatchImg4,
    WatchImg5,
    WatchImg6,
    WatchImg7,
    WatchImg8,
    WatchImg9,
    WatchImg10,
    WatchImg1,
    WatchImg2,
    WatchImg3,
    WatchImg4,
    WatchImg5,
    WatchImg6,
    WatchImg7,
    WatchImg8,
    WatchImg9,
    WatchImg10,
  ];

  const [img, setImg] = useState(images[0]);

  const hoverHandler = (image, i) => {
    setImg(image);
    refs?.current[i]?.classList?.add("active");
    for (var j = 0; j < images.length; j++) {
      if (i !== j) {
        refs?.current[j]?.classList?.remove("active");
      }
    }
  };
  const refs = useRef([]);
  refs.current = [];
  const addRefs = (el) => {
    if (el && !refs.current.includes(el)) {
      refs?.current?.push(el);
    }
  };

  const containerRef = useRef(null);

  const scrollNext = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({ top: 260, behavior: "smooth" });
    }
  };

  const scrollPrev = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({ top: -255, behavior: "smooth" });
    }
  };

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const updateDimensions = () => {
    setScreenWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const enlargedImageContainerDimensions =
    screenWidth >= 1900
      ? { width: "270%", height: "180%" }
      : screenWidth >= 1800
      ? { width: "270%", height: "180%" }
      : screenWidth >= 1400
      ? { width: "300%", height: "180%" }
      : screenWidth >= 1300
      ? { width: "300%", height: "180%" }
      : screenWidth >= 1200
      ? { width: "270%", height: "170%" }
      : screenWidth >= 992
      ? { width: "200%", height: "150%" }
      : screenWidth >= 768
      ? { width: "100%", height: "100%" }
      : screenWidth <= 767
      ? { width: "0%", height: "0%" }
      : { width: "0%", height: "0%" };

  return (
    <div className="">
      <Header />
      <div className="product_details">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-8 col-xl-9 col-xxl-9 mb-2">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-xl-5 col-xxl-5 mb-3">
                  <div className="productImg_preview">
                    <div className="allImage_list">
                      <div className="left">
                        <div className="left_1">
                          <button
                            onClick={scrollPrev}
                            // disabled={currentIndex === 0}
                            className="arrow-btn prev-arrow"
                          >
                            <IoIosArrowUp />
                          </button>
                          <div className="allImgs" ref={containerRef}>
                            {images?.map((image, i) => (
                              <div
                                className={
                                  i == 0 ? "img_wrap active" : "img_wrap"
                                }
                                key={i}
                                onMouseOver={() => hoverHandler(image, i)}
                                ref={addRefs}
                              >
                                <img src={image} alt="" />
                              </div>
                            ))}
                          </div>

                          <button
                            onClick={scrollNext}
                            // disabled={currentIndex >= images.length - 5}
                            className="arrow-btn next-arrow"
                          >
                            <IoIosArrowDown />
                          </button>
                        </div>
                        <div className="left_2">
                          <ReactImageMagnify
                            {...{
                              smallImage: {
                                alt: "Wristwatch by Ted Baker London",
                                isFluidWidth: true,
                                src: img,
                                srcSet: img,
                                // sizes:
                                //   "(max-width: 480px) 100vw, (max-width: 1200px) 30vw, 360px",
                              },
                              largeImage: {
                                src: img,
                                width: 1200,
                                height: 1800,
                              },
                              enlargedImageContainerDimensions,
                              // enlargedImageContainerDimensions: {
                              //   width: "250%",
                              //   height: "150%",
                              // },
                            }}
                          />
                        </div>
                      </div>
                      {/* <div className="right"></div> */}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-xl-7 col-xxl-7">
                  <div className="prodDetail">
                    <div className="alert_heading">
                      <Lottie
                        animationData={bellLottie}
                        className="bell_lottie"
                      />
                      FAST AND RELIABLE CHECKOUT WITH THE SECURE BLOCKCHAIN
                      NETWORKS
                    </div>
                    <div className="section1">
                      <p className="mb-0 prod-name">
                        SKMEI Men's Watch New Wheels Rolling Creative Fashion
                        Che Youhui League Fans Butterfly Double Snap Gift
                        Wristwatch - 1990
                      </p>
                      <div className="amount mt-3">
                        <span className="coin">
                          <img src={bnb} className="img-fluid coinImg" />
                          <span>0.0024</span>
                          <span>BNB</span>
                        </span>
                        <img src={sign1} className="img-fluid signImg" />
                        <div className="d-flex align-items-center gap-2 mx-1">
                          <span className="new-amt">$98.40</span>
                          <span className="old-amt">$100</span>
                        </div>
                        <img
                          src={reload}
                          className="img-fluid reloadImg ms-1"
                        />
                      </div>
                      <div className="reviewRatings mt-3">
                        <span className="rating-icons">
                          <img src={star} className="img-fluid" />
                          <img src={star} className="img-fluid" />
                          <img src={star} className="img-fluid" />
                          <img src={star} className="img-fluid" />
                          <img src={star} className="img-fluid" />
                          <span className="ratVal ms-1">4.4</span>
                        </span>
                        <RxDividerVertical className="verticalBar" />
                        <span className="review">10 Review</span>
                        <RxDividerVertical className="verticalBar" />
                        <span className="sold">35 Sold</span>
                      </div>
                      <div className="soldBy mt-3">
                        <span className="">
                          Sold by :{" "}
                          <a href="" target="_blank">
                            Current Store
                          </a>
                        </span>
                      </div>
                    </div>
                    <div className="section2">
                      <div className="heading_txt">
                        Item Info
                        <Button
                          className="arrowBtn"
                          onClick={() => setItemInfo(!itemInfo)}
                        >
                          {itemInfo ? (
                            <FaArrowDown size={8} />
                          ) : (
                            <FaArrowRight size={8} />
                          )}
                        </Button>
                      </div>
                      {itemInfo && (
                        <div className="item-info mt-2">
                          <p className="txt mb-2">
                            Lorem ipsum dolor sit amet, consetetur sadipscing
                            elitr, sed diam nonumy eirmod tempor invidunt ut
                            labore et dolore magna aliquyam erat, sed diam
                            voluptua. At vero eos et accusam et justo duo
                            dolores et ea rebum. Stet clita kasd gubergren, no
                            sea takimata sanctus est.
                          </p>
                          <p className="txt mb-0">
                            Lorem ipsum dolor sit amet, consetetur sadipscing
                            elitr, sed diam nonumy eirmod tempor invidunt ut
                            labore et dolore magna aliquyam erat, sed diam
                            voluptua. At vero eos et accusam et justo duo
                            dolores et ea rebum. Stet clita kasd gubergren, no
                            sea takimata sanctus est
                          </p>
                          <Button
                            className="closeBtn mt-2"
                            onClick={() => setItemInfo(false)}
                          >
                            {/* <FaArrowRight size={8} /> */}
                            <IoIosArrowUp size={15} />
                            Close
                          </Button>
                        </div>
                      )}
                    </div>
                    <div className="section3">
                      <p className="header-txt mb-0">Block Chains :</p>
                      <div className="block_icons mt-2">
                        <img src={bnb} className="img-fluid" />
                        <img src={arbitrum} className="img-fluid" />
                        <img src={polygon} className="img-fluid" />
                      </div>
                    </div>
                    <div className="section4">
                      <p className="header-txt mb-0 fw-500">
                        <span className="fw-600">Colour : </span>Black
                      </p>
                      <div className="mt-2 productPhotos">
                        {ProductPhotos.map((item, index) => {
                          return (
                            <div className="photo">
                              <div
                                className={
                                  item.active ? "img_sec active" : "img_sec"
                                }
                              >
                                <img src={item.img} className="img-fluid" />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div className="mt-2 info">
                        <div className="name">
                          Brand :<span className="value"> Curren</span>
                        </div>
                        <RxDividerVertical className="verticalBar mx-1" />
                        <div className="name">
                          SkU :<span className=""> 2564756456</span>
                        </div>
                        <RxDividerVertical className="verticalBar mx-1" />
                        <div className="name">
                          Category :<span className="value"> Wrist Watch</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-4">
                <ProductDescriptions />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-xl-3 col-xxl-3">
              <div className="shipAddress">
                <div className="shipHeading">
                  <span>Ship to</span>
                  <Button className="locationBtn" onClick={handleShipToShow}>
                    <FaLocationDot />
                    Ireland
                    <IoIosArrowForward />
                  </Button>
                </div>
                <div className="cmnScroll">
                  <div className="section1">
                    <div className="delivery">
                      <Lottie
                        animationData={delivery}
                        className="delivery_lottie"
                      />
                      Delivery
                    </div>

                    <div className="amount mt-2">
                      <p className="coin mb-0">
                        <img src={bnb} className="img-fluid coinImg" />
                        <span>0.0024</span>
                        <span>BNB</span>
                        <img src={checked} className="img-fluid checked" />
                      </p>
                      <img src={sign1} className="img-fluid signImg" />
                      <span className="amt-txt mb-0">$ 2.00</span>
                    </div>
                    <p className="mb-0 mt-2 delivery-date">
                      Delivery in 2 Days, Thursday
                    </p>
                  </div>
                  <div className="section2">
                    <p className="mb-0 ship-from">
                      Ship from : <span className="country">Germany</span>
                    </p>
                  </div>
                  <div className="section3">
                    <div className="mb-0 cus_protection">
                      <p className="mb-0 header-txt">Customer protection :</p>
                      <div className="position-relative">
                        <div className="escrow_lock">
                          <Lottie
                            animationData={lock}
                            className="lock_lottie"
                          />
                          <div className="lock_dropdown">
                            <a href="#" className="a">
                              {" "}
                              Escrow Lock{" "}
                              <Button className="ques">
                                <FaQuestion size={7} />
                              </Button>
                            </a>
                            <div className="hidden_info dropdown-content">
                              <p className="heading_txt mb-0">
                                Info for customers :
                              </p>
                              <div className="info_details mt-1">
                                Escrow Lock protect customer's cryptocurrency
                                from frauds and gives ability for easy re-claim
                                back of your funds. Check more info here.
                              </div>
                              <p className="more-info mb-0 mt-1">
                                Click more info.
                                <Link to=""> here</Link>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="section4">
                    <div className="d-flex align-items-center justify-content-between">
                      <p className="mb-0 header-txt">Quantity :</p>
                      <div className="d-flex align-items-center gap-2">
                        <Button
                          className="inc_dec_btn minus"
                          onClick={() => setCount(count - 1)}
                        >
                          <FaMinus />
                        </Button>
                        <span className="count">{count}</span>
                        <Button
                          className="inc_dec_btn"
                          onClick={() => setCount((item) => item + 1)}
                        >
                          <FaPlus />
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="section5">
                    <p
                      className="coupons_heading mb-0"
                      onClick={() => setCoupons(!coupons)}
                    >
                      <div className="d-flex align-items-center gap-2">
                        <img src={coupon} className="img-fluid couponImg" />
                        Explore Coupons
                      </div>
                      {coupons ? (
                        <IoIosArrowDown size={17} />
                      ) : (
                        <IoIosArrowUp size={17} />
                      )}
                    </p>
                  </div>

                  {coupons && (
                    <div className="section6">
                      <div className="couponsList">
                        {CouponsList.map((item, index) => {
                          return (
                            <div
                              className="item"
                              style={{
                                backgroundImage: `URL(${item?.bgImage})`,
                              }}
                            >
                              <p className="des mb-0">
                                Free shipping to all order when shipping fees
                                less than or equal to price
                              </p>
                              <div className="amount">
                                <p className="coin mb-0">
                                  <img
                                    src={bnb}
                                    className="img-fluid coinImg"
                                  />
                                  <span>0.0024</span>
                                  <span>BNB</span>
                                  <img
                                    src={checked}
                                    className="img-fluid checked"
                                  />
                                </p>
                                <img
                                  src={sign1}
                                  className="img-fluid signImg"
                                />
                                <span className="amt-txt mb-0">$ 2.00</span>
                              </div>
                              <div className="coupon-id">
                                <span className="address">
                                  AHJUYHG023154LOK
                                </span>
                                <Button className="cmn_linearBtn apply-btn">
                                  Apply
                                </Button>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                  <div className="section7">
                    <div className="total_price">
                      <p className="header-txt mb-0">Total Price</p>
                    </div>
                    <div className="amount">
                      <p className="coin mb-0">
                        <img src={bnb} className="img-fluid coinImg" />
                        <span>0.0024</span>
                        <span>BNB</span>
                        <img src={checked} className="img-fluid checked" />
                      </p>
                      <img src={sign1} className="img-fluid signImg" />
                      <span className="amt-txt mb-0">$ 2.00</span>
                    </div>
                  </div>
                  <div className="section8">
                    <div className="total_price">
                      <p className="header-txt mb-0">Block Chain :</p>
                    </div>
                    <div className="chain-list">
                      <p className="mb-2 list mt-2">
                        <img src={bnb} className="img-fluid" />
                        <span className="name">BSC</span>
                        <span className="">-</span>
                        <span className="full-name">Binance Smart Chain</span>
                      </p>
                      <p className="mb-2 list mt-2">
                        <img src={arbitrum} className="img-fluid" />
                        <span className="name">ARB</span>
                        <span className="">-</span>
                        <span className="full-name">ARB Block Chain</span>
                      </p>
                      <p className="mb-2 list mt-2">
                        <img src={polygon} className="img-fluid" />
                        <span className="name">Polygon</span>
                        <span className="">-</span>
                        <span className="full-name">Polygon</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="bottom_content">
                  <Link to="/checkout" className="text-decoration-none">
                    <Button className="BuyNow_btn">
                      <img src={buy} className="img-fluid" />
                      <span>Buy now</span>
                    </Button>
                  </Link>

                  <Link to="/add-to-cart" className="text-decoration-none">
                    <Button className="AddtoCart_btn cmn_linearBtn">
                      <img src={shop} className="img-fluid" />
                      <span>Add to cart</span>
                    </Button>
                  </Link>

                  <div className="shareWish_btn">
                    <Button
                      className="share_btn"
                      onClick={handleSocialShareShow}
                    >
                      <PiShareFatFill size={16} />
                      <span>Share</span>
                    </Button>
                    <Button className="wish_btn">
                      {wishProduct ? (
                        <FaHeart
                          size={16}
                          onClick={() => setWishProduct(!wishProduct)}
                          className="wished_product"
                        />
                      ) : (
                        <FaRegHeart
                          size={16}
                          onClick={() => setWishProduct(!wishProduct)}
                        />
                      )}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />

      {/* ShipTo Modal */}

      {shipTo && (
        <ShipToModal
          show={shipTo}
          handleClose={handleShipToClose}
          handleChooseCountryShow={handleChooseCountryShow}
        />
      )}
      {/* Choosed Country Modal */}
      {chooseCountry && (
        <ChoosenCountryModal
          show={chooseCountry}
          handleClose={handleChooseCountryClose}
          handleSelectCountryShow={handleSelectCountryShow}
        />
      )}

      {/* Social Media Modal */}
      {socialShare && (
        <ShareProductModal
          show={socialShare}
          handleClose={handleSocialShareClose}
        />
      )}
    </div>
  );
}

export default ProductDetails;
