// import package

import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

// import local

import "./App.css";
import "./Style.css";
import Overview from "./Pages/Overview";
import Landing from "./Pages/Landing";
import TodaysDeal from "./Pages/TodaysDeal";
import SearchProducts from "./Pages/SearchProducts";
import ProductDetails from "./Pages/ProductDetails";
import AddToCart from "./Pages/AddToCart";
import "./Style.css";
import Sample from "./Components/Sample";
import "./Aligner.css";
import Orders from "./Pages/Order";
import Escrow from "./Pages/Escrow";
import Deliveries from "./Pages/Deliveries";
import Coupons from "./Pages/Coupons";
import Wishlists from "./Pages/Wishlists";
import Checkout from "./Pages/Checkout";
import Chat from "./Pages/Chat";
import Address from "./Pages/Address";
import Notifications from "./Pages/Notifications";
import Accounts from "./Pages/Accounts";
import ShippingManagement from "./Pages/ShippingManagement";
import Stores from "./Pages/Stores";
import AddStore from "./Pages/AddStore";
import QaSeller from "./Pages/QaSeller";
import Products from "./Pages/Products";
import AddProduct from "./Pages/AddProduct";
import AddCoupon from "./Pages/AddCoupon";
function App() {
  return (
    <div className="App">
      <Routes>
        <Route exact path="/" element={<Landing />} />
        <Route exact path="/overview" element={<Overview />} />
        <Route exact path="/todays-deal" element={<TodaysDeal />} />
        <Route exact path="/search-products" element={<SearchProducts />} />
        <Route exact path="/product-details" element={<ProductDetails />} />
        <Route exact path="/add-to-cart" element={<AddToCart />} />
        <Route exact path="/todays-deal" element={<TodaysDeal />} />
        <Route exact path="/search-products" element={<SearchProducts />} />
        <Route exact path="/orders" element={<Orders />} />
        <Route exact path="/escrow" element={<Escrow />} />
        <Route exact path="/deliveries" element={<Deliveries />} />
        <Route exact path="/stores" element={<Stores />} />
        <Route exact path="/coupons" element={<Coupons />} />
        <Route exact path="/wishlists" element={<Wishlists />} />
        <Route exact path="/sample" element={<Sample />} />
        <Route exact path="/checkout" element={<Checkout />} />
        <Route exact path="/chat" element={<Chat />} />
        <Route exact path="/address" element={<Address />} />
        <Route exact path="/notifications" element={<Notifications />} />
        <Route exact path="/account" element={<Accounts />} />
        <Route
          exact
          path="/shipping-management"
          element={<ShippingManagement />}
        />
        <Route exact path="/add-store" element={<AddStore />} />
        <Route exact path="/qa-seller" element={<QaSeller />} />
        <Route exact path="/products" element={<Products />} />
        <Route exact path="/add-product" element={<AddProduct />} />
        <Route exact path="/add-coupon" element={<AddCoupon />} />

      </Routes>
    </div>
  );
}

export default App;
