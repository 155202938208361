import React from "react";
import { Link } from "react-router-dom";
import product1 from "../Assets/Images/Joystick.png";
import product2 from "../Assets/Images/mobile.png";
import product3 from "../Assets/Images/airbots.png";
import product4 from "../Assets/Images/headphone.png";
import ratings from "../Assets/Images/ratings.svg";
import { Button } from "react-bootstrap";

import BookingCollectionCard from "./BookingCollectionCard";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import book1 from "../Assets/Images/books/book1_img.png";
import book2 from "../Assets/Images/books/book2_img.png";
import book1Bg from "../Assets/Images/books/book1_bg.png";
import book2Bg from "../Assets/Images/books/book2_bg.png";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function BookCollections() {
  const BookCollections = [
    {
      id: 1,
      BgImg: book1Bg,
      Img: book1,
    },
    {
      id: 2,
      BgImg: book2Bg,
      Img: book2,
    },
    {
      id: 3,
      BgImg: book1Bg,
      Img: book1,
    },
    {
      id: 4,
      BgImg: book2Bg,
      Img: book2,
    },

    {
      id: 1,
      BgImg: book1Bg,
      Img: book1,
    },
    {
      id: 2,
      BgImg: book2Bg,
      Img: book2,
    },
    {
      id: 3,
      BgImg: book1Bg,
      Img: book1,
    },
    {
      id: 4,
      BgImg: book2Bg,
      Img: book2,
    },
  ];

  const options = {
    loop: true,
    center: true,
    items: 3.5,
    margin: 15,
    autoplay: false,
    dots: false,
    autoplayTimeout: 8500,
    smartSpeed: 450,
    nav: true,
    responsive: {
      0: {
        items: 1,
      },
      480: {
        items: 1.5,
      },
      576: {
        items: 1.5,
      },
      768: {
        items: 2.5,
      },
      1200: {
        items: 3.5,
      },
      1800: {
        items: 4.5,
      },
    },
  };

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3.5,
    slidesToScroll: 1,
    ltr: true,
    responsive: [
      {
        breakpoint: 2200,
        settings: {
          slidesToShow: 4.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1800,
        settings: {
          slidesToShow: 4.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 479,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="bookCollections">
      <div className="container">
        <div className="cmnHeading_sec">
          <p className="mb-0 heading_txt">Book Collections</p>
          <Link to="/" className="text-decoration-none">
            <p className="mb-0 see-all">See all</p>
          </Link>
        </div>
        <div className="mt-4">
          <Slider {...settings} className="bookCollections_slide">
            {BookCollections.map((item, index) => {
              return <BookingCollectionCard items={item} />;
            })}
          </Slider>
          {/* <OwlCarousel
            {...options}
            ref={carouselRef}
            className="bookCollections_slide bookCollections_slide_owl"
          >
            {BookCollections.map((item, index) => {
              return <BookingCollectionCard items={item} />;
            })}
          </OwlCarousel> */}
        </div>
      </div>
    </div>
  );
}

export default BookCollections;
