// import package

import React, { useRef, useState } from "react";
import { Button, Col, Dropdown, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
// import local
import { Image } from "../data/Images";
import DashLayout from "../Components/DashboardLayout/DashLayout";
import { Link } from "react-router-dom";
import Select from "react-select";
import GoogleMapReact from "google-map-react";
import { TbCurrentLocation } from "react-icons/tb";
const AnyReactComponent = ({ text }) => <div>{text}</div>;

const AddStore = () => {
  const [logo, setLogo] = useState("");
  const [banner, setBanner] = useState("");
  const [selectTab, setSelectTab] = useState("store");
  const [storeHours, setStoreHours] = useState([{ open: [], close: [] }]);

  const addFormFields = () => {
    setStoreHours([...storeHours, { open: [], close: [] }]);
  };

  const removeFormFields = (i) => {
    let newFormValues = [...storeHours];
    newFormValues.splice(i, 1);
    setStoreHours(newFormValues);
  };

  const handleChangeLogo = (e) => {
    setLogo(e.target.files[0]);
  };
  const handleChangeBanner = (e) => {
    setBanner(e.target.files[0]);
  };
  const OpensOptions = [
    { value: "01:00 AM", label: "01:00 AM" },
    { value: "02:00 AM", label: "02:00 AM" },
    { value: "03:00 AM", label: "03:00 AM" },
    { value: "04:00 AM", label: "04:00 AM" },
    { value: "05:00 AM", label: "05:00 AM" },
    { value: "06:00 AM", label: "06:00 AM" },
    { value: "07:00 AM", label: "07:00 AM" },
    { value: "08:00 AM", label: "08:00 AM" },
    { value: "09:00 AM", label: "09:00 AM" },
    { value: "10:00 AM", label: "10:00 AM" },
    { value: "11:00 AM", label: "11:00 AM" },
    { value: "12:00 AM", label: "12:00 AM" },
  ];
  const CloseOptions = [
    { value: "01:00 AM", label: "01:00 AM" },
    { value: "02:00 AM", label: "02:00 AM" },
    { value: "03:00 AM", label: "03:00 AM" },
    { value: "04:00 AM", label: "04:00 AM" },
    { value: "05:00 AM", label: "05:00 AM" },
    { value: "06:00 AM", label: "06:00 AM" },
    { value: "07:00 AM", label: "07:00 AM" },
    { value: "08:00 AM", label: "08:00 AM" },
    { value: "09:00 AM", label: "09:00 AM" },
    { value: "10:00 AM", label: "10:00 AM" },
    { value: "11:00 AM", label: "11:00 AM" },
    { value: "12:00 AM", label: "12:00 AM" },
  ];
  const StateOptions = [
    { value: "Tn", label: "TamilNadu" },
    { value: "Kl", label: "kerala" },
  ];
  const CountryOptions = [
    { value: "In", label: "India" },
    { value: "Aus", label: "Australia" },
  ];
  const handleSelectTab = (val) => {
    setSelectTab(val);
  };

  const defaultProps = {
    center: {
      lat: 10.99835602,
      lng: 77.01502627,
    },
    zoom: 11,
  };

  return (
    <DashLayout>
      <div className="my-4 AddStorepage">
        <div className="row mt-3">
          <div className="order-2 order-lg-1 col-lg-9 col-xl-10 mb-4">
            {selectTab == "store" ? (
              <div className="stores_details">
                <h5 className="overview__title">Store</h5>
                <div className="stores_inp mt-3">
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Store Name</Form.Label>
                    <Form.Control type="text" placeholder="" />
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput2"
                  >
                    <Form.Label>Store Slug</Form.Label>
                    <Form.Control type="text" placeholder="" />
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput3"
                  >
                    <Form.Label>Store Email</Form.Label>
                    <Form.Control type="email" placeholder="" />
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput4"
                  >
                    <Form.Label>Store Phone</Form.Label>
                    <Form.Control type="number" placeholder="" />
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput5"
                  >
                    <Form.Label>Store Banner Type</Form.Label>
                    <Form.Control type="text" placeholder="" />
                  </Form.Group>
                  <div className="row">
                    <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3 mb-3">
                      <Form.Group
                        className=""
                        controlId="exampleForm.ControlInput5"
                      >
                        <Form.Label>Store Logo</Form.Label>
                        <div className="store_logo custom_ImgPick">
                          <button class="btn">
                            {!logo ? (
                              <img
                                src={Image.emptyImg1}
                                className="emptyImg img-fluid"
                              />
                            ) : (
                              <img
                                src={URL.createObjectURL(logo)}
                                className="pickedImg img-fluid"
                              />
                            )}
                          </button>
                          <input
                            type="file"
                            name="myfile"
                            onChange={handleChangeLogo}
                          />
                        </div>
                      </Form.Group>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3 ">
                      <Form.Group
                        className=""
                        controlId="exampleForm.ControlInput5"
                      >
                        <Form.Label>Store Banner</Form.Label>
                        <div className="store_logo custom_ImgPick">
                          <button class="btn">
                            {!banner ? (
                              <img
                                src={Image.emptyImg1}
                                className="emptyImg img-fluid"
                              />
                            ) : (
                              <img
                                src={URL.createObjectURL(banner)}
                                className="pickedImg img-fluid"
                              />
                            )}
                          </button>
                          <input
                            type="file"
                            name="myfile"
                            onChange={handleChangeBanner}
                          />
                        </div>
                      </Form.Group>
                    </div>
                  </div>
                </div>
                <div className="mt-4">
                  <div className="d-flex align-items-center gap-3 cancel_nxt_Btn">
                    <Button className="btn cmn_grayBtn">Cancel</Button>
                    <Button
                      className="btn cmn-linearBtn"
                      onClick={() => handleSelectTab("hours")}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              </div>
            ) : selectTab == "hours" ? (
              <div className="Stores_hours">
                <h5 className="overview__title">Store Hours</h5>
                <div className="stores_inp mt-3">
                  {storeHours.map((item, index) => {
                    return (
                      <div className="mb-3">
                        <div className="row position-relative position-sm-unset">
                          <div className="col-11 col-sm-5 col-md-4 col-lg-4 mb-3 mb-sm-0">
                            <Form.Group
                              className=""
                              controlId="exampleForm.ControlInput21"
                            >
                              <Form.Label>Opens At</Form.Label>
                              <div className="store_time">
                                <Select
                                  classNamePrefix="storeTime_select"
                                  options={OpensOptions}
                                  defaultValue={OpensOptions[0]}
                                />
                              </div>
                            </Form.Group>
                          </div>
                          <div className="col-11 col-sm-5 col-md-4 col-lg-4">
                            <Form.Group
                              className=""
                              controlId="exampleForm.ControlInput21"
                            >
                              <Form.Label>Close At</Form.Label>
                              <div className="store_time">
                                <Select
                                  classNamePrefix="storeTime_select"
                                  options={CloseOptions}
                                  defaultValue={CloseOptions[11]}
                                />
                              </div>
                            </Form.Group>
                          </div>
                          <div className="col-1 col-sm-2 col-md-4 col-lg-4">
                            {index != 0 && storeHours.length > 1 && (
                              <div className="removeTime">
                                <img
                                  onClick={() => removeFormFields(index)}
                                  src={Image.removeLogo}
                                  className="iimg-fluid removeIcon"
                                />
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="days_list mt-3">
                          <Form.Check
                            type="checkbox"
                            id="sun"
                            label="Sun"
                            className="cmn_checkBox"
                          />
                          <Form.Check
                            type="checkbox"
                            id="mon"
                            label="Mon"
                            className="cmn_checkBox"
                          />
                          <Form.Check
                            type="checkbox"
                            id="tue"
                            label="Tue"
                            className="cmn_checkBox"
                          />
                          <Form.Check
                            type="checkbox"
                            id="wed"
                            label="Wed"
                            className="cmn_checkBox"
                          />
                          <Form.Check
                            type="checkbox"
                            id="thu"
                            label="Thu"
                            className="cmn_checkBox"
                          />
                          <Form.Check
                            type="checkbox"
                            id="fri"
                            label="Fri"
                            className="cmn_checkBox"
                          />
                          <Form.Check
                            type="checkbox"
                            id="sat"
                            label="Sat"
                            className="cmn_checkBox"
                          />
                        </div>
                      </div>
                    );
                  })}

                  <div className="add_more">
                    <Button className="" onClick={() => addFormFields()}>
                      Add More
                    </Button>
                  </div>
                </div>
                <div className="mt-4">
                  <div className="d-flex align-items-center gap-3 cancel_nxt_Btn">
                    <Button className="btn cmn_grayBtn">Cancel</Button>
                    <Button
                      className="btn cmn-linearBtn"
                      onClick={() => handleSelectTab("address")}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="stores_details">
                <h5 className="overview__title">Store Address</h5>
                <div className="stores_inp mt-3">
                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-6 mb-3 mb-lg-4">
                      <Form.Group
                        className=""
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" placeholder="" />
                      </Form.Group>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6 mb-3 mb-lg-4">
                      <Form.Group
                        className=""
                        controlId="exampleForm.ControlInput2"
                      >
                        <Form.Label>Street</Form.Label>
                        <Form.Control type="text" placeholder="" />
                      </Form.Group>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6 mb-3 mb-lg-4">
                      <Form.Group
                        className=""
                        controlId="exampleForm.ControlInput3"
                      >
                        <Form.Label>City</Form.Label>
                        <Form.Control type="text" placeholder="" />
                      </Form.Group>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6 mb-3 mb-lg-4">
                      <Form.Group
                        className=""
                        controlId="exampleForm.ControlInput3"
                      >
                        <Form.Label>State</Form.Label>
                        <Select
                          classNamePrefix="stateCountry_select"
                          options={StateOptions}
                          // defaultValue={StateOptions[1]}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6 mb-3 mb-lg-4">
                      <Form.Group
                        className=""
                        controlId="exampleForm.ControlInput3"
                      >
                        <Form.Label>Country</Form.Label>
                        <Select
                          classNamePrefix="stateCountry_select"
                          options={CountryOptions}
                          // defaultValue={OpensOptions[0]}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6 mb-3 mb-lg-4">
                      <Form.Group
                        className=""
                        controlId="exampleForm.ControlInput4"
                      >
                        <Form.Label>Zipcode</Form.Label>
                        <Form.Control type="number" placeholder="" />
                      </Form.Group>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6 mb-3 mb-lg-4">
                      <Form.Group
                        className=""
                        controlId="exampleForm.ControlInput5"
                      >
                        <Form.Label>Phone</Form.Label>
                        <Form.Control type="text" placeholder="" />
                      </Form.Group>
                    </div>
                    <div className="col-12 col-md-12 col-lg-12 mb-3 mb-lg-4">
                      <Form.Group
                        className=""
                        controlId="exampleForm.ControlInput5"
                      >
                        <Form.Label>Find on GPS</Form.Label>
                        <div className="store_currentLoc_map position-relative">
                          <div
                            style={{
                              height: "450px",
                              width: "100%",
                              overflow: "hidden",
                              borderRadius: "10px",
                            }}
                          >
                            <GoogleMapReact
                              bootstrapURLKeys={{ key: "" }}
                              defaultCenter={defaultProps.center}
                              defaultZoom={defaultProps.zoom}
                              className="custom_map"
                            >
                              <AnyReactComponent
                                lat={59.955413}
                                lng={30.337844}
                                text="My Marker"
                              />
                            </GoogleMapReact>
                          </div>
                          <div className="curr_locPick">
                            <Button className="cmn_Green_btn d-flex align-items-center gap-1">
                              <TbCurrentLocation />
                              Current location
                            </Button>
                          </div>
                        </div>
                      </Form.Group>
                    </div>
                    <div className="loc_pick_approveBtn">
                      <Button className="cancelBtn">Cancel</Button>
                      <Button className="cmn_Green_btn">Ok</Button>
                    </div>
                  </div>
                </div>
                <div className="mt-4">
                  <div className="d-flex align-items-center gap-3 cancel_nxt_Btn">
                    <Button className="cancelBtn cmn_grayBtn">Cancel</Button>
                    <Button className="btn cmn-linearBtn">Apply</Button>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="order-1 order-lg-2 mb-3 mb-lg-0 mt-0 mt-lg-4 col-lg-3 col-xl-2">
            <div className="store_ryt_tab mt-lg-2">
              <div
                className={
                  selectTab == "store"
                    ? "heading_txt active mb-2"
                    : "heading_txt mb-2"
                }
                onClick={() => handleSelectTab("store")}
              >
                <span className="activeLine"></span>
                Store
              </div>
              {/* <div
                className={
                  selectTab == "policy"
                    ? "heading_txt active mb-2"
                    : "heading_txt mb-2"
                }
                onClick={() => handleSelectTab("policy")}
              >
                <span className="activeLine"></span>
                Store Policy
              </div> */}
              <div
                className={
                  selectTab == "hours"
                    ? "heading_txt active mb-2"
                    : "heading_txt mb-2"
                }
                onClick={() => handleSelectTab("hours")}
              >
                <span className="activeLine"></span>
                Store Hours
              </div>

              <div
                className={
                  selectTab == "address" ? "heading_txt active" : "heading_txt"
                }
                onClick={() => handleSelectTab("address")}
              >
                <span className="activeLine"></span>
                Store Address
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashLayout>
  );
};

export default AddStore;
