import React from "react";
import { Link } from "react-router-dom";
import product1 from "../Assets/Images/Joystick.png";
import product2 from "../Assets/Images/mobile.png";
import product3 from "../Assets/Images/airbots.png";
import product4 from "../Assets/Images/headphone.png";
import ratings from "../Assets/Images/ratings.svg";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import TodayDealzCard from "./ProductCard1";

function TodayDealz() {
  const TodayDealz = [
    {
      id: 1,
      prodImg: product1,
      ProdName: "Lorem ipsum dolor sit amet adi pscing elitr",
      ratings: ratings,
      ProdStatus: "minus",
      ProdStatusVal: "-25%",
    },
    {
      id: 2,
      prodImg: product2,
      ProdName: "Lorem ipsum dolor sit amet adi pscing elitr",
      ratings: ratings,
      ProdStatus: "new",
      ProdStatusVal: "New",
    },
    {
      id: 3,
      prodImg: product3,
      ProdName: "Lorem ipsum dolor sit amet adi pscing elitr",
      ratings: ratings,
      ProdStatus: "minus",
      ProdStatusVal: "-25%",
    },
    {
      id: 4,
      prodImg: product4,
      ProdName: "Lorem ipsum dolor sit amet adi pscing elitr",
      ratings: ratings,
      ProdStatus: "trending",
      ProdStatusVal: "Trending",
    },
    {
      id: 5,
      prodImg: product1,
      ProdName: "Lorem ipsum dolor sit amet adi pscing elitr",
      ratings: ratings,
      ProdStatus: "minus",
      ProdStatusVal: "-25%",
    },
  ];

  const options = {
    loop: false,
    // center: true,
    items: 4,
    margin: 15,
    autoplay: false,
    dots: true,
    autoplayTimeout: 8500,
    smartSpeed: 450,
    // nav: true,
    responsive: {
      0: {
        items: 1,
      },
      480: {
        items: 2,
      },
      768: {
        items: 3,
      },
      1200: {
        items: 4,
      },
      1800: {
        items: 5,
      },
    },
  };

  return (
    <div className="today_dealz">
      <div className="todayDealz_container container">
        <div className="cmnHeading_sec">
          <p className="mb-0 heading_txt">Today's Deals</p>
          <Link to="/" className="text-decoration-none">
            <p className="mb-0 see-all">See all</p>
          </Link>
        </div>
        <div className="todayDelaz_card">
          <OwlCarousel
            id="customer-testimonoals"
            className="owl-carousel owl-theme todayDelaz_owlCarousal"
            {...options}
          >
            {TodayDealz.map((item, index) => {
              return <TodayDealzCard items={item} />;
            })}
          </OwlCarousel>
        </div>
      </div>
    </div>
  );
}

export default TodayDealz;
