export const Image = {
  overview: require("../Assets/Images/overview.svg").default,
  orders: require("../Assets/Images/my-order.svg").default,
  escrow: require("../Assets/Images/escrow.svg").default,
  deliveries: require("../Assets/Images/deliveries.svg").default,
  chat: require("../Assets/Images/chat.svg").default,
  coupons: require("../Assets/Images/coupons.svg").default,
  wishlists: require("../Assets/Images/wishlists.svg").default,
  address: require("../Assets/Images/address.svg").default,
  product: require("../Assets/Images/product.svg").default,
  store: require("../Assets/Images/store.svg").default,
  qaSeller: require("../Assets/Images/qaSeller.svg").default,
  notification: require("../Assets/Images/notification.svg").default,
  email: require("../Assets/Images/email.svg").default,
  account: require("../Assets/Images/account.svg").default,
  logo: require("../Assets/Images/logo.svg").default,
  notificationicon: require("../Assets/Images/notificationicon.svg").default,
  chipOne: require("../Assets/Images/chipOne.png"),
  bsc: require("../Assets/Images/bsc.png"),
  notify: require("../Assets/Images/notify.svg").default,
  mail: require("../Assets/Images/mail.svg").default,
  settings: require("../Assets/Images/settings.svg").default,
  wallet: require("../Assets/Images/wallet.svg").default,
  searchGlass: require("../Assets/Images/searchglass.svg").default,
  goldlock: require("../Assets/Images/goldlock.svg").default,
  deleteIcon: require("../Assets/Images/delete.svg").default,
  infoIcon: require("../Assets/Images/info.svg").default,
  docsIcon: require("../Assets/Images/docs.svg").default,
  headphone: require("../Assets/Images/headphone.png"),
  hometheatre: require("../Assets/Images/home_theatre.png"),
  joystick: require("../Assets/Images/Joystick.png"),
  coin1: require("../Assets/Images/coin1.png"),
  link: require("../Assets/Images/link.svg").default,
  btc: require("../Assets/Images/btc.png"),
  tron: require("../Assets/Images/tron.png"),
  eth: require("../Assets/Images/eth.svg").default,
  coupon1: require("../Assets/Images/coupon1.png"),
  coupon2: require("../Assets/Images/coupon2.png"),
  coupon3: require("../Assets/Images/coupon3.png"),
  coupon4: require("../Assets/Images/coupon4.png"),
  watch: require("../Assets/Images/watch.png"),
  tv: require("../Assets/Images/tv.png"),
  shoe: require("../Assets/Images/shoe.png"),
  mobile: require("../Assets/Images/mobile.png"),
  grapher: require("../Assets/Images/graph.svg").default,
  pinkBg: require("../Assets/Images/pinkBg.png"),
  userImg: require("../Assets/Images/userImg.png"),
  imager: require("../Assets/Images/imager.svg").default,
  emoji: require("../Assets/Images/emoji.svg").default,
  attach: require("../Assets/Images/attach.svg").default,
  qrcode: require("../Assets/Images/qrcode.png"),
  copyicon: require("../Assets/Images/copyicon.png"),
  info: require("../Assets/Images/info1.svg").default,
  remove: require("../Assets/Images/remove.svg").default,
  checked: require("../Assets/Images/checked.png"),
  storeImg: require("../Assets/Images/storeImg.jpeg"),
  emptyImg: require("../Assets/Images/EmptyImg.png"),
  emptyImg: require("../Assets/Images/EmptyImg.png"),
  emptyImg1: require("../Assets/Images/emptyImg1.svg").default,
  productImg: require("../Assets/Images/productImg.png"),
  horizontalDot: require("../Assets/Images/horizontalDot.svg").default,
  userBlackLogo: require("../Assets/Images/userBlack_logo.svg").default,
  removeLogo: require("../Assets/Images/remove.svg").default,
};
