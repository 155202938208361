// import package

import React, { useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Select from "react-select";
import ReactDatatable from "@ashvin27/react-datatable";

// import local
import { Image } from "../data/Images";
import DashLayout from "../Components/DashboardLayout/DashLayout";
import { Link } from "react-router-dom";

const Stores = () => {
  const [tableRecords, setTableRecords] = useState([
    {
      id: 1,
      storename: "Store One",
      email: "store1@gmail.com",
      logo: Image.storeImg,
      pincode: "657241",
      city: "madurai",
      state: "Tamilnadu",
      country: "India",
      phone: 9876543210,
    },
  ]);

  const columns = [
    {
      key: "storename",
      text: "Store Name",
      className: "w_150",
      align: "start",
      sortable: false,
    },
    {
      key: "email",
      text: "Email",
      className: "w_150",
      align: "start",
      sortable: false,
    },
    {
      key: "logo",
      text: "Store Logo",
      className: "w_150",
      align: "start",
      sortable: false,
      cell: (record, index) => {
        return (
          <div className="store_Tbl_logo">
            <img src={record.logo} className="img-fluid" />
          </div>
        );
      },
    },
    {
      key: "pincode",
      text: "Pincode",
      className: "w_150",
      align: "start",
      sortable: false,
    },
    {
      key: "city",
      text: "City",
      className: "w_150",
      align: "start",
      sortable: false,
    },
    {
      key: "state",
      text: "State",
      className: "w_150",
      align: "start",
      sortable: false,
    },
    {
      key: "country",
      text: "Country",
      className: "w_150",
      align: "start",
      sortable: false,
    },
    {
      key: "action",
      text: "Action",
      className: "store_Tbl_action",
      align: "center",
      sortable: false,
      cell: (record) => {
        return (
          <div className="btn_list">
            <Link to="/add-store">
              <button className="cmn_liteBlueBtn">Edit</button>
            </Link>
            <Link to="/add-store">
              <button className="cmn_Green_btn">View</button>
            </Link>
          </div>
        );
      },
    },
  ];
  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Emailtemplates",
    no_data_text: "No Email Templates found!",
    language: {
      length_menu: "Show MENU result per page",
      filter: "Filter in records...",
      info: "Showing START to END of TOTAL records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: false,
    show_filter: false,
    show_pagination: false,
    show_info: false,
  };

  return (
    <DashLayout>
      <div className="my-3 stores_list">
        <h5 className="overview__title">Stores</h5>
        <div className="table_card mt-4">
          <div className="table_card_header p-2 p-sm-3 p-md-4 d-flex flex-wrap gap-4 align-items-center justify-content-between">
            <div className="d-flex flex-wrap  align-items-center gap-3">
              <Link to="/add-store">
                <button className="green_btn">Add</button>
              </Link>
              <button className="gray_rect_btn">
                <img
                  src={Image.deleteIcon}
                  alt="delete"
                  className="img-fluid"
                  style={{ width: "20px" }}
                />
              </button>
              <button className="gray_rect_btn">
                {" "}
                <img
                  src={Image.infoIcon}
                  alt="info"
                  className="img-fluid"
                  style={{ width: "15px" }}
                />
              </button>
            </div>
            <div>
              <input type="text" placeholder="Search" className="gray_input" />
            </div>
          </div>
          <div className="custom_table">
            <ReactDatatable
              config={config}
              records={tableRecords}
              columns={columns}
              // extraButtons={extraButtons}
            />
          </div>
        </div>
      </div>
    </DashLayout>
  );
};

export default Stores;
