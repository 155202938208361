import React from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import closeBtn from "../../Assets/Images/modal_close.svg";

import signUpWallet from "../../Assets/Images/passkey.png";

import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
function SignupSuccessModal() {
  const [show, setShow] = useState(true);
  const [acc, setAcc] = useState("customer");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        size="md"
        className="common_Modal_Popup signUp_modal"
      >
        <Modal.Body>
          <Button className="close_btn" onClick={handleClose}>
            <img src={closeBtn} className="img-fluid" />
          </Button>

          <div className="content">
            <div className="head_sec text-center">
              <p className="name mb-0">Signup Success</p>
              <img src={signUpWallet} className="signUpWallet img-fluid my-4" />
              <p className="passkey_protect mb-0">
                Your account is successfully created !
              </p>
              <p className="create-passkey mb-0 mt-1">
                Explore Bitdealz Marketplace
              </p>
            </div>
            <div className="btns my-4">
              <Button className="homeBtn">Home</Button>
              <Button className="hotDealz">Hot Deals</Button>
              <Button className="manageAccBtn">Manage Account</Button>
            </div>
            <p className="mb-0 bitdealz-txt text-center">Bitdealz</p>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default SignupSuccessModal;
