import React, { useState } from "react";
import SpecialProductCard from "./SpecialProductCard";
import afterBar from "../Assets/Images/productList_after.svg";
import powerbank from "../Assets/Images/power_bank.png";
import mobile from "../Assets/Images/mobile.png";
import airbuts from "../Assets/Images/airbots.png";
import bike from "../Assets/Images/bike.png";
import headphone from "../Assets/Images/headphone.png";
import joystick from "../Assets/Images/Joystick.png";
import shoe from "../Assets/Images/shoe.png";
import watch from "../Assets/Images/watch1.png";
import { Link } from "react-router-dom";
function SpecialProducts() {
  const [specialProducts, setSpecialProducts] = useState("best-deals");

  const handleActive = (val) => {
    setSpecialProducts(val);
  };

  const SpecialProducts = [
    {
      id: 1,
      type: "Power Banks",
      name: "Power Banks 1130 Mah",
      img: powerbank,
    },
    {
      id: 2,
      type: "Power Banks",
      name: "Power Banks 1130 Mah",
      img: mobile,
    },
    {
      id: 3,
      type: "Power Banks",
      name: "Power Banks 1130 Mah",
      img: airbuts,
    },
    {
      id: 4,
      type: "Power Banks",
      name: "Power Banks 1130 Mah",
      img: bike,
    },
    {
      id: 5,
      type: "Power Banks",
      name: "Power Banks 1130 Mah",
      img: headphone,
    },
    {
      id: 6,
      type: "Power Banks",
      name: "Power Banks 1130 Mah",
      img: joystick,
    },

    {
      id: 7,
      type: "Power Banks",
      name: "Power Banks 1130 Mah",
      img: shoe,
    },
    {
      id: 8,
      type: "Power Banks",
      name: "Power Banks 1130 Mah",
      img: watch,
    },

    {
      id: 9,
      type: "Power Banks",
      name: "Power Banks 1130 Mah",
      img: powerbank,
    },
    {
      id: 10,
      type: "Power Banks",
      name: "Power Banks 1130 Mah",
      img: mobile,
    },
    {
      id: 11,
      type: "Power Banks",
      name: "Power Banks 1130 Mah",
      img: airbuts,
    },
    {
      id: 12,
      type: "Power Banks",
      name: "Power Banks 1130 Mah",
      img: bike,
    },
  ];
  return (
    <div className="specialProducts">
      <div className="header_sec">
        <span className="txt">Special Products</span>
      </div>
      <div className="container">
        <div className="SplProd_Tabs mt-3">
          <div className="values_list">
            <div
              className={
                specialProducts == "best-deals" ? "items active" : "items"
              }
              onClick={() => handleActive("best-deals")}
            >
              <span> Best Dealz </span>
            </div>
            <div
              className={
                specialProducts == "tv-radio" ? "items active" : "items"
              }
              onClick={() => handleActive("tv-radio")}
            >
              <span>Tv & Radio</span>
            </div>
            <div
              className={
                specialProducts == "smartphone" ? "items active" : "items"
              }
              onClick={() => handleActive("smartphone")}
            >
              <span>Smartphone</span>
            </div>
            <div
              className={specialProducts == "audio" ? "items active" : "items"}
              onClick={() => handleActive("audio")}
            >
              <span>Audio</span>
            </div>
            <div
              className={specialProducts == "camera" ? "items active" : "items"}
              onClick={() => handleActive("camera")}
            >
              <span>Camera</span>
            </div>
            <div
              className={specialProducts == "watch" ? "items active" : "items"}
              onClick={() => handleActive("watch")}
            >
              <span>Smart watches</span>
            </div>
            <div
              className={
                specialProducts == "headphone" ? "items active" : "items"
              }
              onClick={() => handleActive("headphone")}
            >
              <span>Headphones</span>
            </div>
          </div>
        </div>
        <div className="row mt-sm-3 mt-4">
          {specialProducts == "best-deals" ? (
            <>
              {SpecialProducts.map((item, index) => {
                return (
                  <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-2 mb-3 cardCols">
                    <Link to="/" className="text-decoration-none">
                      <SpecialProductCard items={item} />
                    </Link>
                  </div>
                );
              })}
            </>
          ) : (
            <>
              {SpecialProducts.map((item, index) => {
                return (
                  <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-2 mb-3">
                    <Link to="/" className="text-decoration-none">
                      <SpecialProductCard items={item} />
                    </Link>
                  </div>
                );
              })}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default SpecialProducts;
