import React from "react";
import { Link } from "react-router-dom";
import PopularSalesCard from "./PopularSalesCard";
import homeTheatre from "../Assets/Images/home_theatre.png";
import mobile from "../Assets/Images/mobile.png";
import bike from "../Assets/Images/bike.png";
import tv from "../Assets/Images/tv.png";

function PopularSales() {
  const PopularSales = [
    {
      id: 1,
      Img: homeTheatre,
      type: "Home Theatre",
      modal: "Power Banks 1130 Mah",
    },
    {
      id: 2,
      Img: mobile,
      type: "Oppo Reno 11",
      modal: "Power Banks 1130 Mah",
    },
    {
      id: 3,
      Img: bike,
      type: "Ninja Z2000",
      modal: "Power Banks 1130 Mah",
    },
    {
      id: 4,
      Img: tv,
      type: "Sony Z40",
      modal: "Power Banks 1130 Mah",
    },
    {
      id: 5,
      Img: homeTheatre,
      type: "Home Theatre",
      modal: "Power Banks 1130 Mah",
    },
    {
      id: 6,
      Img: mobile,
      type: "Oppo Reno 11",
      modal: "Power Banks 1130 Mah",
    },
    {
      id: 7,
      Img: bike,
      type: "Ninja Z2000",
      modal: "Power Banks 1130 Mah",
    },
    {
      id: 8,
      Img: tv,
      type: "Sony Z40",
      modal: "Power Banks 1130 Mah",
    },
  ];
  return (
    <div className="popular_sales">
      <div className="popularSales_container container">
        <div className="cmnHeading_sec">
          <p className="mb-0 heading_txt">Popular Sales</p>
          <Link to="/" className="text-decoration-none">
            <p className="mb-0 see-all">See all</p>
          </Link>
        </div>
        <div className="popularSalesCard">
          <div className="row">
            {PopularSales.map((item, index) => {
              return (
                <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-2 col cardCols">
                  <PopularSalesCard items={item} />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PopularSales;
