// import package

import React, { useRef, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import Select from "react-select";
import ReactDatatable from "@ashvin27/react-datatable";

// import local
import { Image } from "../data/Images";
import DashLayout from "../Components/DashboardLayout/DashLayout";
import OrderStatus from "../table/OrderStatus";
import FeatureProductCard from "../Components/FeatureProductCard";
import { Link } from "react-router-dom";
import CouponCard from "../Components/CouponCard";
import WalletAndNetwork from "../Components/WalletAndNetwork";
import EscrowLock from "../Components/EscrowLock";

const Coupons = () => {
  const [couponDetails, setCouponDetails] = useState([
    {
      id: 1,
      img: Image.coupon1,
      offPercent: "50%",
      couponCode: "DISCOUNT50",
      expiryTime: 6000000,
    },
    {
      id: 2,
      img: Image.coupon2,
      offPercent: "45%",
      couponCode: "DISCOUNT45",
      expiryTime: 60000,
    },
    {
      id: 3,
      img: Image.coupon3,
      offPercent: "60%",
      couponCode: "DISCOUNT60",
      expiryTime: 60000000,
    },
    {
      id: 4,
      img: Image.coupon4,
      offPercent: "10%",
      couponCode: "DISCOUNT10",
      expiryTime: 6000,
    },
    {
      id: 5,
      img: Image.coupon1,
      offPercent: "50%",
      couponCode: "S0CHINEW",
      expiryTime: 6000000,
    },
    {
      id: 6,
      img: Image.coupon2,
      offPercent: "45%",
      couponCode: "S0CHINEW",
      expiryTime: 60000,
    },
    {
      id: 7,
      img: Image.coupon3,
      offPercent: "60%",
      couponCode: "S0CHINEW",
      expiryTime: 60000000,
    },
    {
      id: 8,
      img: Image.coupon4,
      offPercent: "10%",
      couponCode: "S0CHINEW",
      expiryTime: 6000,
    },
    {
      id: 9,
      img: Image.coupon1,
      offPercent: "50%",
      couponCode: "S0CHINEW",
      expiryTime: 6000000,
    },
    {
      id: 10,
      img: Image.coupon2,
      offPercent: "45%",
      couponCode: "S0CHINEW",
      expiryTime: 60000,
    },
    {
      id: 11,
      img: Image.coupon3,
      offPercent: "60%",
      couponCode: "S0CHINEW",
      expiryTime: 60000000,
    },
    {
      id: 12,
      img: Image.coupon4,
      offPercent: "10%",
      couponCode: "S0CHINEW",
      expiryTime: 6000,
    },
  ]);

  return (
    <DashLayout>
      <div className="my-4 coupons">
        <h5 className="overview__title">Coupons</h5>
        <div className="coupon_body_card p-3">
          <p className="fw_black_sm">My Coupon</p>
          <Link to="/add-coupon">
            <Button className="add_btn cmn_Green_btn">Add</Button>
          </Link>
          <div className="row mt-4">
            {couponDetails.map((item) => (
              <div className="col-lg-6 col-xl-6 col-xxl-4 col-xxxl-3 mb-4">
                <CouponCard item={item} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </DashLayout>
  );
};

export default Coupons;
