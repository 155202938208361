import React from "react";
import premiumProduct from "../Assets/Images/premium_watch.png";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
function PremiumProduct() {
  return (
    <div className="premiumProduct">
      <div className="premiumProduct container">
        <Link to="/" className="text-decoration-none">
          {/* <div
            className="product"
            style={{ backgroundImage: `url(${premiumProduct})` }}
          >
            <div className="des">
              <p className="mb-0 name">Premium Watches</p>
              <Button className="showNow_btn">Shop now</Button>
            </div>
          </div> */}

          <div className="premiumProduct_Img product position-relative">
            <img src={premiumProduct} className="img-fluid" />
            {/* <div className="des">
              <p className="mb-0 name">Premium Watches</p>
              <Button className="showNow_btn">Shop now</Button>
            </div> */}
          </div>
        </Link>
      </div>
    </div>
  );
}

export default PremiumProduct;
